import React from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Divider,
  Modal
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import {
  CREATE_ENTITY,
  CREATE_ASSET,
  EDIT_ENTITY,
  CREATE_ATTENDANCE,
  ATTENDANCE_USER,
  REVERSE_GEO,
  CURRENT_USER_ROASTERS,
  GET_ASSIGNED_USER_SUB_ORG_LIST
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";
import CreateShop from "../shop/createShop";
import CreateAsset from "../assets/createAsset";
import ReactGA from "react-ga";

const { Text } = Typography;
const { Option } = Select;

export default class CreateAttendance extends React.Component {
  state = {
    attendance: false,
    checkin_time: "",
    checkin_time_view: moment(),
    checkout_time: "",
    checkout_time_view: moment(),
    entity: "",
    entity_key: null,
    entity_address: "",
    // entity_loc_lat: 23.685,
    // entity_loc_lon: 90.3563,
    entity_loc: "",
    asset: "",
    asset_key: null,
    loc_lat: 23.685,
    loc_lon: 90.3563,
    loc_name: "",
    entity_loc_name: "",
    loading: false,
    dataS: [],
    assetData: [],
    userData: [],
    user_id: "",
    user_name: "",
    date: Date.now(),
    showEntity: false,
    showAsset: false,
    current_user_roasters: [],
    roaster_id: "",
    roaster_name: "",
    sub_org: "",
    sub_orgs: []
  };

  componentDidMount() {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          loc_lat: latitude,
          loc_lon: longitude
        },
        () => {
          axios
            .get(REVERSE_GEO(this.state.loc_lat, this.state.loc_lon))
            .then(response => {
              let name = response.data.features[0].properties.display_name;
              this.setState({
                loc_name: name
              });
            });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
    this.getUserList();
    this.getShopList();
    this.getAssetList();
    // this.getCurrentUserRoasters();
    this.setState({ date: Date.now() });
    ReactGA.pageview(window.location.pathname);
  }

  getCurrentUserRoasters = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CURRENT_USER_ROASTERS}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
        params: {
          selected_user_id: this.state.user_id
        }
      })
        .then(response => {
          console.log("from current user roasters", response);
          this.setState({ current_user_roasters: response.data.data });
        })
        .catch(error => {
          console.log("error from current user roasters", error);
        });
    });
  };
  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${ATTENDANCE_USER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("from user", response);
          this.setState({ userData: response.data.data });
        })
        .catch(error => {
          console.log("error from user", error);
        });
    });
  };
  getShopList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ENTITY}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ dataS: response.data.data });
        })
        .catch(error => {
          console.log("error from shop list api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ASSET}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from asset list api", error);
        });
    });
  };

  getAssignedUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${this.state.user_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            sub_orgs: response.data.data
          });

          console.log("response from assigned user sub org list", response);
        })
        .catch(error => {
          console.log("error from assigned user sub org list api", error);
        });
    });
  };

  setUser = value => {
    this.setState({ user_name: value.label, user_id: value.key }, () => {
      this.getCurrentUserRoasters();
      this.getAssignedUserSubOrgs();
    });
  };
  setSubOrg = value => {
    this.setState({ sub_org: value}, () => {
      console.log(this.state.sub_org);
    });
  };
  setRoaster = value => {
    this.setState({ roaster_name: value.label, roaster_id: value.key }, () => {
      console.log(this.state.roaster_name);
    });
  };

  setCheckin = (value, dateString) => {
    this.setState(
      { checkin_time: moment(value).format("x"), checkin_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.checkin_time);
        console.log("Selected value: ", this.state.checkin_time_view);
      }
    );
  };

  setCheckout = (value, dateString) => {
    this.setState(
      { checkout_time: moment(value).format("x"), checkout_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.checkout_time);
        console.log("Selected value: ", this.state.checkout_time_view);
      }
    );
  };

  setEntity = value => {
    this.setState({ asset_key: null, entity_key: value }, () => {
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_ENTITY}${this.state.entity_key}?access_token=${token}`;
        axios({
          method: "get",
          url: url
        }).then(response => {
          console.log("response fro edit entity", response);
          this.setState(
            {
              entity_loc: response.data.data.loc.coordinates,
              entity_loc_name: response.data.data.name,
              entity_address: response.data.data.address
            },
            () => console.log(this.state.entity_loc)
          );
        });
      });
    });
  };

  setAsset = value => {
    this.setState({ entity_key: null, entity_loc: null, asset_key: value });
  };

  createAttendance = () => {
    if (this.state.checkin_time === "" || this.state.checkout_time === "") {
      swal.fire("Info", "Please enter checkin and checkout time", "info");
    } else if (this.state.entity_key !== null) {
      this.setState({ loading: true });

      let data = {
        attendance: this.state.attendance,
        roaster_id: this.state.roaster_id,
        sub_org: this.state.sub_org,
        date: this.state.date,
        loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        loc_name: this.state.loc_name,
        checkin_time: this.state.checkin_time,
        checkout_time: this.state.checkout_time,
        entity_confirm: {
          checkin_entity: this.state.entity_key,
          checkout_entity: this.state.entity_key,
          checkin_loc: {
            coordinates: this.state.entity_loc
          },
          checkin_loc_name: this.state.entity_address,
          checkout_loc: {
            coordinates: this.state.entity_loc
          },
          checkout_loc_name: this.state.entity_address
        },
        asset_confirm: {},
        created_for: {
          id: this.state.user_id,
          name: this.state.user_name
        }
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_ATTENDANCE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from create Attendance", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create Attendance", error);
          });
      });
    } else if (this.state.asset_key !== null) {
      this.setState({ loading: true });

      let data = {
        attendance: this.state.attendance,
        roaster_id: this.state.roaster_id,
        sub_org: this.state.sub_org,
        loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        loc_name: this.state.loc_name,
        checkin_time: this.state.checkin_time,
        checkout_time: this.state.checkout_time,
        entity_confirm: {},
        asset_confirm: {
          asset: this.state.asset_key
        },
        created_for: {
          id: this.state.user_id,
          name: this.state.user_name
        }
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_ATTENDANCE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from create Attendance", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create Attendance", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Attendance"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Select User</Text>
            <Select
              labelInValue
              value={{
                key: this.state.user_id,
                label: this.state.user_name
              }}
              onChange={this.setUser}
              placeholder="Select User"
            >
              {this.state.userData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Select Sub Org</Text>
            <Select
              value={this.state.sub_org}
              onChange={this.setSubOrg}
              disabled={this.state.sub_orgs.length<1?true:false}
            >
              {this.state.sub_orgs.map(r => {
                return <Option key={r.id}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Select Roaster</Text>
            <Select
              labelInValue
              value={{
                key: this.state.roaster_id,
                label: this.state.roaster_name
              }}
              onChange={this.setRoaster}
              placeholder="Select Roaster"
              disabled={
                this.state.current_user_roasters.length === 0 ? true : false
              }
            >
              {this.state.current_user_roasters.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Checkin Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.checkin_time_view}
                  onChange={this.setCheckin}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Checkout Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.checkout_time_view}
                  onChange={this.setCheckout}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          <Form.Item>
            <Text>
              Select Place{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showEntity: true })}
              >
                Create Place
              </Button>
            </Text>
            <Select
              allowClear={true}
              value={this.state.entity_key}
              onChange={this.setEntity}
              placeholder="Select Entity"
            >
              {this.state.dataS.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Divider>Only Select Place or Asset</Divider>
          <Form.Item>
            <Text>
              Select Asset
              <Button
                type="link"
                onClick={() => this.setState({ showAsset: true })}
              >
                Create Asset
              </Button>
            </Text>
            <Select
              allowClear={true}
              value={this.state.asset_key}
              onChange={this.setAsset}
              placeholder="Select Asset"
            >
              {this.state.assetData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createAttendance}
            >
              Create Attendance
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Create New Place"
          visible={this.state.showEntity}
          onOk={() => {
            this.setState({ showEntity: false });
            this.getShopList();
          }}
          onCancel={() => {
            this.setState({ showEntity: false });
            this.getShopList();
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <CreateShop />
        </Modal>
        <Modal
          title="Create New Asset"
          visible={this.state.showAsset}
          onOk={() => {
            this.setState({ showAsset: false });
            this.getAssetList();
          }}
          onCancel={() => {
            this.setState({ showAsset: false });
            this.getAssetList();
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <CreateAsset />
        </Modal>
      </Card>
    );
  }
}
