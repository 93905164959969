import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Select,
  Typography,
  Input,
  Pagination,
} from "antd";
import axios from "axios";
// import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { debounce } from "lodash";
// import FileSaver from "file-saver";

import {
  MOBILE_HEARTEBEAT_LIST,
  CREATE_ORGANIZATION,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

export default class MobileHeartbeatList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    from: moment().startOf("day").format("x"),
    from_view: moment().startOf("day"),
    to: moment().endOf("day").format("x"),
    to_view: moment().endOf("day"),
    orgData: [],
    filteredOrgData: [],
    org_id: "",
    user_name: "",
  };
  componentDidMount() {
    this.getHeartbeatList();
    this.getOrganizationList();
    ReactGA.pageview(window.location.pathname);
  }

  setOrganization = (value) => {
    this.setState({ org_id: value, filteredOrgData: [] }, () => {
      console.log(this.state.org_id);
      this.getHeartbeatList();
    });
  };

  handleSearch = (value) => {
    const result = this.state.orgData.filter((org) => {
      return org.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filteredOrgData: result });
  };

  setTo = (value, dateString) => {
    this.setState({ to: moment(value).format("x"), to_view: value }, () => {
      console.log("Selected Time: ", this.state.to);
      this.getHeartbeatList();
    });
  };
  setFrom = (value, dateString) => {
    this.setState({ from: moment(value).format("x"), from_view: value }, () => {
      console.log("Selected Time: ", this.state.from);
      this.getHeartbeatList();
    });
  };

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ORGANIZATION}?limit=1000&access_token=${token}&get_connected_orgs=true&sort_type=asc`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from organization list", response);

          this.setState({
            orgData: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  getHeartbeatList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${MOBILE_HEARTEBEAT_LIST}?limit=${this.state.limit}&page=${this.state.page}&from=${this.state.from}&to=${this.state.to}&org_id=${this.state.org_id}&user_name=${this.state.user_name}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from heartbeat list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from heartbeat list api", error);
        });
    });
  }, 1000);

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getHeartbeatList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getHeartbeatList();
    });
  };

  refresh = () => {
    this.setState(
      {
        user_name: "",
        org_id: "",
        from: moment().startOf("day").format("x"),
        from_view: moment().startOf("day"),
        to: moment().endOf("day").format("x"),
        to_view: moment().endOf("day"),
        page: 0,
        limit: 500,
      },
      () => {
        this.getHeartbeatList();
      }
    );
  };

  render() {
    const OrgList = this.state.orgData.map((r) => {
      return <Option key={r._id.$oid}>{`${r.name}`}</Option>;
    });

    const FliteredOrgList = this.state.filteredOrgData.map((r) => {
      return <Option key={r._id.$oid}>{`${r.name}`}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <Card style={{ marginTop: 5 }}>
          <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
            Filter Options{" "}
          </Text>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.from_view}
                  onChange={this.setFrom}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Text style={{ paddingTop: 20 }}>---</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.to_view}
                  onChange={this.setTo}
                />
              </MuiPickersUtilsProvider>
            </div>
            {GET_USER_LOCAL().is_staff || GET_USER_LOCAL().is_super ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                  width: 350,
                }}
              >
                <Text style={{ margin: 5 }}>Search Organization</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: "100%" }}
                  value={this.state.org_id}
                  onChange={this.setOrganization}
                  placeholder="Select User"
                >
                  {this.state.filteredOrgData.length === 0
                    ? OrgList
                    : FliteredOrgList}
                </Select>
              </div>
            ) : null}
            <div style={{ marginLeft: 10,marginTop:30, textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#1A3337", color: "white" }}
                onClick={this.refresh}
              >
                Refresh
              </Button>
            </div>
          </div>
        </Card>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Mobile Heartbeat Data"
        />
        <div style={{ margin: 10 }}>
          <Text>{`Displaying : `}</Text>
          <Select value={this.state.limit} onChange={this.setLimit}>
            <Option key={10}>10</Option>
            <Option key={25}>25</Option>
            <Option key={50}>50</Option>
            <Option key={100}>100</Option>
          </Select>
          <Text>{`  Entries `}</Text>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div>User Name</div>

                <Input
                  value={this.state.user_name}
                  onChange={(evt) => {
                    this.setState(
                      { user_name: evt.target.value, page: 0 },
                      () => {
                        this.getHeartbeatList();
                      }
                    );
                  }}
                />
              </>
            }
            key="User Name"
            render={(text, record) => <span>{record?.user_name}</span>}
          />
          <Column
            title="Device ID"
            key="Device ID"
            render={(text, record) => <span>{record?.device_id}</span>}
          />
          <Column
            title="App Background GPS Status"
            key="App Background GPS Status"
            render={(text, record) => (
              <span>
                {record?.app_background_gps_permission?.toString() ?? ""}
              </span>
            )}
          />
          <Column
            title="Mobile GPS Status"
            key="Mobile GPS Status"
            render={(text, record) => (
              <span>{record?.mobile_gps_status?.gps?.toString() ?? ""}</span>
            )}
          />
          <Column
            title="Mobile Network Status"
            key="Mobile Network Status"
            render={(text, record) => (
              <span>
                {record?.mobile_gps_status?.network?.toString() ?? ""}
              </span>
            )}
          />
          <Column
            title="App Background Service Status"
            key="App Background Service Status"
            render={(text, record) => (
              <span>
                {record?.app_background_services_on?.toString() ?? ""}
              </span>
            )}
          />
          <Column
            title="Mobile Sleep Status"
            key="Mobile Sleep Status"
            render={(text, record) => (
              <span>{record?.mobile_sleep_status?.toString() ?? ""}</span>
            )}
          />
          <Column
            title="Wifi Connected"
            key="Wifi Connected"
            render={(text, record) => (
              <span>
                {record?.wifi_connected?.is_connected?.toString() ?? ""}
              </span>
            )}
          />
          <Column
            title="Wifi Reachable"
            key="Wifi Reachable"
            render={(text, record) => (
              <span>
                {record?.wifi_connected?.is_internet_reachable?.toString() ??
                  ""}
              </span>
            )}
          />
          <Column
            title="Mobile Data Connected"
            key="Mobile Data Connected"
            render={(text, record) => (
              <span>{record?.mobile_data?.is_connected?.toString() ?? ""}</span>
            )}
          />
          <Column
            title="Mobile Data Reachable"
            key="Mobile Data Reachable"
            render={(text, record) => (
              <span>
                {record?.mobile_data?.is_internet_reachable?.toString() ?? ""}
              </span>
            )}
          />
          <Column
            title="Battery Level"
            key="Battery Level"
            render={(text, record) => (
              <span>{record?.battery_saver_mode?.battery_level}</span>
            )}
          />
          <Column
            title="Battery State"
            key="Battery State"
            render={(text, record) => (
              <span>{record?.battery_saver_mode?.battery_state}</span>
            )}
          />
          <Column
            title="Low Power Mode"
            key="Low Power Mode"
            render={(text, record) => (
              <span>
                {record?.battery_saver_mode?.low_power_mode?.toString() ?? ""}
              </span>
            )}
          />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}
