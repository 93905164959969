import React from "react";
import { Redirect } from "react-router-dom";

import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  EDIT_DRIVER,
  CREATE_ORGANIZATION,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

class EditDriver extends React.Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    email_verified: false,
    mobile_verified: false,
    password: "",
    address: "",
    city: "",
    country: "",
    security_id: "",
    organization: "",
    org_id: "",
    contact_no: "",
    emr_contact_name: "",
    blood_grp: "",
    lic_no: "",
    exp_date: moment(),
    exp_date_view: moment(),
    player_id: "",
    driver_id: "",
    data: [],
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    org_admin: false,
    is_operator: false,
    redirect: false,
    is_admin: false,
    is_staff: false,
    own_account: false,
    designation: "",
    is_active: true,
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    this.getUser();
    this.getOrganizationList();
    console.log(this.props.history);
    this.setState({
      driver_id: this.props.location.state.driver_id,
      is_admin: GET_USER_LOCAL().org_admin,
      is_staff: GET_USER_LOCAL().is_staff,
    });

    if (this.props.location.state.driver_id === GET_USER_LOCAL()._id.$oid) {
      this.setState({ own_account: true });
    }
  }

  getUser = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let driver_id = this.props.location.state.driver_id;
      let url = `${EDIT_DRIVER}${driver_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit driver", response);
        if (response.data.message === "Driver not found") {
          swal.fire("Error", "User Not Found !", "error");
          this.setState({ redirect: true });
        } else {
          this.setState({
            name: response?.data?.data?.name,
            designation: response?.data?.data?.designation ?? "",
            email: response?.data?.data?.email,
            mobile: response?.data?.data?.mobile,
            email_verified: response?.data?.data?.email_verified,
            mobile_verified: response?.data?.data?.mobile_verified,
            // password: response.data.data.password,
            address: response?.data?.data?.address,
            // city: response.data.data.city,
            // country: response.data.data.country,
            security_id: response?.data?.data?.security_id,
            // organization: response.data.data.organizations[0].name,
            // org_id: response.data.data.organization.$oid,
            contact_no: response?.data?.data?.contact_no,
            emr_contact_name: response?.data?.data?.emr_contact_name,
            blood_grp: response?.data?.data?.blood_grp,
            lic_no: response?.data?.data?.lic_no,
            // exp_date: response?.data?.data?.exp_date?.$date??"",
            // exp_date_view: response?.data?.data?.exp_date?.$date,
            player_id: response?.data?.data?.player_id ?? "",
            is_operator: response?.data?.data?.is_operator,
            org_admin: response?.data?.data?.org_admin ?? false,
            is_active: response?.data?.data?.is_active ?? false,
          });
        }
      });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch((error) => {
          console.log("error from country list api", error);
        });
    });
  };

  getOrganizationList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_ORGANIZATION}?limit=${limit}&get_connected_orgs=true&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from organization list", response);
          if (response.data.data.length === 0) {
            swal.fire("Info", "No Data Available", "info");
          }
          this.setState({ data: response.data.data });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDesignation = (evt) => {
    evt.preventDefault();
    this.setState({ designation: evt.target.value }, () => {
      console.log(this.state.designation);
    });
  };
  setEmail = (evt) => {
    evt.preventDefault();
    this.setState({ email: evt.target.value }, () => {
      console.log(this.state.email);
    });
  };
  setMobile = (evt) => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value }, () => {
      console.log(this.state.mobile);
    });
  };
  handleEmailVchange = (value) => {
    this.setState({ email_verified: CHECK_BOOL(value) }, () => {
      console.log(this.state.email_verified);
    });
  };
  handleOperatorChange = (value) => {
    this.setState({ is_operator: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_operator);
    });
  };
  handleAdminChange = (value) => {
    this.setState({ org_admin: CHECK_BOOL(value) }, () => {
      console.log(this.state.org_admin);
    });
  };
  handleMobileVchange = (value) => {
    this.setState({ mobile_verified: CHECK_BOOL(value) }, () => {
      console.log(this.state.mobile_verified);
    });
  };
  handleActiveChange = (value) => {
    this.setState({ is_active: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_active);
    });
  };
  setPassword = (evt) => {
    evt.preventDefault();
    this.setState({ password: evt.target.value }, () => {
      console.log(this.state.password);
    });
  };
  setAddress = (evt) => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };

  setCity = (value) => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = (value) => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;

        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities,
          });
        });
      });
    });
  };
  setSecurity = (evt) => {
    evt.preventDefault();
    this.setState({ security_id: evt.target.value }, () => {
      console.log(this.state.security_id);
    });
  };
  setOrganization = (value) => {
    this.setState({ organization: value.label, org_id: value.key }, () => {
      console.log(this.state.organization);
    });
  };
  setContact = (evt) => {
    evt.preventDefault();
    this.setState({ contact_no: evt.target.value }, () => {
      console.log(this.state.contact_no);
    });
  };
  setEmergencyContact = (evt) => {
    evt.preventDefault();
    this.setState({ emr_contact_name: evt.target.value }, () => {
      console.log(this.state.emr_contact_name);
    });
  };
  setBloodgroup = (evt) => {
    evt.preventDefault();
    this.setState({ blood_grp: evt.target.value }, () => {
      console.log(this.state.blood_grp);
    });
  };
  setLicense = (evt) => {
    evt.preventDefault();
    this.setState({ lic_no: evt.target.value }, () => {
      console.log(this.state.lic_no);
    });
  };
  setExpiryDate = (value, dateString) => {
    this.setState(
      { exp_date: moment(value).format("x"), exp_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.exp_date);
        console.log("Selected value: ", this.state.exp_date_view);
      }
    );
  };

  updateDriver = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      designation: this.state.designation,
      email: this.state.email,
      mobile: this.state.mobile,
      email_verified: this.state.email_verified,
      mobile_verified: this.state.mobile_verified,
      password: this.state.password,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      security_id: this.state.security_id,
      organization: this.state.org_id,
      contact_no: this.state.contact_no,
      emr_contact_name: this.state.emr_contact_name,
      blood_grp: this.state.blood_grp,
      lic_no: this.state.lic_no,
      // exp_date: this.state.exp_date,
      player_id: this.state.player_id,
      is_operator: this.state.is_operator,
      org_admin: this.state.org_admin,
      is_active: this.state.is_active,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_DRIVER}${this.state.driver_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from create driver", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from create driver", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    if (this.state.redirect === true) {
      return <Redirect to="/userList" />;
    }

    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => this.props.history.goBack()}
          title="Update User"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              disabled={
                this.state.is_staff ||
                this.state.own_account ||
                this.state.is_admin
                  ? false
                  : true
              }
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Designation </Text>
            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="Designation"
              type="text"
              value={this.state.designation}
              onChange={this.setDesignation}
            />
          </Form.Item>
          <Form.Item>
            <Text>Email</Text>
            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="Email"
              type="text"
              value={this.state.email}
              onChange={this.setEmail}
            />
          </Form.Item>
          <Form.Item>
            <Text>Mobile</Text>
            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="+880-1 ..."
              type="text"
              value={this.state.mobile}
              onChange={this.setMobile}
            />
          </Form.Item>
          <Form.Item>
            <Text>Email Verified</Text>
            <Select
              disabled={true}
              value={this.state.email_verified.toString()}
              onChange={this.handleEmailVchange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Active</Text>
            <Select
              disabled={
                this.props.userProfileData.data_level.access_suborg_data ||
                this.props.userProfileData.data_level.access_all_data
                  ? false
                  : true
              }
              value={this.state.is_active.toString()}
              onChange={this.handleActiveChange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Mobile Verified</Text>
            <Select
              disabled={true}
              value={this.state.mobile_verified.toString()}
              onChange={this.handleMobileVchange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item>
            <Text>Operator</Text>
            <Select
              disabled={
                this.state.is_admin || this.state.is_staff ? false : true
              }
              value={this.state.is_operator.toString()}
              onChange={this.handleOperatorChange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Text>Org Admin</Text>
            <Select
              disabled={
                this.state.is_admin || this.state.is_staff ? false : true
              }
              value={this.state.org_admin.toString()}
              onChange={this.handleAdminChange}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          {this.state.is_admin ? (
            <Form.Item>
              <Text>Password</Text>
              <Input
                placeholder="Password"
                type="text"
                value={this.state.password}
                onChange={this.setPassword}
              />
            </Form.Item>
          ) : null}

          <Form.Item>
            <Text>Address</Text>

            <TextArea
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              rows={4}
              placeholder="Address"
              type="text"
              value={this.state.address}
              onChange={this.setAddress}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>City</Text>

            <Select
              labelInValue
              disabled={this.state.country===""?true:false}
              value={{ key: this.state.city_id, label: this.state.city }}
              onChange={this.setCity}
              placeholder="Select City"
            >
              {this.state.cities.map(r => {
                return <Option key={r.code}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Text>Security</Text>

            <Input
              disabled={
                this.state.is_staff || this.state.is_admin ? false : true
              }
              placeholder="Security"
              type="text"
              value={this.state.security_id}
              onChange={this.setSecurity}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Organization</Text>
            <Select
              labelInValue
              value={{key:this.state.org_id,label:this.state.organization}}
              onChange={this.setOrganization}
              placeholder="Select Organization"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item> */}
          <Form.Item>
            <Text>Contact Number</Text>

            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="Contact Number"
              type="text"
              value={this.state.contact_no}
              onChange={this.setContact}
            />
          </Form.Item>
          <Form.Item>
            <Text>Emergency Contact Name</Text>

            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="Emergency Contact Name"
              type="text"
              value={this.state.emr_contact_name}
              onChange={this.setEmergencyContact}
            />
          </Form.Item>
          <Form.Item>
            <Text>Blood Group</Text>

            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="Blood Group"
              type="text"
              value={this.state.blood_grp}
              onChange={this.setBloodgroup}
            />
          </Form.Item>
          <Form.Item>
            <Text>License Number</Text>

            <Input
              disabled={
                this.state.is_staff ||
                this.state.is_admin ||
                this.state.own_account
                  ? false
                  : true
              }
              placeholder="License Number"
              type="text"
              value={this.state.lic_no}
              onChange={this.setLicense}
            />
          </Form.Item>
          {/* <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Expiry Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disabled={
                    this.state.is_admin || this.state.is_staff || this.state.own_account ? false : true
                  }
                  inputVariant="outlined"
                  value={moment(this.state.exp_date_view)}
                  onChange={this.setExpiryDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item> */}

          <Form.Item>
            {this.state.is_staff ||
            this.state.is_admin ||
            this.state.own_account ? (
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateDriver}
              >
                Update User
              </Button>
            ) : null}
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(EditDriver);
