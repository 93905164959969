import React, { Component } from "react";
import { Card, Typography, PageHeader } from "antd";
import axios from "axios";
import { EDIT_ORGANIZATION } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga";


import "../login.css";

const { Text } = Typography;

export default class ViewOrganization extends Component {
  state = {
    name: "",
    address: "",
    city: "",
    country: "",
    connect_key: "",
    post_code: "",
    telephone: "",
    website: "",
    sub_orgs: "",
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    org_id: "",
    org_admin: false,
    allow_connect: true,
    timezone: [],
    timezone_name: "",
    filteredTimezone: [],
    for_name: "organization",
    imgFile: null,
    imgUrl: null
  };
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    this.setState({
      org_id: this.props.location.state.org_id
    });
    this.getOrganizationDetails();
  }

  getOrganizationDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let org_id = this.props.location.state.org_id;
      let url = `${EDIT_ORGANIZATION}${org_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit org", response);
        this.setState({
          name: response.data.data.name,
          address: response.data.data.address,
          city: response.data.data.city,
          country: response.data.data.country,
          connect_key: response.data.data.connect_key,
          post_code: response.data.data.post_code,
          telephone: response.data.data.telephone,
          website: response.data.data.website,
          sub_orgs: response.data.data.sub_orgs,
          allow_connect: response.data.data.allow_connect,
          timezone_name: response.data.data.timezone_name,
          imgUrl: response.data?.data?.org_image ?? null
        });
      });
    });
  };
  render() {
    return (
      <div>
        <Card style={{ margin: 10 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            onBack={() => this.props.history.goBack()}
            title="View Organization"
          />
          <Card className="view">
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Organization Name</Text>:{" "}
              {this.state.name}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Address</Text>:{" "}
              {this.state.address}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>City</Text>:{" "}
              {this.state.city}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Country</Text>:{" "}
              {this.state.country}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Telephone</Text>:{" "}
              {this.state.telephone}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Connect Key</Text>:{" "}
              {this.state.connect_key}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Post Code</Text>:{" "}
              {this.state.post_code}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>TimeZone</Text>:{" "}
              {this.state.timezone_name}
            </p>
            <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Website</Text>:{" "}
              {this.state.website}
            </p>
          </Card>
        </Card>
      </div>
    );
  }
}
