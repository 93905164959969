import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Typography, Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import {
  CREATE_SALES_TARGET,
  EDIT_SALES_TARGET,
  CREATE_DRIVER
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import ReactGA from "react-ga";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

export default class SalesTargetList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    users: [],
    assigned_users: [],
    assigned_user: ""
  };
  componentDidMount() {
    this.getUserList();
    this.getSalesTargetList();
    ReactGA.pageview(window.location.pathname);
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from user list", response);
          this.setState({
            users: response.data.data
          });
        })
        .catch(error => {
          console.log("error from user list api", error);
        });
    });
  };

  getSalesTargetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_SALES_TARGET}?limit=50&page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from sales target list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from sales target list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getSalesTargetList();
      }
    );
  };

  searchUser = value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from search user", response);
          this.setState({
            users: response.data.data
          });
        })
        .catch(error => {
          console.log("error from search user api", error);
        });
    });
  };

  filterSalesList = value => {
    this.setState({ assigned_user: value }, () => {
      GET_ACCESS_TOKEN().then(token => {
        const url = `${CREATE_SALES_TARGET}?limit=100&assigned_user=${this.state.assigned_user}&access_token=${token}`;

        axios({
          method: "get",
          url: url
        })
          .then(response => {
            console.log("response from sales target list search", response);
            this.setState({
              data: response.data.data,
              total: response.data.total
            });
          })
          .catch(error => {
            console.log("error from sales target list api search", error);
          });
      });
    });
  };

  deleteSalesTarget = id => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_SALES_TARGET}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          swal.fire("Success", response.data.message, "success");
          this.getSalesTargetList();
          console.log("deleted", response);
        })
        .catch(error => {
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {GET_USER_LOCAL().org_admin ? (
          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div style={{ margin: 10 }}>
                <Text>Search Assigned Users : </Text>
                <Select
                  style={{ minWidth: 300 }}
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.searchUser}
                  onChange={this.filterSalesList}
                  placeholder="Search Users"
                  value={this.state.assigned_user}
                >
                  {this.state.users.map(r => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile}`}</Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                  <Button onClick={()=>this.setState({assigned_user:""},()=>this.getSalesTargetList())}>Refresh</Button>
              </div>
            </div>
          </Card>
        ) : null}
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Sales Targets"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title="Assigned User"
            dataIndex="assigned_user_name"
            key="assigned_user_name"
          />
          <Column
            title="Target Amount"
            dataIndex="target_amount"
            key="target_amount"
          />
          <Column
            title="Achieved Amount"
            dataIndex="achieved_amount"
            key="achieved_amount"
          />
          <Column
            title="Remaining Amount"
            dataIndex="remaining_amount"
            key="remaining_amount"
          />
          <Column
            title="Achieved Amount Based on"
            dataIndex="achieved_amount_based_on"
            key="achieved_amount_based_on"
          />

          <Column
            title="Start Time"
            key="Start Time"
            render={(text, record) => (
              <span>{timeConverter(record.start_datetime.$date)}</span>
            )}
          />
          <Column
            title="End Time"
            key="End Time"
            render={(text, record) => (
              <span>{timeConverter(record.end_datetime.$date)}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {/* <Link
                  to={{
                    pathname: "/viewProduct",
                    state: { product_id: record._id.$oid }
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    View
                  </Button>
                </Link> */}

                {GET_USER_LOCAL().org_admin ? (
                  <Link
                    to={{
                      pathname: "/editSalesTarget",
                      state: { sales_target_id: record._id.$oid }
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {GET_USER_LOCAL().org_admin ? (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteSalesTarget(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 && this.state.data.length < this.state.total ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
