import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tag, Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { EDIT_MONTHLYBILL, CREATE_MONTHLYBILL } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  LOCAL_USER_INFO
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { Column } = Table;

class MonthlyBillList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    param: "true",
    isStaff: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.setState({ isStaff: LOCAL_USER_INFO().user.is_staff });
    this.getMonthlyBillList();
    ReactGA.pageview(window.location.pathname);
  }

  handleParamChange = value => {
    this.setState({ param: value }, () => {
      console.log(this.state.param);
      this.getMonthlyBillList();
    });
  };

  getMonthlyBillList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_MONTHLYBILL}?upcoming=${
        this.state.param
      }&d=${false}&page=${this.state.page}&limit=${
        this.state.limit
      }&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from monthly bill list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from monthly bill list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getMonthlyBillList();
      }
    );
  };

  deleteOrder = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_MONTHLYBILL}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.state.isStaff ? (
          <Link to="/createMonthlyBill">
            <Tooltip placement="top" title="Create New Monthly Bill">
              <Button
                shape="circle"
                icon="plus"
                style={{
                  position: "fixed",
                  zIndex: 100,
                  bottom: 30,
                  right: 20,
                  backgroundColor: "#1A3337",
                  height: 60,
                  width: 60,
                  fontSize: 30,
                  paddingBottom: 5
                }}
              />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Monthly Bills"
        />
        {/* <div style={{margin:5,textAlign:"end"}}>
            <Text style={{margin:5}}>Upcoming : </Text>
            <Select defaultValue="false" value={this.state.param} onChange={this.handleParamChange}>
              <Option value="true">true</Option>
              <Option value="false">false</Option>
            </Select>
            </div> */}

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title="Org Name"
            key="Org_Name"
            render={(value, record) => <span>{record.org.name}</span>}
          />
          <Column
            title="Item Mobile"
            dataIndex="items"
            key="items"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="blue"
                    key={item.user}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.mobile}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Item Name"
            dataIndex="items"
            key="items3"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="red"
                    key={item.user}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.name}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Item Price"
            dataIndex="items"
            key="items2"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="green"
                    key={item.user}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.price}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Item Discount Period"
            dataIndex="items"
            key="items4"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="yellow"
                    key={item.user}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.discount_for_period}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Days"
            dataIndex="items"
            key="items5"
            render={items => (
              <span>
                {items.map(item => (
                  <Tag
                    color="purple"
                    key={item.user}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2
                    }}
                  >
                    {item.days}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title="Has Paid"
            key="has_paid"
            render={(value, record) => (
              <span>{record.has_paid.toString()}</span>
            )}
          />
          <Column title="Tax Amount" dataIndex="tax_amount" key="tax_amount" />
          <Column title="Dues" dataIndex="dues" key="dues" />
          <Column
            title="Billed Amount"
            dataIndex="billed_amount"
            key="billed_amount"
          />
          <Column
            title="Discount Amount"
            dataIndex="discount_amount"
            key="discount_amount"
          />
          <Column title="VAT Id" dataIndex="vat_id" key="vat_id" />
          <Column title="Payments" dataIndex="payments" key="payments" />
          <Column title="Total" dataIndex="sum_total" key="sum_total" />
          <Column title="Balance" dataIndex="balance" key="balance" />
          <Column title="Year" dataIndex="year" key="year" />
          <Column title="Month" dataIndex="month" key="month" />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.generated_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.state.isStaff ? (
                  <Link
                    to={{
                      pathname: "/staffBillPayment",
                      state: { monthlyBill_id: record._id.$oid }
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Payment
                    </Button>
                  </Link>
                ) : this.props.userProfileData.organization.organization_profile
                    .monthly_payment_view ? (
                  <Link
                    to={{
                      pathname: "/viewMonthlyBill",
                      state: { monthlyBill_id: record._id.$oid }
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}

                {this.state.isStaff ? (
                  <div>
                    <Button
                      type="link"
                      style={{ color: "red" }}
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          this.deleteOrder(record._id.$oid);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(MonthlyBillList);
