import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Select,
  Typography,
  Pagination,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";

import { CREATE_PRODUCT_CATEGORY } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import ReactGA from "react-ga";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

export default class ProductCategoryList extends Component {
    state = {
        data: [],
        page: 0,
        total: 0,
        loading: false,
        limit: 10
      };
      componentDidMount() {
        this.getProductCategoryList();
        ReactGA.pageview(window.location.pathname);
      }
    
      getProductCategoryList = () => {
        GET_ACCESS_TOKEN().then(token => {
          const url = `${CREATE_PRODUCT_CATEGORY}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;
    
          axios({
            method: "get",
            url: url
          })
            .then(response => {
              console.log("response from product category list", response);
              this.setState({
                data: response.data.data,
                total: response.data.total
              });
            })
            .catch(error => {
              console.log("error from product category list api", error);
            });
        });
      };
    
      pageChange = (current, pageSize) => {
        this.setState({ page: current - 1 }, () => {
          console.log("page number", this.state.page);
          this.getProductCategoryList();
        });
      };
    
      setLimit = value => {
        this.setState({ limit: value }, () => {
          console.log(this.state.limit);
          this.getProductCategoryList();
        });
      };
    
     
    render() {
        return (
            <Card style={{ margin: 10 }}>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Product Category List"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div style={{ margin: 10 }}>
                <Text>{`Displaying : `}</Text>
                <Select value={this.state.limit} onChange={this.setLimit}>
                  <Option key={10}>10</Option>
                  <Option key={25}>25</Option>
                  <Option key={50}>50</Option>
                  <Option key={100}>100</Option>
                </Select>
                <Text>{`  Entries `}</Text>
              </div>
            </div>
            <Table
              dataSource={this.state.data}
              pagination={false}
              rowKey={record => record._id.$oid}
              size="small"
              bordered
            >
              <Column title="Name" dataIndex="name" key="name" />
              <Column
                title="Prefix"
                dataIndex="product_category_prefix"
                key="product_category_prefix"
              />
              <Column
                title="Parent Category Name"
                dataIndex="parent_category_name"
                key="parent_category_name"
              />
              <Column
                title="Modified On"
                key="modified_on"
                render={(text, record) => (
                  <span>{timeConverter(record.modified_on.$date)}</span>
                )}
              />
              <Column
                title="Created On"
                key="created_by"
                render={(text, record) => (
                  <span>{timeConverter(record.created_on.$date)}</span>
                )}
              />
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <Link
                      to={{
                        pathname: "/editProductCategory",
                        state: { product_category_id: record._id.$oid }
                      }}
                    >
                      <Button type="link" style={{ color: "green" }}>
                        Edit
                      </Button>
                    </Link>
                  </span>
                )}
              />
            </Table>
            {this.state.total > 10 ? (
              <Pagination
                pageSize={Number(this.state.limit)}
                current={this.state.page + 1}
                total={this.state.total}
                onChange={this.pageChange}
                style={{ marginTop: 10 }}
              />
            ) : null}
          </Card>
        )
    }
}
