import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_DRIVER,
  ASSET_SEARCH,
  CREATE_TASK,
  CREATE_ORDER,
  CREATE_PARCEL,
  CREATE_CONTAINER,
  EDIT_INCIDENT,
  INCIDENT_TYPE_CHOICES
} from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditIncident extends Component {
  state = {
    name: "",
    incident_type: "",
    reason: "",
    asset_id: "",
    asset_name: "",
    task_id: "",
    task_name: "",
    order_id: "",
    order_name: "",
    parcel_name: "",
    parcel_id: "",
    container_id: "",
    container_name: "",
    created_for_id: "",
    created_for_name: "",
    incidentData: [],
    taskData: [],
    assetData: [],
    userData: [],
    orderData: [],
    parcelData: [],
    containerData: [],
    incident_id:""
  };

  componentDidMount() {
    this.getIncident()
    this.getincidentType();
    this.getAsset();
    this.getTask();
    this.getUser();
    this.getOrder();
    this.getParcel();
    this.getContainer();
  }

  getIncident = () => {
    this.setState({ incident_id: this.props.location.state.incident_id });

    GET_ACCESS_TOKEN().then(token => {
      let incident_id = this.props.location.state.incident_id;
      let url = `${EDIT_INCIDENT}${incident_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit incident", response);
        this.setState({
            name: response?.data?.data?.name??"",
            incident_type: response?.data?.data?.incident_type??"",
            reason: response?.data?.data?.reason??"",
            asset_id: response?.data?.data?.asset?.id??"",
            asset_name: response?.data?.data?.asset?.name??"",
            task_id: response?.data?.data?.task?.id??"",
            task_name: response?.data?.data?.task?.name??"",
            order_id: response?.data?.data?.order?.id??"",
            order_name: response?.data?.data?.order?.name??"",
            parcel_name: response?.data?.data?.parcel?.id??"",
            parcel_id: response?.data?.data?.parcel?.name??"",
            container_id: response?.data?.data?.container?.id??"",
            container_name: response?.data?.data?.container?.name??"",
            created_for_id: response?.data?.data?.created_for?.id??"",
            created_for_name: response?.data?.data?.created_for?.name??""
        });
      });
    });
  };

  getincidentType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${INCIDENT_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ incidentData: response.data.incident_type_choices });
          console.log("inc",response);
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getAsset = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${ASSET_SEARCH}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getUser = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getTask = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_TASK}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ taskData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getOrder = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ORDER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ orderData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getParcel = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_PARCEL}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ parcelData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getContainer = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_CONTAINER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ containerData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  setUser = value => {
    this.setState({ created_for_id: value.key, created_for_name: value.label }, () => {
      console.log(this.state.created_for_name);
    });
  };
  setIncidentType = value => {
    this.setState({ incident_type: value }, () => {
      console.log(this.state.incident_type);
    });
  };
  setAsset = value => {
    this.setState({ asset_id: value.key, asset_name: value.label }, () => {
      console.log(this.state.asset_name);
    });
  };
  setTask = value => {
    this.setState({ task_id: value.key, task_name: value.label }, () => {
      console.log(this.state.task_name);
    });
  };
  setOrder = value => {
    this.setState({ order_id: value.key, order_name: value.label }, () => {
      console.log(this.state.order_name);
    });
  };
  setParcel = value => {
    this.setState({ parcel_id: value.key, parcel_name: value.label }, () => {
      console.log(this.state.parcel_name);
    });
  };
  setContainer = value => {
    this.setState({ container_id: value.key, container_name: value.label }, () => {
      console.log(this.state.container_name);
    });
  };
  setText = evt => {
    evt.preventDefault();
    this.setState({ text: evt.target.value }, () => {
      console.log(this.state.text);
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setReason = evt => {
    evt.preventDefault();
    this.setState({ reason: evt.target.value }, () => {
      console.log(this.state.reason);
    });
  };

  updateIncident = () => {
    const data = {
        name: this.state.name,
        incident_type: this.state.incident_type,
        reason: this.state.reason,
        asset: {
          id: this.state.asset_id,
          name: this.state.asset_name
        },
        task: {
          id: this.state.task_id,
          name: this.state.task_name
        },
        order: {
          id: this.state.order_id,
          name: this.state.order_name
        },
        parcel: {
          id: this.state.parcel_id,
          name: this.state.parcel_name
        },
        container: {
          id: this.state.container_id,
          name: this.state.container_name
        },
        created_for: {
          id: this.state.created_for_id,
          name: this.state.created_for_name
        }
      };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_INCIDENT}${this.state.incident_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from update incident", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from update incident", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="Create Incident"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Reason</Text>
            <Input
              placeholder="Reason"
              type="text"
              value={this.state.reason}
              onChange={this.setReason}
            />
          </Form.Item>
          <Form.Item>
              <Text>Incident Type</Text>
              <Select
                value={this.state.incident_type}
                onChange={this.setIncidentType}
                placeholder="Incident Type"
              >
                {this.state.incidentData.map(r => {
                  return <Option key={r}>{r}</Option>;
                })}
              </Select>
            </Form.Item>
          {GET_USER_LOCAL().org_admin || GET_USER_LOCAL().is_staff ? (
            <Form.Item>
              <Text>Incident For</Text>
              <Select
                labelInValue
                value={{
                  key: this.state.created_for_id,
                  label: this.state.created_for_name
                }}
                onChange={this.setUser}
                placeholder="Incident For"
              >
                {this.state.userData.map(r => {
                  return <Option key={r._id.$oid}>{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Text>Asset</Text>
            <Select
              labelInValue
              value={{
                key: this.state.asset_id,
                label: this.state.asset_name
              }}
              onChange={this.setAsset}
              placeholder="Asset"
            >
              {this.state.assetData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Task</Text>
            <Select
              labelInValue
              value={{
                key: this.state.task_id,
                label: this.state.task_name
              }}
              onChange={this.setTask}
              placeholder="Task"
            >
              {this.state.taskData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Order</Text>
            <Select
              labelInValue
              value={{
                key: this.state.order_id,
                label: this.state.order_name
              }}
              onChange={this.setOrder}
              placeholder="Order"
            >
              {this.state.orderData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Parcel</Text>
            <Select
              labelInValue
              value={{
                key: this.state.parcel_id,
                label: this.state.parcel_name
              }}
              onChange={this.setParcel}
              placeholder="Parcel"
            >
              {this.state.parcelData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Container</Text>
            <Select
              labelInValue
              value={{
                key: this.state.container_id,
                label: this.state.container_name
              }}
              onChange={this.setContainer}
              placeholder="Container"
            >
              {this.state.containerData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateIncident}
            >
              Update Incident
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
