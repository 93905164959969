import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tag,
  Radio,
  Checkbox,
  Icon
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_TERRITORY,
  CREATE_CUSTOM_FIELD,
  ADD_CUSTOM_FIELDS_TO_TASK,
  MULTI_IMAGE_UPLOAD
} from "../../api/constants";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Map, FeatureGroup, Polygon } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import { GET_ACCESS_TOKEN, isValidURL } from "../../utilities/utilities";

import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default class ViewTerritory extends Component {
  state = {
    name: "",
    assigned_users: [],
    user_data: [],
    filtered: [],
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 12,
    polygon_data: [],
    territory_id: "",
    previous_users: [],
    delete_assigned_users: [],
    edit_polygon: false,
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {}
  };
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    this.setState({ territory_id: this.props.location.state.territory_id });
    this.getCustomFields();
    this.getTerritoryDetails();
  }

  getCustomFields = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=territory&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({
            custom_fields: response.data.data
          });
          console.log("response from get custom fields", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };

  getTerritoryDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let territory_id = this.props.location.state.territory_id;
      let url = `${EDIT_TERRITORY}${territory_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit territory", response.data.data);

        this.setState({
          name: response.data?.data?.name ?? "",
          previous_users: response.data?.data?.assigned_users ?? [],
          polygon_data: response.data?.data?.area?.coordinates[0],
          getCustomFieldData: response.data?.data?.custom_fields ?? {}
        });
      });
    });
  };

  updateCustomTerritoryData = checked => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "territory",
        custom_fields: this.state.custom_field_data
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.territory_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            if (response.data.message === "Data successfully updated!") {
              this.getTerritoryDetails();
            }
            console.log(
              "response from custom field territory data update ",
              response
            );
          })
          .catch(error => {
            this.getTerritoryDetails();
            swal.fire("Error", error.message, "error");
            console.log(
              "error from custom field territory data update ",
              error
            );
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const { custom_field_data } = this.state;
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="View Territory"
          onBack={() => this.props.history.goBack()}
        />
        <div>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{ KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",VERSION:"3.49.2" }}
              type={"roadmap"}
            />

            <FeatureGroup>
              <Polygon
                positions={[this.state.polygon_data.map(m => [m[1], m[0]])]}
              />
            </FeatureGroup>
          </Map>
        </div>
        <Card
          style={{
            marginTop: 5,
            width: "100%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Name</Text>: {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Assigned Users</Text>:{" "}
            {this.state.previous_users.length > 0
              ? this.state.previous_users.map((u, index) => (
                  <Text key={index}>{`${u.name}-${u.mobile},`}</Text>
                ))
              : null}
          </p>
        </Card>
        {Object.keys(this.state.getCustomFieldData).length !== 0 ? (
          <Card
            style={{
              marginTop: 5,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Custom Field Data"
            />
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 5,
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)"
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>{`${f.label} (Type : ${f._type}) : `}</Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={value => {
                            let obj = {
                              [f.key_name]: value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={e => {
                            let obj = {
                              [f.key_name]: e.target.value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={checkedValues => {
                            let obj = {
                              [f.key_name]: checkedValues
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={value => {
                            let obj = {
                              [f.key_name]: value
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                )
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map(item => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={evt => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then(token => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data"
                                  }
                                })
                                  .then(response => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map(res => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          )
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch(error => {
                                    swal.fire("Error", error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value)
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  )
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText
                                  ]
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      )
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white"
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: []
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomTerritoryData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}
      </Card>
    );
  }
}
