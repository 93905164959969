import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tooltip,
  Input,
  Select,
  Divider,
  Typography,
  Pagination,
  Icon,
} from "antd";
import { connect } from "react-redux";
import { saveAssetListParams } from "../../redux/actions/assetListParamsAction";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import {
  ASSET_SEARCH,
  EDIT_ASSET,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";
// import { Divider } from "@material-ui/core";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class AssetList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    datarange: "",
    name: "",
    asset: "",
    user_name: "",
    pin: "",
    asset_type: "",
    sub_org_name: "",
    sub_org_type_name: "",
    sub_org_parent_name: "",
    chasis_number: "",
    engine_number: "",
    vehicle_owners_name: "",
    vehicle_sellers_name: "",
    brand_name: "",
    registration_number: "",
    search_date_time_type: "created_on",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    loading: false,
    sort_by: "created_on",
    sort_type: "dsc",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_asset_search: "",
  };
  componentDidMount() {
    this.props.getUserData();
    console.log("redux", this.props.assetListParams);
    if (Object.keys(this.props.assetListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          name: this.props?.assetListParams?.name ?? "",
          asset: this.props?.assetListParams?.asset ?? "",
          user_name: this.props?.assetListParams?.user_name ?? "",
          pin: this.props?.assetListParams?.pin ?? "",
          asset_type: this.props?.assetListParams?.asset_type ?? "",
          sub_org_name: this.props?.assetListParams?.sub_org_name ?? "",
          sub_org_type_name:
            this.props?.assetListParams?.sub_org_type_name ?? "",
          sub_org_parent_name:
            this.props?.assetListParams?.sub_org_parent_name ?? "",
          chasis_number: this.props?.assetListParams?.chasis_number ?? "",
          engine_number: this.props?.assetListParams?.engine_number ?? "",
          vehicle_owners_name:
            this.props?.assetListParams?.vehicle_owners_name ?? "",
          vehicle_sellers_name:
            this.props?.assetListParams?.vehicle_sellers_name ?? "",
          brand_name: this.props?.assetListParams?.brand_name ?? "",
          registration_number:
            this.props?.assetListParams?.registration_number ?? "",
          search_date_time_type:
            this.props?.assetListParams?.search_date_time_type ?? "created_on",
          start_time: this.props?.assetListParams?.start_time ?? "",
          start_time_view:
            this.props?.assetListParams?.start_time_view ?? moment(),
          end_time: this.props?.assetListParams?.end_time ?? "",
          end_time_view: this.props?.assetListParams?.end_time_view ?? moment(),
          limit: this.props?.assetListParams?.limit ?? 10,
          page: this.props?.assetListParams?.page ?? 0,
          sort_by: this.props?.assetListParams?.sort_by ?? "created_on",
          sort_type: this.props?.assetListParams?.sort_type ?? "dsc",
        },
        () => {
          this.getCurrentUserSubOrgs();
        }
      );
    } else {
      this.getCurrentUserSubOrgs();
    }
    ReactGA.pageview(window.location.pathname);
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_asset_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getAssetList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getAssetList = debounce(() => {
    let reduxdata = {
      name: this.state.name,
      asset: this.state.asset,
      user_name: this.state.user_name,
      pin: this.state.pin,
      asset_type: this.state.asset_type ?? "",
      sub_org_name: this.state.sub_org_name ?? "",
      sub_org_type_name: this.state.sub_org_type_name ?? "",
      sub_org_parent_name: this.state.sub_org_parent_name ?? "",
      chasis_number: this.state.chasis_number ?? "",
      engine_number: this.state.engine_number ?? "",
      vehicle_owners_name: this.state.vehicle_owners_name ?? "",
      vehicle_sellers_name: this.state.vehicle_sellers_name ?? "",
      brand_name: this.state.brand_name ?? "",
      registration_number: this.state.registration_number ?? "",
      search_date_time_type: this.state.search_date_time_type ?? "",
      start_time: this.state.start_time ?? "",
      start_time_view: this.state.start_time_view ?? moment(),
      end_time: this.state.end_time ?? "",
      end_time_view: this.state.end_time_view ?? moment(),
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      limit: this.state.limit,
      page: this.state.page,
    };
    this.props.saveAssetListParams(reduxdata);
    console.log("reduxdata going in", reduxdata);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?sub_org=${this.state.sub_org_id_asset_search}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&search_date_time_type=${this.state.search_date_time_type}&registration_number=${this.state.registration_number}&brand_name=${this.state.brand_name}&vehicle_sellers_name=${this.state.vehicle_sellers_name}&vehicle_owners_name=${this.state.vehicle_owners_name}&engine_number=${this.state.engine_number}&chasis_number=${this.state.chasis_number}&sub_org_parent_name=${this.state.sub_org_parent_name}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_name=${this.state.sub_org_name}&asset_type=${this.state.asset_type}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&name=${this.state.name}&asset=${this.state.asset}&user_name=${this.state.user_name}&pin=${this.state.pin}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from asset list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        asset: "",
        name: "",
        user_name: "",
        pin: "",
        asset_type: "",
        sub_org_name: "",
        sub_org_type_name: "",
        sub_org_parent_name: "",
        chasis_number: "",
        engine_number: "",
        vehicle_owners_name: "",
        vehicle_sellers_name: "",
        brand_name: "",
        registration_number: "",
        search_date_time_type: "created_on",
        start_time: "",
        start_time_view: moment(),
        end_time: "",
        end_time_view: moment(),
        current_user_sub_org_id: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.current_user_sub_org_id,
        sub_org_id_order_search: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.sub_org_id_asset_search,
        sort_by: "created_by",
        sort_type: "dsc",
        page: 0,
        limit: 10,
      },
      () => {
        this.getAssetList();
      }
    );
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_asset_search: value },
      () => {
        this.getAssetList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_asset_search: value },
      () => {
        this.getAssetList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getAssetList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAssetList();
    });
  };

  deleteAsset = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ASSET}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.asset.asset_create ? (
          <Link to="/createAsset">
            <Tooltip placement="top" title="Create New Asset">
              <Button
                shape="circle"
                icon="plus"
                style={{
                  position: "fixed",
                  zIndex: 100,
                  bottom: 30,
                  right: 20,
                  backgroundColor: "#1A3337",
                  height: 60,
                  width: 60,
                  fontSize: 30,
                  paddingBottom: 5,
                }}
              />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Assets"
        />
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select date range within which you would like to find your Assets
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <Text>Filter Assets By : </Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getAssetList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                style={{ minWidth: 200 }}
              >
                <Option key="created_on">Created on</Option>
                <Option key="registration_date">Registration Date</Option>
                <Option key="purchase_date">Purchase Date</Option>
                <Option key="tax_token_expiry_date">
                  Tax Token Expiry Date
                </Option>
                <Option key="tax_token_renewal_reminder_date">
                  Tax Token Renewal Reminder Date
                </Option>
                <Option key="fitness_expiry_date">Fiteness Expiry Date</Option>
                <Option key="fitness_document_renewal_reminder_date">
                  Fiteness Documents Renewal Reminder Date
                </Option>
                <Option key="route_permit_document_expiry_date">
                  Route Permit Expiry Date
                </Option>
                <Option key="route_permit_document_renewal_reminder_date">
                  Route Permit Renewal Reminder Date
                </Option>
                <Option key="insurance_document_expiry_date">
                  Insurance Document Expiry Date
                </Option>
                <Option key="insurance_document_renewal_reminder_date">
                  Insurance Document Renewal Reminder Date
                </Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.current_user_sub_orgs.length < 1 ? true : false
                }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <Text> Child Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.first_child_sub_orgs.length < 1 ? true : false
                }
                value={this.state.first_child_sub_org_id}
                onChange={this.setFirstChildSubOrg}
              >
                {this.state.first_child_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
          </div>
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getAssetList();
                    });
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Sub Org Name</Text>
                </div>

                <Input
                  value={this.state.sub_org_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_name: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="sub_org"
            render={(text, record) => <span>{record.sub_org.name}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Registration Number</Text>
                </div>

                <Input
                  value={this.state.registration_number}
                  onChange={(evt) => {
                    this.setState(
                      { registration_number: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="registration_number"
            render={(text, record) => <span>{record.registration_number}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Device ID</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "asset", sort_type: "asc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "asset", sort_type: "dsc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.asset}
                  onChange={(evt) => {
                    this.setState({ asset: evt.target.value, page: 0 }, () => {
                      this.getAssetList();
                    });
                  }}
                />
              </>
            }
            dataIndex="asset"
            key="asset"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Connected User</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    {" "}
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "user_name", sort_type: "asc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "user_name", sort_type: "dsc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.user_name}
                  onChange={(evt) => {
                    this.setState(
                      { user_name: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="assigned_user"
            render={(text, record) => <span>{record.user_name}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Address</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="address"
            render={(text, record) => (
              <span>{`${record.address}, ${record.city}, ${record.country}`}</span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Current Mileage</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="current_mileage"
            render={(text, record) => <span>{record.current_mileage}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Mileage Settings</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="maint_category"
            render={(text, record) => <span>{record.maint_category}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Maintainance Category</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="mileage_settings"
            render={(text, record) => <span>{record.mileage_settings}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Maintainance Renewal Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="maint_renewal_date"
            render={(text, record) => (
              <span>
                {record.maint_renewal_date
                  ? timeConverter(record.maint_renewal_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Chasis Number</Text>
                </div>

                <Input
                  value={this.state.chasis_number}
                  onChange={(evt) => {
                    this.setState(
                      { chasis_number: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="chasis_number"
            render={(text, record) => <span>{record.chasis_number}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Engine Number</Text>
                </div>

                <Input
                  value={this.state.engine_number}
                  onChange={(evt) => {
                    this.setState(
                      { engine_number: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="engine_number"
            render={(text, record) => <span>{record.engine_number}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>CC</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="cc"
            render={(text, record) => <span>{record.cc}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Model</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="model"
            render={(text, record) => <span>{record.model}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Brand Name</Text>
                </div>

                <Input
                  value={this.state.brand_name}
                  onChange={(evt) => {
                    this.setState(
                      { brand_name: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="brand_name"
            render={(text, record) => <span>{record.brand_name}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Body Color</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="body_color"
            render={(text, record) => <span>{record.body_color}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Fuel Used</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="fuel_used"
            render={(text, record) => <span>{record.fuel_used}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Fuel Expense Unit</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="fuel_expense_unit"
            render={(text, record) => <span>{record.fuel_expense_unit}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Maintainance Expense Unit</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="maint_expense_unit"
            render={(text, record) => <span>{record.maint_expense_unit}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Purchase Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="purchase_date"
            render={(text, record) => (
              <span>
                {record.purchase_date
                  ? timeConverter(record.purchase_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Registration Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="registration_date"
            render={(text, record) => (
              <span>
                {record.registration_date
                  ? timeConverter(record.registration_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Purchase Unit</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="purchase_unit"
            render={(text, record) => <span>{record.purchase_unit}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}> Vehicle Purchase Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="vehicle_purchase_amount"
            render={(text, record) => (
              <span>{record.vehicle_purchase_amount}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}> Vehicle Owner's Name</Text>
                </div>

                <Input
                  value={this.state.vehicle_owners_name}
                  onChange={(evt) => {
                    this.setState(
                      { vehicle_owners_name: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="vehicle_owners_name"
            render={(text, record) => <span>{record.vehicle_owners_name}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}> Vehicle Seller's Name</Text>
                </div>

                <Input
                  value={this.state.vehicle_sellers_name}
                  onChange={(evt) => {
                    this.setState(
                      { vehicle_sellers_name: evt.target.value, page: 0 },
                      () => {
                        this.getAssetList();
                      }
                    );
                  }}
                />
              </>
            }
            key="vehicle_sellers_name"
            render={(text, record) => (
              <span>{record.vehicle_sellers_name}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Remarks</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="remarks"
            render={(text, record) => <span>{record.remarks}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Tax Token File</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="tax_token_file"
            render={(text, record) => (
              <span>{record.tax_token_file ? "Uploaded" : "N/A"}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Tax Token Expiry Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="tax_token_expiry_date"
            render={(text, record) => (
              <span>
                {record.tax_token_expiry_date
                  ? timeConverter(record.tax_token_expiry_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Tax Token Renewal Reminder Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="tax_token_renewal_reminder_date"
            render={(text, record) => (
              <span>
                {record.tax_token_renewal_reminder_date
                  ? timeConverter(record.tax_token_renewal_reminder_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Fitness Document File</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="fitness_document_file"
            render={(text, record) => (
              <span>{record.fitness_document_file ? "Uploaded" : "N/A"}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Fitness Document Expiry Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="fitness_expiry_date"
            render={(text, record) => (
              <span>
                {record.fitness_expiry_date
                  ? timeConverter(record.fitness_expiry_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>
                    Fitness Document Renewal Reminder Date
                  </Text>
                  <Input disabled />
                </div>
              </>
            }
            key="fitness_document_renewal_reminder_date"
            render={(text, record) => (
              <span>
                {record.fitness_document_renewal_reminder_date
                  ? timeConverter(
                      record.fitness_document_renewal_reminder_date?.$date
                    )
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Route Permit Document File</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="route_permit_document_file"
            render={(text, record) => (
              <span>
                {record.route_permit_document_file ? "Uploaded" : "N/A"}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Route Permit Expiry Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="route_permit_document_expiry_date"
            render={(text, record) => (
              <span>
                {record.route_permit_document_expiry_date
                  ? timeConverter(
                      record.route_permit_document_expiry_date?.$date
                    )
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>
                    Route Permit Renewal Reminder Date
                  </Text>
                  <Input disabled />
                </div>
              </>
            }
            key="route_permit_document_renewal_reminder_date"
            render={(text, record) => (
              <span>
                {record.route_permit_document_renewal_reminder_date
                  ? timeConverter(
                      record.route_permit_document_renewal_reminder_date?.$date
                    )
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Insurance Document File</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="insurance_document_file"
            render={(text, record) => (
              <span>{record.insurance_document_file ? "Uploaded" : "N/A"}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Insurance Document Expiry Date</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="insurance_document_expiry_date"
            render={(text, record) => (
              <span>
                {record.insurance_document_expiry_date
                  ? timeConverter(record.insurance_document_expiry_date?.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>
                    Insurance Document Renewal Reminder Date
                  </Text>
                  <Input disabled />
                </div>
              </>
            }
            key="insurance_document_renewal_reminder_date"
            render={(text, record) => (
              <span>
                {record.insurance_document_renewal_reminder_date
                  ? timeConverter(
                      record.insurance_document_renewal_reminder_date?.$date
                    )
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>PIN</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    {" "}
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "pin", sort_type: "asc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "pin", sort_type: "dsc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.pin}
                  onChange={(evt) => {
                    this.setState({ pin: evt.target.value, page: 0 }, () => {
                      this.getAssetList();
                    });
                  }}
                />
              </>
            }
            dataIndex="pin"
            key="pin"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Created On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "asc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "dsc" },
                          () => {
                            this.getAssetList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.asset.add_reminder ? (
                  <Link
                    to={{
                      pathname: "/createReminder",
                      state: {
                        asset_id: record._id.$oid,
                        asset_name: record.name,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Add Reminder
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.asset.asset_detail ? (
                  <Link
                    to={{
                      pathname: "/viewAsset",
                      state: { asset_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.asset.asset_update ? (
                  <Link
                    to={{
                      pathname: "/editAsset",
                      state: { asset_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {record.connect_key?.asset_provider_id &&
                (this.props.userProfileData.data_level.access_all_data ||
                  this.props.userProfileData.data_level.access_sub_org_data) ? (
                  <Link
                    to={{
                      pathname: "/assetLastLocation",
                      state: {
                        asset_id: record._id.$oid,
                        asset_provider_id:
                          record.connect_key?.asset_provider_id ?? "",
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View Last Location
                    </Button>
                  </Link>
                ) : null}
                {record.connect_key?.asset_provider_id &&
                (this.props.userProfileData.data_level.access_all_data ||
                  this.props.userProfileData.data_level.access_sub_org_data) ? (
                  <Link
                    to={{
                      pathname: "/routeHistory",
                      state: {
                        asset_id: record._id.$oid,
                        asset_provider_id:
                          record.connect_key?.asset_provider_id ?? "",
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View Route History
                    </Button>
                  </Link>
                ) : null}

                <div>
                  {GET_USER_LOCAL().org_admin ||
                  this.props.userProfileData.asset.asset_delete ? (
                    <div>
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteDriver(record._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null}
                </div>
              </span>
            )}
          />
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  assetListParams: state.assetListParams.assetListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { saveAssetListParams, getUserData })(
  AssetList
);
