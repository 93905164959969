import React, { Component } from "react";
import {
  //   Table,
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
} from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
// import { debounce } from "lodash";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  CREATE_BILL_TYPE,
  CREATE_BILL,
  CREATE_ROLE,
  CREATE_BILL_SETTINGS,
  CURRENT_USER_SUB_ORG_LIST
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  //   CHECK_BOOL,
  //   GET_USER_LOCAL,
} from "../../utilities/utilities";

import ReactGA from "react-ga";

import "../login.css";

// const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
// const { Column } = Table;

export default class CreateAutomatedBill extends Component {
  state = {
    name: "",
    miscellaneous_bill_type_choices: [],
    bill_for_choices: [],
    role_choices: [],
    misc_bill_type_id: "",
    misc_bill_type_name: "",
    bill_for_id: "",
    bill_for_name: "",
    role_id: "",
    start_time: moment().startOf("day").format("x"),
    start_time_view: moment().startOf("day"),
    end_time: moment().endOf("day").format("x"),
    end_time_view: moment().endOf("day"),
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
  };

  componentDidMount() {
    this.getBillTypeChoices();
    this.getBillForChoices();
    this.getRoleChoices();

    ReactGA.pageview(window.location.pathname);
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data?.data??[],
              current_user_sub_org_id:
                response.data.data.length > 0&&!this.props.userProfileData.data_level.access_all_data ? response?.data?.data[0].id : ""
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getBillTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            miscellaneous_bill_type_choices: response.data?.data??[],
            misc_bill_type: response.data?.data[0]?.name??"",
            misc_bill_type_id: response.data?.data[0]?._id?.$oid??"",
          });
          console.log("response from bill type choices", response);
        })
        .catch((error) => {
          console.log("error from bill type choices api", error);
        });
    });
  };
  getBillForChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_SETTINGS}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            bill_for_choices: response?.data?.data??[],
            bill_for_id:response?.data?.data[0]?._id?.$oid ??"",
            bill_for_name: response?.data?.data[0]?.name ??"",
          });
          console.log("response from bill for choices", response);
        })
        .catch((error) => {
          console.log("error from bill for choices api", error);
        });
    });
  };
  getRoleChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROLE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            role_choices: response.data?.data??[],
          });
          console.log("response from role choices", response);
        })
        .catch((error) => {
          console.log("error from role choices api", error);
        });
    });
  };

  setBillType = (value) => {
    this.setState(
      { misc_bill_type_id: value.key, misc_bill_type_name: value.label },
      () => {
        console.log(this.state.misc_bill_type_name);
      }
    );
  };
  setBillFor = (value) => {
    this.setState(
      { bill_for_id: value.key, bill_for_name: value.label },
      () => {
        console.log(this.state.bill_for_id);
      }
    );
  };

  setRoles = (value) => {
    this.setState({ role_id: value }, () => {
      console.log(this.state.role_id);
    });
  };

  
  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value}
    );
  };

  createAutomatedBill = () => {
    if(this.state.role_id===""){
      swal.fire("Info","Please select a role","info")
    }else{
      let data = {
        automated: true,
        bill_for: this.state.bill_for_id,
        role_id: this.state.role_id,
        misc_bill_type_id: this.state.misc_bill_type_id,
        start_date: this.state.start_time,
        end_date: this.state.end_time,
        sub_org: this.state.current_user_sub_org_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_BILL}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
  
            console.log("response from create Bill automated", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from create Bill automated", error);
          });
      });
    }
    
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/billList" />;
    }

    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Create Bulk Bill"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Form.Item>
                <Text>Bill For</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.bill_for_id,
                    label: this.state.bill_for_name,
                  }}
                  onChange={this.setBillFor}
                >
                  {this.state.bill_for_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>Bill Type</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.misc_bill_type_id,
                    label: this.state.misc_bill_type_name,
                  }}
                  onChange={this.setBillType}
                  placeholder="Bill Type"
                >
                  {this.state.miscellaneous_bill_type_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>User Role</Text>
                <Select value={this.state.role_id} onChange={this.setRoles}>
                  {this.state.role_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Sub Org</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 5,
                  }}
                >
                  <Text>Start Time</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.start_time_view}
                      onChange={(value, dateString) => {
                        this.setState({
                          start_time: moment(value).format("x"),
                          start_time_view: value,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: 5,
                  }}
                >
                  <Text>End Time</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.end_time_view}
                      onChange={(value, dateString) => {
                        this.setState({
                          end_time: moment(value)
                            .add(59, "seconds")
                            .format("x"),
                          end_time_view: value,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>
            </div>
          </div>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={() => this.createAutomatedBill()}
            >
              Create Bulk Bill
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
