import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Input, Table } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import { Spinner } from "../loader";
import {  ASSET_PROVIDER } from "../../api/constants";
import { GET_USER_LOCAL, GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Column } = Table;

export default class ConnecttoFinder extends Component {
  state = {
    loading: false,
    user_id: "",
    api_key: "",
    connect_key: "",
    assets: [],
    access_token: "",
    expires_on: "",
    provider: "finder"
  };

  componentDidMount() {
    this.setState({ user_id: GET_USER_LOCAL()._id.$oid });
    this.getSavedAssets();
  }

  setConnectkey = evt => {
    evt.preventDefault();
    this.setState({ connect_key: evt.target.value }, () => {
      console.log(this.state.connect_key);
    });
  };

  getSavedAssets = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ASSET_PROVIDER}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from Asset Provider get list", response);
          this.setState({
            assets: response.data.data
          });
        })
        .catch(error => {
          console.log("error from Asset Provider get list", error);
        });
    });
  };

  connectAsset = () => {
    if (this.state.connect_key === "") {
      swal.fire("Warning", "Please Enter Connect key", "warning");
    } else {
      const data = {
        user_id: this.state.user_id,
        connect_key: this.state.connect_key,
        provider: this.state.provider
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${ASSET_PROVIDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            console.log("response from asset provider post", response);
            swal.fire("Info", response.data.message, "info");
            if (response.data.status === "success") {
              this.getSavedAssets();
            }
          })
          .catch(error => {
            console.log("error from asset provider post", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10, backgroundColor: "#F5F5F5", minWidth: 500 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Select Your Asset then copy the connect key and paste here"
        />
        <Card style={{ margin: 10 }}>
          <Button
            className="login-form-button"
            htmlType="submit"
            onClick={() =>
              window.open("https://web.finder-lbs.com/connect-asset")
            }
          >
            Login to Finder
          </Button>
        </Card>
        <Card style={{ margin: 10 }}>
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <Text>Paste Connect key</Text>
              <Input
                placeholder="Paste Connect key"
                type="text"
                value={this.state.connect_key}
                onChange={this.setConnectkey}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.connectAsset}
              >
                Connect Asset
              </Button>
            </Form.Item>
          </Form>
        </Card>
        {this.state.assets.length > 0 ? (
          <Card style={{ margin: 10 }}>
            <Table
              dataSource={this.state.assets}
              pagination={false}
              rowKey={record => record._id.$oid}
              size="small"
              bordered
            >
              <Column title="Asset ID" dataIndex="asset_id" key="asset_id" />
              <Column title="Asset Name" dataIndex="name" key="name" />
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <Link
                      to={{
                        pathname: "/fuelData",
                        state: {
                          asset_id: record.asset_id,
                          access_token: record.access_token
                        }
                      }}
                    >
                      <Button type="link" style={{ color: "green" }}>
                        Fuel Data
                      </Button>
                    </Link>
                  </span>
                )}
              />
            </Table>
          </Card>
        ) : null}
      </Card>
    );
  }
}
