// main server
export const SERVER = process.env.REACT_APP_BASE_PATH;

// associated API's
export const SIGNUP_URL = SERVER + "auth/registration";
export const LOGIN_URL = SERVER + "auth/login";
export const PRE_SELECTED_PLAN_CHECK = SERVER + "app/user_pre_selected_plan/";
export const RESET_PASSWORD = SERVER + "auth/reset_password";
export const RESET_CODE = SERVER + "auth/reset_password/mobile_verify";
export const RESET_LINK = SERVER + "auth/reset_password/email_verify";
export const SEND_CODE = SERVER + "auth/send_code";
export const SEND_EMAIL = SERVER + "auth/send_email";
export const RESEND_EMAIL = SERVER + "auth/send_email?resend";
export const RESEND_CODE = SERVER + "auth/send_code?resend";
export const VERIFY_USER = SERVER + "auth/mobile_verify";
export const MOBILE_EMAIL_VERIFY_SUPER_USER = SERVER + "app/super/user_mobile_or_email_verified/";
export const VERIFY_EMAIL = SERVER + "auth/email_verify";
export const EMAIL_VERIFY = SERVER + "auth/email_verify?code=";
export const REFRESH_TOKEN = SERVER + "auth/refresh_token";
export const MOBILE_VERIFY = SERVER + "auth/mobile_verify?code=";
export const LOGOUT = SERVER + "auth/logout";
export const CREATE_TASK = SERVER + "app/task-list";
export const ADVANCED_TASK_SEARCH = SERVER + "app/task-search-list";
// export const ADVANCED_TASK_SEARCH = SERVER + "app/task-search-new-list";
export const TASK_SUMMARY_REPORT = SERVER + "reports/task-summary-report";
// export const TASK_SUMMARY_REPORT = SERVER + "app/task-summary-report-new";
export const EDIT_TASK = SERVER + "app/task/";
export const TASK_DASH = SERVER + "app/task-list-dash";
export const USERWISE_TASK_REPORT = SERVER + "app/userwise-task-report";
export const CREATE_TASK_TYPE = SERVER + "app/task-type-list";
export const EDIT_TASK_TYPE = SERVER + "app/task-type/";
export const CREATE_ORGANIZATION = SERVER + "app/org-list";
export const ORG_IMAGE_UPLOAD = SERVER + "app/web-image-upload/";
export const EDIT_ORGANIZATION = SERVER + "app/org/";
export const SWITCH_ORGANIZATION = SERVER + "app/user-org-switch/";
export const CREATE_DRIVER = SERVER + "app/user-list";
export const EDIT_DRIVER = SERVER + "app/user/";
export const EDIT_DRIVER_P = SERVER + "app/user?u_id=";
export const BULK_USER_UPLOAD = SERVER + "app/bulk_user";
export const CREATE_PLAN = SERVER + "app/plan-list";
export const EDIT_PLAN = SERVER + "app/plan/";
export const CREATE_SERVICE = SERVER + "app/service-list";
export const EDIT_SERVICE = SERVER + "app/service/";
export const CREATE_EXTRA_PACKAGE = SERVER + "app/extra-package-list";
export const ORGANIZATION_PLAN = SERVER + "app/org-plan/";
export const CREATE_ASSET = SERVER + "app/asset-list";
export const ASSET_SEARCH = SERVER + "app/asset-search-list";
export const ASSET_HISTORY = SERVER + "app/asset-history-for-collection/";
export const TASK_FROM_ASSET = SERVER + "app/gen-task-from-asset/";
export const EDIT_ASSET = SERVER + "app/asset/";
export const FINDER_ASSET_ROUTE_HISTORY = SERVER + "app/finder-route-history";
export const FINDER_ASSET_LAST_LOCATION = SERVER + "app/finder-last-location";
export const CREATE_SUPPLIER = SERVER + "app/supplier-list";
export const EDIT_SUPPLIER = SERVER + "app/supplier/";
export const CREATE_ENTITY = SERVER + "app/entity-list";
export const EDIT_ENTITY = SERVER + "app/entity/";
export const ENTITY_TYPE = SERVER + "app/entity-choices";
export const CREATE_PRODUCT = SERVER + "app/product-list";
export const EDIT_PRODUCT = SERVER + "app/product/";
export const CREATE_PRODUCT_CATEGORY = SERVER + "app/product-category-list";
export const EDIT_PRODUCT_CATEGORY = SERVER + "app/product-category/";
export const GENERATE_BARCODE_PRODUCT = SERVER + "app/generate-barcode-for-product/";
export const PRODUCT_SUBTRACT_REASON = SERVER + "app/subtract-reason-choices";
export const PRODUCT_ADD_REASON = SERVER + "app/add-reason-choices";
export const PRODUCT_STOCK_CHANGE =
  SERVER + "app/add-or-minus-stock-to-inventroy-product-stock/";
export const PRODUCT_STOCK_HISTORY =
  SERVER + "app/inventory-product-stock-history-list";
export const CREATE_MTYPE = SERVER + "app/mtype-list";
export const EDIT_MTYPE = SERVER + "app/mtype/";
export const CREATE_MATERIAL = SERVER + "app/material-list";
export const ALL_MATERIAL = SERVER + "app/all-materials";
export const EDIT_MATERIAL = SERVER + "app/material/";
export const CREATE_ORDER = SERVER + "app/order-list";
export const ORDER_FROM_CODE = SERVER + "app/order-qr-shodai";
export const ORDER_PRODUCT_STATUS_UPDATE =
  SERVER + "app/order-product-status-update/";
export const ORDER_PRODUCT_TAG = SERVER + "app/order-product-tag";
export const EDIT_ORDER = SERVER + "app/order/";
export const EDIT_ORDER_WITH_PROMOTION = SERVER + "app/order-with-promotion/";
export const ORDER_LOCATION_UPDATE = SERVER + "app/order-loc-update/";
export const ADVANCED_ORDER_SEARCH = SERVER + "app/order-search-list";
export const PROMOTION_ORDER_SEARCH = SERVER + "app/order-with-promotion-search-list";
export const CREATE_PROMOTION_ORDER = SERVER + "app/order-with-promotion-list";
export const ORDER_PAYMENT_METHOD_CHOICES =
  SERVER + "app/order-payment-method-choices";
export const ORDER_DISCOUNT_TYPE_CHOICES =
  SERVER + "app/order-discount-type-choices";
export const CREATE_PROCEDURE = SERVER + "app/procedure-list";
export const EDIT_PROCEDURE = SERVER + "app/procedure/";
export const PROCEDURE_CHOICES = SERVER + "app/procedure-choices";
export const CREATE_UNIT = SERVER + "app/unit-list";
export const EDIT_UNIT = SERVER + "app/unit/";
export const CREATE_MONTHLYBILL = SERVER + "app/monthly-bill-list";
export const EDIT_MONTHLYBILL = SERVER + "app/monthly-bill/";
export const GENERATE_MONTHLYBILL = SERVER + "app/monthly-bill-generate/";
export const MONTHLYBILL_PAYMENT = SERVER + "app/monthly-bill-payment/";
export const CREATE_BILL = SERVER + "app/bill-list";
export const BILL_SEARCH = SERVER + "app/bill-search-list";
export const CREATE_BILL_TYPE = SERVER + "app/bill-type-list";
export const EDIT_BILL = SERVER + "app/bill/";
export const BILL_ITEMS_EDIT = SERVER + "app/bill-items/";
// export const CONVEYANCE_BILL_PDF = SERVER + "app/conveyance_bill_pdf";
export const CONVEYANCE_BILL_PDF = SERVER + "app/conveyance-bill-pdf";
export const PURCHASE_BILL_PDF = SERVER + "app/purchase-bill-pdf";
export const INITIATE_PAYMENT = SERVER + "app/easy-pay/initiate";
export const INITIATE_PAYMENT_INT = SERVER + "app/pmt/initiate";
export const PROCESS_PAYMENT = SERVER + "app/easy-pay/processing/";
export const PROCESS_PAYMENT_INT = SERVER + "app/pmt/processing/";
export const SUCCESS_PAYMENT = SERVER + "app/easy-pay/success/";
export const SUCCESS_PAYMENT_INT = SERVER + "app/pmt/success/";
export const FAIL_PAYMENT_INT = SERVER + "app/pmt/fail/";
export const CANCEL_PAYMENT_INT = SERVER + "app/pmt/cancel/";
export const CREATE_REQUISITION = SERVER + "app/requisition-list";
export const REQUISITION_SEARCH = SERVER + "app/requisition-search-list";
export const EDIT_REQUISITION = SERVER + "app/requisition/";
export const REQUISITION_REPORT_PDF = SERVER + "app/requisition_pdf";
export const PURCHASE_REPORT_PDF = SERVER + "app/purchase_pdf";
export const CREATE_COUNTRY = SERVER + "app/ccountry-list";
export const EDIT_COUNTRY = SERVER + "app/ccountry/";
export const CREATE_ROUTE = SERVER + "app/route-list";
export const EDIT_ROUTE = SERVER + "app/route/";
export const CONNECT_DRIVER = SERVER + "app/connect-org-driver/";
export const CONNECT_ADMIN = SERVER + "app/connect-org-admin";
export const CONNECT_ASSET = SERVER + "app/connect-asset";
export const SEND_INVITE = SERVER + "app/send-invite";
export const GET_INVITE = SERVER + "app/get-invited/";
export const JOIN_INVITE = SERVER + "app/join-via-invitation/";
export const USER_PROFILE = SERVER + "user/profile";
export const TASK_ROUTE = SERVER + "app/task/route/";
export const USER_LOCATION_LIST = SERVER + "app/user-list/locations";
export const USER_ONLINE_LIST = SERVER + "app/user-list/online-offline";
export const ASSIGNED_USER_LOC = SERVER + "app/location/";
export const GET_TASK_COMMENTS = SERVER + "app/task-comment";
export const POST_TASK_COMMENTS = SERVER + "app/task-comments/";
export const CREATE_NOTIFICATION = SERVER + "app/notification-list";
export const UPDATE_NOTIFICATION = SERVER + "app/notification/";
export const TASK_LINK = SERVER + "app/task-link/";
export const ORDER_FROM_TASK_LINK = SERVER + "app/order-from-task-link/";
export const SHIPMENT_NUMBER = SERVER + "app/tasks-gen-code";
export const SEND_ORG_INVITATION = SERVER + "app/send-org-invite";
export const GET_INVITAION_DATA = SERVER + "app/get-org-invited/";
export const JOIN_ORG_INVITATION = SERVER + "app/join-org-via-invitation/";
export const ORG_CONNECT_LIST = SERVER + "app/org-connect-list";
export const EDIT_CONNECT_LIST = SERVER + "app/org-connect/";
export const ORDER_NUMBER = SERVER + "app/gen-random-order-num";
export const GENERATE_TASK_FROM_ORDER = SERVER + "app/gen-task-from-order/";
export const ORDER_ITEM_UPDATE = SERVER + "app/order-items-with-promotion/";
export const PROMOTIONS_FOR_PRODUCT = SERVER + "app/promotions-for-product/";
export const CREATE_PRE_ORDER_PROMOTIONS = SERVER + "app/create-or-update-pre-order-applied-promotion-from-order-applied-promotion";
export const PRE_ORDER_APPLIED_PROMOTIONS = SERVER + "app/pre-order-applied-promotions";
export const IS_PROMOTION_APPLICABLE = SERVER + "app/is-promotion-applicable";
export const DEDUCT_APPLIED_PROMOTION = SERVER + "app/deduct-applied-promotion";
export const GET_FREE_PROMOTION_PRODUCT = SERVER + "app/get-promotion-free-products";
export const GENERATE_MULTIPLE_TASK_FROM_ORDER =
  SERVER + "app/gen-multi-task-from-orders";
export const CREATE_ATTENDANCE = SERVER + "app/attendance-list";
export const EDIT_ATTENDANCE = SERVER + "app/attendance/";
export const ATTENDANCE_USER = SERVER + "app/org_users";
export const CURRENT_USER_ROASTERS = SERVER + "app/current_user_roasters";
// export const ATTENDANCE_DETAILED_REPORT = SERVER + "reports/attendance_excel";
export const ATTENDANCE_DETAILED_REPORT = SERVER + "reports/async_attendance_excel";
export const ATTENDANCE_REPORT_WITH_CUSTOM_FIELDS =
  SERVER + "reports/attendance_custom_field_excel";
export const SIMPLE_ATTENDANCE = SERVER + "app/simple-attendance-list";
export const SIMPLE_ATTENDANCE_REPORT = SERVER + "app/simple_attendance_excel";
export const SIMPLE_ATTENDANCE_REPORT_SINGLE =
  SERVER + "app/simple_attendance_single_excel";
// export const ATTENDANCE_DETAILED_REPORT = SERVER + "app/attendance_test";
export const ATTENDANCE_SINGLE_REPORT = SERVER + "app/attendance_single_excel";
export const TRANSPORT_BILL = SERVER + "app/task-bills/";
export const TRANSPORT_TYPE = SERVER + "app/type-choices";
export const GENERATE_BILL_FROM_REQUISITION =
  SERVER + "app/gen-bill-from-requisition";
export const YOUTUBE_LINK_TYPES = SERVER + "app/youtube-link-types";
export const CREATE_YOUTUBE_LINK = SERVER + "app/youtube-link-list";
export const EDIT_YOUTUBE_LINK = SERVER + "app/youtube-link/";
export const ROUTE_BILLS = SERVER + "app/route-bills/";
export const CREATE_CONTAINER = SERVER + "app/container-list";
export const EDIT_CONTAINER = SERVER + "app/container/";
export const CREATE_PARCEL = SERVER + "app/parcel-list";
export const EDIT_PARCEL = SERVER + "app/parcel/";
export const CONTAINER_FOR_ORDER =
  SERVER + "app/container/gen-containers-suggestion-from-sel-orders";
export const CONTAINER_FOR_PARCEL =
  SERVER + "app/container/gen-containers-suggestion-from-sel-parcels";
export const PARCEL_FROM_ORDER =
  SERVER + "app/container/gen-parcel-from-sel-orders";
export const BOOK_CONTAINER = SERVER + "app/container/book-for-orders";
export const LOAD_PARCEL = SERVER + "app/container/load-parcel";
export const UNLOAD_PARCEL = SERVER + "app/container/unload-parcel";
export const CHECK_PARCEL = SERVER + "app/container/is-parcel-there";
export const TRACKING_STATUS_CHOICES =
  SERVER + "app/tracking-status-type-choices";
export const TRACKING_PERMISSION = SERVER + "app/tracking-settings";
export const TRACKING_PERMISSION_EDIT = SERVER + "app/tracking-settings/";
export const USER_ROUTE = SERVER + "app/user-loc-route";
export const ASSET_PROVIDER = SERVER + "app/connect-asset-provider-api";
export const ROUTE_SUGGESTION =
  SERVER + "app/gen-route-suggestion-from-sel-orders";
export const NEARBY_USERS = SERVER + "app/get-nearby-org-user";
export const NEARBY_ASSETS = SERVER + "app/get-nearby-org-asset";
export const CHAIN_TASK_FROM_ORDER = SERVER + "app/gen-chain-task-from-orders";
export const FINDER_FUEL_HISTORY = SERVER + "app/finder-asset-fuel-history";
export const BILL_TYPE_CHOICES = SERVER + "app/miscellaneous-bill-type-choices";
export const CONNECT_FINDERASSET_ORGASSET =
  SERVER + "app/connect-provider-asset-to-org-asset";
export const BROTHERS_ASSET_REPORT = SERVER + "app/brother-asset-report-api";
export const FIRST_CHAIN_TASK = SERVER + "app/chained-first-tasks";
export const CREATE_VISIT_PLAN = SERVER + "app/visit-plan-list";
export const EDIT_VISIT_PLAN = SERVER + "app/visit-plan/";
export const PLAN_ROUTE_SUGGESTION =
  SERVER + "app/gen-route-suggestion-from-sel-visiting-plan-location";
export const PLAN_TO_CHAIN_TASK =
  SERVER + "app/gen-chain-task-from-visiting-plan";
export const CREATE_RATE = SERVER + "app/rate-list";
export const EDIT_RATE = SERVER + "app/rate/";
export const CREATE_QRCODE = SERVER + "app/qrcode-list";
export const CREATE_TERRITORY = SERVER + "app/territory-list";
export const EDIT_TERRITORY = SERVER + "app/territory/";
export const CREATE_ROASTER_PLAN = SERVER + "app/roaster-plan-list";
export const EDIT_ROASTER_PLAN = SERVER + "app/roaster-plan/";
export const CREATE_ROASTER = SERVER + "app/roaster-list";
export const EDIT_ROASTER = SERVER + "app/roaster/";
export const COPY_ROASTER = SERVER + "app/copy-update-roaster";
export const CREATE_CUSTOM_FIELD = SERVER + "app/create-custom-fields";
export const CUSTOM_FIELD_TYPE = SERVER + "app/custom-field-type-choices";
export const CUSTOM_FIELD_FOR = SERVER + "app/custom-field-for-choices";
export const LOCATION_SEARCH = SERVER + "app/search_loc_with_loc_name";
// export const LOCATION_SEARCH_GOOGLE = SERVER + "app/search_loc_with_loc_name_google_map";
export const LOCATION_SEARCH_GOOGLE =
  SERVER + "app/search-loc-with-place-textsearch-google-map";
export const REVERSE_LOCATION_SEARCH_GOOGLE =
  SERVER + "app/search_loc_name_with_lat_lng_google_map";
export const CREATE_QUICK_TASK = SERVER + "app/quick-task";
export const SAVE_FREQUENT_LOCATION = SERVER + "app/add-entity-for-quick-task";
export const ADD_CUSTOM_FIELDS_TO_TASK =
  SERVER + "app/add-custom-fields-to-collection/";
export const MULTI_IMAGE_UPLOAD = SERVER + "app/web-multi-image-upload/";
export const CREATE_LEAVE = SERVER + "app/attendance-leave-list";
export const EDIT_LEAVE = SERVER + "app/attendance-leave/";
export const LEAVE_TYPE_CHOICES = SERVER + "app/leave-type-choices";
export const LEAVE_APPROVAL = SERVER + "app/attendance-leave-approval/";
export const INDIVIDUAL_LEAVE_REPORT = SERVER + "app/individual-leave-report";
export const ORGANIZATION_LEAVE_REPORT =
  SERVER + "app/organization-leave-report";
export const WEB_FILE_UPLOAD = SERVER + "app/web-file-upload/";
export const TEST_CUSTOM_FIELD_REPORT =
  SERVER + "app/test-custom-fields-report";
export const ORDER_ITEM_DETAILS = SERVER + "app/order/";
export const MANDATORY_UPDATE = SERVER + "app/mandatory-update";
export const ORDER_TAG_PRINT = SERVER + "app/order-tag";
export const ORDER_INVOICE_PRINT = SERVER + "app/order-invoice-tag";
export const ORDER_INVOICE_PDF = SERVER + "app/order-invoice-pdf";
export const ORDER_EXTENDED_REPORT = SERVER + "reports/extended_order_summary";
export const ORDER_SUMMARY_REPORT = SERVER + "reports/order_summary";
export const ORDER_EXTENDED_PRODUCT_SUMMARY = SERVER + "reports/extended_product_summary_pdf";
export const ORDER_DETAILED_INVOICE = SERVER + "app/detail_order_invoice_pdf";
export const ENTITY_TAG_PRINT = SERVER + "app/entity-tag";
export const ASSET_TAG_PRINT = SERVER + "app/asset-tag";
export const CONTAINER_TAG_PRINT = SERVER + "app/container-tag";
export const PARCEL_TAG_PRINT = SERVER + "app/parcel-tag";
export const BILL_APPROVAL = SERVER + "app/bill-approval/";
export const CREATE_INCIDENT = SERVER + "app/incident-list";
export const INCIDENT_TYPE_CHOICES = SERVER + "app/incident-type-choices";
export const EDIT_INCIDENT = SERVER + "app/incident/";
export const CREATE_REMINDER = SERVER + "app/reminder-list";
export const REMINDER_TYPE_CHOICES = SERVER + "app/reminder-type-choices";
export const BILL_ID_GENERATOR = SERVER + "app/gen-random-number";
export const APPROVAL_SYSTEM_CHOICES =
  SERVER + "app/approval-system-status-choices";
export const NAAFCO_TASK_REPORT_WITH_CUSTOM_FIELDS =
  SERVER + "app/task_custom_field_naafco_excel";
// export const NAAFCO_TASK_CRM_REPORT_WITH_CUSTOM_FIELDS_NEW = SERVER + "app/task_crm_report_naafco_excel";
export const NAAFCO_TASK_CRM_REPORT_WITH_CUSTOM_FIELDS_NEW =
  SERVER + "app/reports_from_conveyance_insights";
export const MOBILE_HEARTEBEAT_LIST = SERVER + "app/mobile-heartbeat-log-list";
export const CREATE_ROLE = SERVER + "app/role-list";
export const EDIT_ROLE = SERVER + "app/role/";
export const PERMISSION_CHOICES = SERVER + "app/permission-choices";
export const USER_ROLE_ADD_OR_CHANGE = SERVER + "app/user-role-add-or-change/";
export const ORG_PLAN_SERVICE_CHECK = SERVER + "app/org-plan-pack-services/";
export const CREATE_SUB_ORG_TYPE = SERVER + "app/sub-org-type-list";
export const EDIT_SUB_ORG_TYPE = SERVER + "app/sub-org-type/";
export const CREATE_SUB_ORG = SERVER + "app/sub-org-list";
export const EDIT_SUB_ORG = SERVER + "app/sub-org/";
export const SUB_ORG_SORT_TYPE_CHOICES = SERVER + "app/sub-org-sort-by-type-choices";
export const CREATE_SALES_TARGET = SERVER + "app/sale-targets-list";
export const EDIT_SALES_TARGET = SERVER + "app/sale-targets/";
export const SUB_ORG_PARENT_LIST = SERVER + "app/sub-org-parent-list";
export const SUB_ORGS_WITH_USER_ROLE_PERMISSION = SERVER + "app/get-sub-orgs-with-user-role-permission";
export const GET_ASSIGNED_USER_SUB_ORG_LIST =
  SERVER + "app/get-sub-orgs-with-assigned-user";
export const CURRENT_USER_SUB_ORG_LIST =
  SERVER + "app/get-sub-orgs-with-current-user";
export const GET_FIRST_CHILD_SUB_ORG_LIST = SERVER + "app/get-sub-org-descendants";
export const CONNECT_ASSET_FINDER =
  "http://dev.finder-lbs.com:8000/services/connect_asset";
export const SAMPLE_USER_UPLOAD_FILE =
  SERVER + "example_files/bulk_users/users.xlsx";
export const CREATE_INVENTORY = SERVER + "app/inventory-list";
export const INVENTORY_STOCK_HISTORY_REPORT = SERVER + "reports/inventory-history-report/";
export const INVENTORY_MULTI_SHEET_REPORT = SERVER + "reports/inventory-multi-sheet-reports";
export const EDIT_INVENTORY = SERVER + "app/inventory/";
export const INVENTORY_SETTING = SERVER + "app/inventory-setting/";
export const INVENTORY_LAST_CLOSING_VALUE_UPDATE =
  SERVER + "app/inventory-product-last-closing-and-closing-history/";
export const INVENTORY_PRODUCT_STOCK_HISTORY_LIST =
  SERVER + "app/inventory-product-stock-history-list/";

export const ORGANIZATION_SETTINGS_LIST = SERVER + "app/org-setting-list";
export const GET_ORDER_SETTINGS = SERVER + "app/order-setting/";
export const GET_SMS_SETTINGS = SERVER + "app/sms-setting/";
export const GET_EMAIL_SETTINGS = SERVER + "app/email-setting/";
export const ORDER_STATUS_SETTINGS = SERVER + "app/order-status-setting/";
export const ORDER_INVENTORY_DEDUCTION_STATUS_SETTINGS =
  SERVER + "app/order-inventory-deduction-status-setting/";
export const GET_ACTIVE_ORDER_STATUS = SERVER + "app/get-active-order-status/";
export const ORDER_APPROVAL_STATUS_SETTINGS =
  SERVER + "app/order-approval-status-setting/";
export const CREATE_TARGET = SERVER + "app/create-targets";
export const TARGET_LIST = SERVER + "app/targets";
export const TARGET_TYPE_CHOICES = SERVER + "app/targets-type-choices";
export const TARGET_FOR_CHOICES = SERVER + "app/targets-for-choices";
export const TARGET_QUERY_CHOICES = SERVER + "app/query-label";
export const EDIT_ACHIEVEMENT = SERVER + "app/achievements/";
export const ACHIEVEMENT_WITH_QUERY = SERVER + "app/achievement-with-query";
export const ACHIEVEMENT_LIST = SERVER + "app/achievement";
export const APPROVE_ACHIEVEMENT = SERVER + "app/approve-achievement/";
export const ACHIEVEMENT_DETAILS = SERVER + "app/achievement-detail/";
export const GENERATE_ACHIEVEMENT = SERVER + "app/generate-achievement";
export const ORDER_INSIGHTS = SERVER + "app/order_insights";
export const ORDER_EXTENDED_SUMMARY_PDF = SERVER + "reports/extended_order_summary_pdf";
export const ORDER_INVOICE_POS = SERVER + "app/order-invoice-pos";
export const ORDER_EXTENDED_SUMMARY_EXCEL = SERVER + "reports/extended_product_summary";
export const ORDER_EXTENDED_MULTISHEET_EXCEL = SERVER + "reports/extended_multi_sheet_product_summary";
export const ORDER_AVAILABLE_STOCK_OR_STOCK_QUANTITY_OPTIONS = SERVER + "app/select-available-stock-or-stock-quantity";
export const ORDER_PRODUCT_STOCK_SETTINGS = SERVER + "app/order-product-stock-setting/";
export const GET_ORDER_PRODUCT_STOCK_SETTINGS = SERVER + "app/get-order-product-stock-setting";
export const ORGANIZATION_PRODUCT_BULK_UPLOAD_SAMPLE = SERVER + "example_files/bulk_products/upload_parent_product.csv";
export const INVENTORY_PRODUCT_BULK_UPLOAD_SAMPLE = SERVER + "app/product-for-inventory-download-csv-format";
export const PARENT_PRODUCT_BULK_UPLOAD = SERVER + "app/parent-products-bulk-upload";
export const INVENTORY_PRODUCT_BULK_UPLOAD = SERVER + "app/inventory-products-bulk-upload";
export const INTERNAL_INVENTORY_TRANSFER = SERVER + "app/internal-transfer-inventroy-to-inventory";
export const ACHIEVEMENT_COLLECTION_NAME_CHOICES = SERVER + "app/achievement-collection-name-choices";
export const ACHIEVEMENT_FIELD_NAME_CHOICES = SERVER + "app/field-name-choices";
export const ACHIEVEMENT_OPERATOR_CHOICES = SERVER + "app/achievement-operator-choices";
export const RETURN_INVENTORY_PRODUCT_REASON_CHOICES = SERVER + "app/return-inventory-product-reason-choices";
export const RETURN_PRODUCT_TO_INVENTORY = SERVER + "app/return-stock-to-inventory-product-stock/";
export const INVENTORY_PRODUCT_CLOSING_TIME_LIST = SERVER + "app/inventory-product-last-closing-history-times/";
export const INVENTORY_PRODUCT_CLOSING_LIST = SERVER + "app/inventory-product-last-closing-datewise-history/";
export const EXTEND_SUBSCRIPTION = SERVER + "app/super/extend-subscription-this-month/";
export const CHANGE_ORG_PLAN = SERVER + "app/super/change-org-plan/";
export const ADD_ORG_PLAN = SERVER + "app/super/add-org-plan/";
export const INVENTORY_CLOSING_HISTORY_REPORT = SERVER + "app/inventory-product-last-closing-history-excel/";
export const CREATE_WALLET = SERVER + "app/wallets";
export const ADD_BALANCE_TO_WALLET = SERVER + "app/add-money-to-wallet/";
export const GET_ORDERS_BY_ENTITY = SERVER + "app/get-orders-by-entity/";
export const ADJUST_WALLET_WITH_ORDER = SERVER + "app/adjust-wallet-with-order/";
export const AUTO_CLOSING_TYPE_CHOICES = SERVER + "app/auto-closing-type-choices";
export const WALLET_PAYMENT_METHOD_CHOICES = SERVER + "app/wallet-payment-method-choices";
export const REFUND_MONEY_TO_WALLET = SERVER + "app/refund-money-to-wallet/";
export const ADJUST_WALLET_WITH_BALANCE = SERVER + "app/adjust-dues-with-wallet-balance/";
export const WALLET_HISTORY = SERVER + "app/wallet-history/";
export const WALLET_HISTORY_COMBINED_REPORT = SERVER + "app/wallet-history-report/";
export const WALLET_DUE_COLLECTION_LIST = SERVER + "app/due-collection-list";
export const SUB_ORG_WISE_DUE_COLLECTION_REPORT = SERVER + "app/sub-org-wise-due-collection/";
export const PLACE_LIST_FOR_PURCHASE_ORDER = SERVER + "app/place-list-for-purchase-order";
export const PLACE_DETAILS_FOR_PURCHASE_ORDER = SERVER + "app/place-details-for-purchase-order/";
export const PURCHASE_ORDER_LIST = SERVER + "app/purchase-order-list";
export const INVENTORY_BY_PLACE = SERVER + "app/get-inventories-by-place/";
export const CATEGORIES_BY_ORG = SERVER + "app/categories-by-org/";
export const ORDER_TYPE_CHOICES = SERVER + "app/order-type-choices";
export const ORDER_FINANCE_REPORT = SERVER + "reports/order-finance-excel";
export const SMS_SERVICE_PROVIDER_CHOICES = SERVER + "app/sms-provider-choices";
export const LOW_STOCK_REPORT = SERVER + "app/generate-report-of-low-stock-products";
export const CUSTOM_FORM_TYPE_CHOICES = SERVER + "app/form_type_choices";
export const CUSTOM_FORM_STATUS_CHOICES = SERVER + "app/form_status_choices";
export const CREATE_CUSTOM_FORM = SERVER + "app/form_settings";
export const EDIT_CUSTOM_FORM = SERVER + "app/form_settings/";
export const SUBMIT_CUSTOM_FORM = SERVER + "app/form_submission_handler";
export const EDIT_CUSTOM_FORM_SUBMITTED_DATA = SERVER + "app/form_submission_handler/";
export const PROMOTION_DISCOUNT_TYPE_CHOICES = SERVER + "app/discount-type-choices";
export const PROMOTION_TYPE_CHOICES = SERVER + "app/promotion-type-choices";
export const CREATE_PROMOTION = SERVER + "app/promotion-list";
export const EDIT_PROMOTION = SERVER + "app/promotion/";
export const CREATE_BILL_SETTINGS = SERVER + "app/bill-level";
export const EDIT_BILL_SETTINGS = SERVER + "app/bill-level/";
export const BILL_DETAILS = SERVER + "app/bill-level-detail/";
export const BILL_FOR_LIST = SERVER + "app/bill-entity-list";
export const TASK_PRESCRIPTION_SEARCH_LIST = SERVER + "app/task-prescription-search-list";
export const INTEGRATE_WITH_QUICKBOOKS = SERVER + "app/bill/integrate-with-qb";
export const QUICKBOOKS_EXPENSE_ACCOUNTS = SERVER + "app/bill/qb-expense-accounts";
export const SYNC_QUICKBOOKS_ITEM = SERVER + "app/bill/sync-qb-item/";
export const SYNC_QUICKBOOKS_BILL = SERVER + "app/bill/sync-bill-with-qb/";
export const CREATE_HANDOVER_REQUEST = SERVER + "app/handover-list";
export const APPROVE_HANDOVER = SERVER + "app/handover/";
export const AUTO_UPDATE_STATUS_NO_DISCOUNT_SETTINGS = SERVER + "app/auto-update-status-for-no-discount-setting/";
export const CREATE_RETURN_ORDER = SERVER + "app/return-order-list";
export const EDIT_RETURN_ORDER = SERVER + "app/return-order-status-update/";
export const RETURN_ORDER_STATUS_CHOICES = SERVER + "app/return-order-status-choices";
export const RETURN_ORDER_STATUS_UPDATE = SERVER + "app/return-order-status-update/";
export const ADD_STOCK_TO_INVENTORY_FROM_RETURN_ORDER = SERVER + "app/add-stock-to-inventroy-product-stock-from-return-order-item";
export const MULTIPLE_ORDER_DETAILS_WITH_PARENT_PRODUCT = SERVER + "app/update-view-multiple-order-with-parent-product/";
export const MULTIPLE_ORDER_DETAILS_WITH_CHILD_PRODUCT = SERVER + "app/update-view-multiple-order-with-item/";
export const GET_ORDER_OTHER_DISCOUNT_CATEGORY = SERVER + "/app/get-and-apply-eligible-all-order-val-all-order-count-order-total-promotions-for-order";


export const ANALYTICS_API ="https://service.conveyance.app/fastapi/";

export const GEO_CODING_API = text =>
  `https://nominatim.openstreetmap.org/search?q=${text}&format=geojson&addressdetails=[1]`;
export const REVERSE_GEO = (lat = "", lon = "") =>
  `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${lat}&lon=${lon}`;
export const REVERSE_GEO_COUNTRY = (lat = "", lon = "") =>
  `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`;
