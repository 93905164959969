import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tabs,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_ASSET,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
  CURRENT_USER_SUB_ORG_LIST,
  ORG_IMAGE_UPLOAD,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

export default class EditAsset extends Component {
  state = {
    name: "",
    asset: "",
    registration_number: "",
    purchase_unit: "",
    vehicle_owners_name: "",
    vehicle_sellers_name: "",
    chasis_number: "",
    engine_number: "",
    cc: "",
    model: "",
    brand_name: "",
    body_color: "",
    fuel_used: "",
    fuel_expense_unit: "",
    maint_expense_unit: "",
    vehicle_purchase_amount: "",
    current_mileage: "",
    remarks: "",
    sub_org: "",
    maint_category: "yearly",
    maint_renewal_date: "",
    maint_renewal_date_view: moment(),
    mileage_settings: 0,

    tax_token_file: "",
    tax_token_file_thumb: "",
    tax_token_expiry_date: Date.now(),
    tax_token_expiry_date_view: moment(),
    tax_token_renewal_reminder_date: Date.now(),
    tax_token_renewal_reminder_date_view: moment(),

    fitness_document_file: "",
    fitness_document_file_thumb: "",
    fitness_expiry_date: Date.now(),
    fitness_expiry_date_view: moment(),
    fitness_document_renewal_reminder_date: Date.now(),
    fitness_document_renewal_reminder_date_view: moment(),

    route_permit_document_file: "",
    route_permit_document_file_thumb: "",
    route_permit_document_expiry_date: Date.now(),
    route_permit_document_expiry_date_view: moment(),
    route_permit_document_renewal_reminder_date: Date.now(),
    route_permit_document_renewal_reminder_date_view: moment(),

    insurance_document_file: "",
    insurance_document_file_thumb: "",
    insurance_document_expiry_date: Date.now(),
    insurance_document_expiry_date_view: moment(),
    insurance_document_renewal_reminder_date: Date.now(),
    insurance_document_renewal_reminder_date_view: moment(),

    purchase_date: Date.now(),
    purchase_date_view: moment(),
    registration_date: Date.now(),
    registration_date_view: moment(),
    user_id: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    connect_key: [],
    asset_id: "",
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    sub_orgs: [],
    activeTab: "1",
    imgUrl: "",
    imgFile: "",
  };

  componentDidMount() {
    this.countryList();
    this.getUserSubOrg();
    this.setState({ asset_id: this.props.location.state.asset_id });

    GET_ACCESS_TOKEN().then((token) => {
      let asset_id = this.props.location.state.asset_id;
      let url = `${EDIT_ASSET}${asset_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit asset", response.data.data);
        this.setState({
          name: response.data.data.name,
          asset: response.data.data.asset,
          user_id: response.data.data.user_id,
          address: response.data.data.address,
          city: response.data.data.city,
          country: response.data.data.country,
          registration_number: response.data.data.registration_number ?? "",
          purchase_unit: response.data.data.purchase_unit ?? "",
          vehicle_owners_name: response.data.data.vehicle_owners_name ?? "",
          vehicle_sellers_name: response.data.data.vehicle_sellers_name ?? "",
          chasis_number: response.data.data.chasis_number ?? "",
          engine_number: response.data.data.engine_number ?? "",
          cc: response.data.data.cc ?? "",
          model: response.data.data.model ?? "",
          brand_name: response.data.data.brand_name ?? "",
          body_color: response.data.data.body_color ?? "",
          fuel_used: response.data.data.fuel_used ?? "",
          fuel_expense_unit: response.data.data.fuel_expense_unit ?? "",
          maint_expense_unit: response.data.data.maint_expense_unit ?? "",
          vehicle_purchase_amount:
            response.data.data.vehicle_purchase_amount ?? "",
          current_mileage: response.data.data.current_mileage ?? "",
          remarks: response.data.data.remarks ?? "",
          sub_org: response.data.data.sub_org.id ?? "",
          maint_category: response.data.data.maint_category??"yearly",
          maint_renewal_date: response?.data?.data?.maint_renewal_date?.$date??"",
          maint_renewal_date_view:response?.data?.data?.maint_renewal_date?.$date?? moment(),
          mileage_settings: response.data.data.mileage_settings??0,

          tax_token_file: response.data.data.tax_token_file ?? "",
          tax_token_file_thumb: response.data.data.tax_token_file_thumb ?? "",
          tax_token_expiry_date:
            response?.data?.data?.tax_token_expiry_date?.$date ?? "",
          tax_token_expiry_date_view:
            response?.data?.data?.tax_token_expiry_date?.$date ?? moment(),
          tax_token_renewal_reminder_date:
            response?.data?.data?.tax_token_renewal_reminder_date?.$date ?? "",
          tax_token_renewal_reminder_date_view:
            response?.data?.data?.tax_token_renewal_reminder_date?.$date ??
            moment(),

          fitness_document_file: response.data.data.fitness_document_file ?? "",
          fitness_document_file_thumb:
            response.data.data.fitness_document_file_thumb ?? "",
          fitness_expiry_date:
            response?.data?.data?.fitness_expiry_date?.$date ?? "",
          fitness_expiry_date_view:
            response?.data?.data?.fitness_expiry_date?.$date ?? moment(),
          fitness_document_renewal_reminder_date:
            response?.data?.data?.fitness_document_renewal_reminder_date
              ?.$date ?? "",
          fitness_document_renewal_reminder_date_view:
            response?.data?.data?.fitness_document_renewal_reminder_date
              ?.$date ?? moment(),

          route_permit_document_file:
            response.data.data.route_permit_document_file ?? "",
          route_permit_document_file_thumb:
            response.data.data.route_permit_document_file_thumb ?? "",
          route_permit_document_expiry_date:
            response?.data?.data?.route_permit_document_expiry_date?.$date ??
            "",
          route_permit_document_expiry_date_view:
            response?.data?.data?.route_permit_document_expiry_date?.$date ??
            moment(),
          route_permit_document_renewal_reminder_date:
            response?.data?.data?.route_permit_document_renewal_reminder_date
              ?.$date ?? "",
          route_permit_document_renewal_reminder_date_view:
            response?.data?.data?.route_permit_document_renewal_reminder_date
              ?.$date ?? moment(),

          insurance_document_file:
            response.data.data.insurance_document_file ?? "",
          insurance_document_file_thumb:
            response.data.data.insurance_document_file_thumb ?? "",
          insurance_document_expiry_date:
            response?.data?.data?.insurance_document_expiry_date?.$date ?? "",
          insurance_document_expiry_date_view:
            response?.data?.data?.insurance_document_expiry_date?.$date ??
            moment(),
          insurance_document_renewal_reminder_date:
            response?.data?.data?.insurance_document_renewal_reminder_date
              ?.$date ?? "",
          insurance_document_renewal_reminder_date_view:
            response?.data?.data?.insurance_document_renewal_reminder_date
              ?.$date ?? moment(),

          purchase_date: response?.data?.data?.purchase_date?.$date ?? "",
          purchase_date_view:
            response?.data?.data?.purchase_date?.$date ?? moment(),
          registration_date:
            response?.data?.data?.registration_date?.$date ?? "",
          registration_date_view:
            response?.data?.data?.registration_date?.$date ?? moment(),
        });
      });
    });
  }

  getUserSubOrg = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?assigned_user_id=${
        GET_USER_LOCAL()._id.$oid
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            sub_orgs: response.data.data,
          });

          console.log("response from current user sub org list", response);
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch((error) => {
          console.log("error from country list api", error);
        });
    });
  };

  onChange = (activeTab) => {
    console.log(`onChange ${activeTab}`);
    this.setState({
      activeTab,
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setAsset = (evt) => {
    evt.preventDefault();
    this.setState({ asset: evt.target.value }, () => {
      console.log(this.state.asset);
    });
  };
  setAddress = (evt) => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };
  setCity = (value) => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = (value) => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;

        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities,
          });
        });
      });
    });
  };

  uploadTaxImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              tax_token_file: `${response.data.data.upload_path}`,
              tax_token_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadFitnessImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              fitness_document_file: `${response.data.data.upload_path}`,
              fitness_document_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadRoutePermitImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              route_permit_document_file: `${response.data.data.upload_path}`,
              route_permit_document_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadInsuranceImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              insurance_document_file: `${response.data.data.upload_path}`,
              insurance_document_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  editAsset = () => {
    this.setState({ loading: true });
    const data = {
      name: this.state.name,
      asset: this.state.asset,
      user_id: this.state.user_id,
      loc: this.state.loc,
      address: this.state.address,
      city: this.state.city,
      country: this.state.country,
      connect_key: this.state.connect_key,
      registration_number: this.state.registration_number,
      purchase_unit: this.state.purchase_unit,
      vehicle_owners_name: this.state.vehicle_owners_name,
      vehicle_sellers_name: this.state.vehicle_sellers_name,
      chasis_number: this.state.chasis_number,
      engine_number: this.state.engine_number,
      cc: this.state.cc,
      model: this.state.model,
      brand_name: this.state.brand_name,
      body_color: this.state.body_color,
      fuel_used: this.state.fuel_used,
      fuel_expense_unit: this.state.fuel_expense_unit,
      maint_expense_unit: this.state.maint_expense_unit,
      vehicle_purchase_amount: this.state.vehicle_purchase_amount,
      current_mileage: Number(this.state.current_mileage),
      remarks: this.state.remarks,
      sub_org: this.state.sub_org,
      maint_category: this.state.maint_category,
      maint_renewal_date: this.state.maint_renewal_date,
      mileage_settings: Number(this.state.mileage_settings),

      tax_token_file: this.state.tax_token_file,
      tax_token_file_thumb: this.state.tax_token_file_thumb,
      tax_token_expiry_date: this.state.tax_token_expiry_date,
      tax_token_renewal_reminder_date:
        this.state.tax_token_renewal_reminder_date,

      fitness_document_file: this.state.fitness_document_file,
      fitness_document_file_thumb: this.state.fitness_document_file_thumb,
      fitness_expiry_date: this.state.fitness_expiry_date,
      fitness_document_renewal_reminder_date:
        this.state.fitness_document_renewal_reminder_date,

      route_permit_document_file: this.state.route_permit_document_file,
      route_permit_document_file_thumb:
        this.state.route_permit_document_file_thumb,
      route_permit_document_expiry_date:
        this.state.route_permit_document_expiry_date,
      route_permit_document_renewal_reminder_date:
        this.state.route_permit_document_renewal_reminder_date,

      insurance_document_file: this.state.insurance_document_file,
      insurance_document_file_thumb: this.state.insurance_document_file_thumb,
      insurance_document_expiry_date: this.state.insurance_document_expiry_date,
      insurance_document_renewal_reminder_date:
        this.state.insurance_document_renewal_reminder_date,

      purchase_date: this.state.purchase_date,
      registration_date: this.state.registration_date,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_ASSET}${this.state.asset_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("success", response.data.message, "success");
          console.log("response from edit asset", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("error", error, "error");
          console.log("error from edit asset", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => this.props.history.goBack()}
          title="Edit Asset"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Asset (IMEI or Device Number)</Text>
            <Input
              placeholder="Asset"
              type="text"
              value={this.state.asset}
              onChange={this.setAsset}
            />
          </Form.Item>

          <Form.Item>
            <Text>Registration Number</Text>
            <Input
              placeholder="Registration Number"
              type="text"
              value={this.state.registration_number}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ registration_number: evt.target.value }, () => {
                  console.log(this.state.registration_number);
                });
              }}
            />
          </Form.Item>

          <Form.Item>
            <Text>Address</Text>

            <TextArea
              rows={4}
              placeholder="Address"
              type="text"
              value={this.state.address}
              onChange={this.setAddress}
            />
          </Form.Item>
          <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>City</Text>

            <Select
              labelInValue
              disabled={this.state.country === "" ? true : false}
              value={{ key: this.state.city_id, label: this.state.city }}
              onChange={this.setCity}
              placeholder="Select City"
            >
              {this.state.cities.map((r) => {
                return <Option key={r.code}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Purchase Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.purchase_date_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        purchase_date: moment(value).format("x"),
                        purchase_date_view: value,
                      },
                      () => {
                        console.log(
                          "Selected Time: ",
                          this.state.purchase_date
                        );
                        console.log(
                          "Selected value: ",
                          this.state.purchase_date_view
                        );
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Registration Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.registration_date_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        registration_date: moment(value).format("x"),
                        registration_date_view: value,
                      },
                      () => {
                        console.log(
                          "Selected Time: ",
                          this.state.registration_date
                        );
                        console.log(
                          "Selected value: ",
                          this.state.registration_date_view
                        );
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <Text>Purchase Unit</Text>
            <Input
              placeholder="Purchase Unit"
              type="text"
              value={this.state.purchase_unit}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ purchase_unit: evt.target.value }, () => {
                  console.log(this.state.purchase_unit);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Vehicle Owners Name</Text>
            <Input
              placeholder="Vehicle Owners Name"
              type="text"
              value={this.state.vehicle_owners_name}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ vehicle_owners_name: evt.target.value }, () => {
                  console.log(this.state.vehicle_owners_name);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Vehicle Sellers Name</Text>
            <Input
              placeholder="Vehicle Sellers Name"
              type="text"
              value={this.state.vehicle_sellers_name}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState(
                  { vehicle_sellers_name: evt.target.value },
                  () => {
                    console.log(this.state.vehicle_sellers_name);
                  }
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Chasis Number</Text>
            <Input
              placeholder="Chasis Number"
              type="text"
              value={this.state.chasis_number}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ chasis_number: evt.target.value }, () => {
                  console.log(this.state.chasis_number);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Engine Number</Text>
            <Input
              placeholder="Engine Number"
              type="text"
              value={this.state.engine_number}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ engine_number: evt.target.value }, () => {
                  console.log(this.state.engine_number);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>CC</Text>
            <Input
              placeholder="CC"
              type="text"
              value={this.state.cc}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ cc: evt.target.value }, () => {
                  console.log(this.state.cc);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Model</Text>
            <Input
              placeholder="Model"
              type="text"
              value={this.state.model}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ model: evt.target.value }, () => {
                  console.log(this.state.model);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Brand Name</Text>
            <Input
              placeholder="Brand Name"
              type="text"
              value={this.state.brand_name}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ brand_name: evt.target.value }, () => {
                  console.log(this.state.brand_name);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Body Color</Text>
            <Input
              placeholder="Body Color"
              type="text"
              value={this.state.body_color}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ body_color: evt.target.value }, () => {
                  console.log(this.state.body_color);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Fuel Used</Text>
            <Input
              placeholder="Fuel Used"
              type="text"
              value={this.state.fuel_used}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ fuel_used: evt.target.value }, () => {
                  console.log(this.state.fuel_used);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Fuel Expense Unit</Text>
            <Input
              placeholder="Fuel Expense Unit"
              type="text"
              value={this.state.fuel_expense_unit}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ fuel_expense_unit: evt.target.value }, () => {
                  console.log(this.state.fuel_expense_unit);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Maintainance Expense Unit</Text>
            <Input
              placeholder="Maintainance Expense Unit"
              type="text"
              value={this.state.maint_expense_unit}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ maint_expense_unit: evt.target.value }, () => {
                  console.log(this.state.maint_expense_unit);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Vehicle Purchase Amount</Text>
            <Input
              placeholder="Vehicle Purchase Amount"
              type="text"
              value={this.state.vehicle_purchase_amount}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState(
                  { vehicle_purchase_amount: evt.target.value },
                  () => {
                    console.log(this.state.vehicle_purchase_amount);
                  }
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Current Milege</Text>
            <Input
              placeholder="Current Milege"
              type="number"
              value={this.state.current_mileage}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ current_mileage: evt.target.value }, () => {
                  console.log(this.state.current_mileage);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Maintainance Category</Text>

            <Select
              value={this.state.maint_category}
              onChange={(value) => this.setState({ maint_category: value })}
            >
              <Option key="monthly">Monthly</Option>
              <Option key="quarterly ">Quarterly</Option>
              <Option key="half yearly">Half yearly</Option>
              <Option key="yearly">Yearly</Option>
              <Option key="per mileage">Per mileage</Option>
            </Select>
          </Form.Item>
          {this.state.maint_category === "per mileage" ? null : (
            <Form.Item>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text>Maintainance Renewal Date</Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.maint_renewal_date_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          maint_renewal_date: moment(value).format("x"),
                          maint_renewal_date_view: value,
                        },
                        () => {
                          console.log(
                            "Selected Time: ",
                            this.state.maint_renewal_date
                          );
                          console.log(
                            "Selected value: ",
                            this.state.maint_renewal_date_view
                          );
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Item>
          )}
          <Form.Item>
            <Text>Mileage Settings</Text>
            <Input
              disabled={
                this.state.maint_category === "per mileage" ? false : true
              }
              placeholder="Mileage Settings"
              type="number"
              value={this.state.mileage_settings}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ mileage_settings: evt.target.value }, () => {
                  console.log(this.state.mileage_settings);
                });
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Remarks</Text>
            <Input
              placeholder="Remarks"
              type="text"
              value={this.state.remarks}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ remarks: evt.target.value }, () => {
                  console.log(this.state.remarks);
                });
              }}
            />
          </Form.Item>

          <Form.Item>
            <Text>Select Sub Organization</Text>
            <Select
              style={{ width: "100%" }}
              value={this.state.sub_org}
              onChange={(value) => {
                this.setState({ sub_org: value }, () => {
                  console.log("sub org value", this.state.sub_org);
                });
              }}
              placeholder="Select Sub Organization"
            >
              {this.state.sub_orgs.map((r) => {
                return <Option key={r.id}>{`${r.name}`}</Option>;
              })}
            </Select>
          </Form.Item>
          <Card style={{ margin: 5 }}>
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={this.state.activeTab}
              onChange={this.onChange}
            >
              <TabPane tab="Tax Token Information" key="1">
                <Form.Item>
                  <Text>
                    Upload Tax Token Image (Image must be under 100 KB)
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadTaxImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Tax Token Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.tax_token_expiry_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              tax_token_expiry_date: moment(value).format("x"),
                              tax_token_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.tax_token_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.tax_token_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Tax Token Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.tax_token_renewal_reminder_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              tax_token_renewal_reminder_date:
                                moment(value).format("x"),
                              tax_token_renewal_reminder_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.tax_token_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.tax_token_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>
              <TabPane tab="Fitness Documents" key="2">
                <Form.Item>
                  <Text>
                    Upload Fitness Document Image (Image must be under 100 KB)
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadFitnessImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Fitness Document Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.fitness_expiry_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              fitness_expiry_date: moment(value).format("x"),
                              fitness_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.fitness_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.fitness_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Fitness Document Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state.fitness_document_renewal_reminder_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              fitness_document_renewal_reminder_date:
                                moment(value).format("x"),
                              fitness_document_renewal_reminder_date_view:
                                value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state
                                  .fitness_document_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .fitness_document_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>

              <TabPane tab="Route Permit Documents" key="3">
                <Form.Item>
                  <Text>
                    Upload Route Permit Document Image (Image must be under 100
                    KB)
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadRoutePermitImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Route Permit Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state.route_permit_document_expiry_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              route_permit_document_expiry_date:
                                moment(value).format("x"),
                              route_permit_document_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.route_permit_document_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .route_permit_document_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Route Permit Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state
                            .route_permit_document_renewal_reminder_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              route_permit_document_renewal_reminder_date:
                                moment(value).format("x"),
                              route_permit_document_renewal_reminder_date_view:
                                value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state
                                  .route_permit_document_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .route_permit_document_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>

              <TabPane tab="Insurance Documents" key="4">
                <Form.Item>
                  <Text>
                    Upload Insurance Document Image (Image must be under 100 KB)
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadInsuranceImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Insurance Document Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.insurance_document_expiry_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              insurance_document_expiry_date:
                                moment(value).format("x"),
                              insurance_document_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.insurance_document_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.insurance_document_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Insurance Document Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state
                            .insurance_document_renewal_reminder_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              insurance_document_renewal_reminder_date:
                                moment(value).format("x"),
                              insurance_document_renewal_reminder_date_view:
                                value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state
                                  .insurance_document_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .insurance_document_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>
            </Tabs>
          </Card>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editAsset}
            >
              Update Asset
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
