import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { connect } from "react-redux";
import { saveTaskData } from "../../redux/actions/startTaskActions";
import {  Redirect } from "react-router-dom";


import { ASSET_SEARCH,CREATE_QUICK_TASK } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

const { Text } = Typography;
const { Option } = Select;

class ChooseAsset extends Component {
  state = {
    assetData: [],
    assigned_asset: "",
    assigned_asset_label: "",
    redirect:false
  };

  componentDidMount() {
    console.log("from starttask", this.props.startTask);
    this.getAssetList();
  }

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${ASSET_SEARCH}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset list", response);
        })
        .catch(error => {
          console.log("error from asset list api", error);
        });
    });
  };

  setAssignedAsset = value => {
    this.setState(
      { assigned_asset_label: value.label, assigned_asset: value.key },
      () => {
        console.log(this.state.assigned_asset_label);
      }
    );
  };

  createQuickTask = () => {
    if (this.state.assigned_asset === "") {
      swal.fire("Info", "Please Select Asset", "info");
    } else {
      let data = {
        name: this?.props?.startTask?.name ?? "",
        task_type: this?.props?.startTask?.task_type ?? "",
        task_code: this?.props?.startTask?.task_code ?? "",
        colors: this?.props?.startTask?.colors ?? [],
        start_time: this?.props?.startTask?.start_time ?? Date.now(),
        started: this.props?.startTask?.started??false,
        assigned_to: this?.props?.startTask?.assigned_to ?? "",
        assigned_to_name: this?.props?.startTask?.assigned_to_label ?? "",
        sub_org: this.props?.startTask?.sub_org_id ?? "",
        start_loc_name: this?.props?.startTask?.start_loc_name ?? "",
        start_loc: {
          coordinates: [
            this?.props?.startTask?.start_location_lon ?? 90.3563,
            this?.props?.startTask?.start_location_lat ?? 23.685
          ]
        },
        end_loc_name: this?.props?.startTask?.end_loc_name??"",
        end_loc: {
          coordinates: [this?.props?.startTask?.end_location_lon??90.3563, this?.props?.startTask?.end_location_lat??23.685]
        },
        drop_contact_no: this?.props?.startTask?.drop_contact_no??"",
        drop_contact_name:  this?.props?.startTask?.drop_contact_name??"",
        assigned_asset:this.state.assigned_asset,
        assigned_asset_name:this.state.assigned_asset_label
      };
      console.log(data)
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_QUICK_TASK}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            this.props.saveTaskData({});
            if(response.data.message==="Data successfully inserted!"){
              this.setState({redirect:true})
            }
            console.log("response from create quick task", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create quick task", error);
          });
      });
    }
    
  };

  
  render() {
    if (this.state.redirect) {
      return <Redirect to="/startTask" />;
    }
    return (
      <Card>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Are You Using Any Asset/Vehicle of Your Own ?"
          onBack={() => this.props.history.goBack()}
        />
        <Form>
          <Form.Item>
            <Text>Assign to (Asset)</Text>
            <Select
              labelInValue
              style={{ width: "100%" }}
              value={{
                key: this.state.assigned_asset,
                label: this.state.assigned_asset_label
              }}
              onChange={this.setAssignedAsset}
              placeholder="Select Asset"
            >
              {this.state.assetData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createQuickTask}
            >
              Done
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  startTask: state.startTask.startTaskData
});

export default connect(mapStateToProps, { saveTaskData })(ChooseAsset);
