import React, { Component } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Table,
  Divider,
  Input,
  Pagination,
  Icon,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  TASK_PRESCRIPTION_SEARCH_LIST,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import ReactGA from "react-ga";

// import { Spinner } from "../loader";

import "../login.css";

const { Option } = Select;
const { Text, Title } = Typography;
const { Column } = Table;

class PrescriptionSearchList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    currentTime: "",
    user_ids: [],
    timezone_name: "",
    timezone_choice: "org_timezone",
    name: "",
    details: "",
    task_code: "",
    task_link: "",
    contact_no: "",
    contact_name: "",
    drop_contact_no: "",
    drop_contact_name: "",
    start_loc_name: "",
    end_loc_name: "",
    started_loc_name: "",
    completed_loc_name: "",
    task_status: "",
    assigned_asset_name: "",
    assigned_to_name: "",
    start_time: moment().startOf("month").format("x"),
    start_time_view: moment().startOf("month"),
    end_time: Date.now(),
    end_time_view: moment(),
    accepted_on: "",
    accepted_on_view: moment(),
    started_on: "",
    started_on_view: moment(),
    completed_on: "",
    completed_on_view: moment(),
    datarange: "",
    sort_by: "created_on",
    sort_type: "dsc",
    search_date_time_type: "completed_on",
    selected_user_id: "",
    selected_user_name: "",
    from: moment().startOf("day").format("x"),
    from_view: moment().startOf("day"),
    to: Date.now(),
    to_view: moment(),
    filtered: [],
    task_type: "",
    taskTypes: [],
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_task_search: "",
    sub_org_name: "",
    sub_org_type_name: "",
    sub_org_parent_name: "",
    view_image: false,
    image_link: "",
  };
  componentDidMount() {
    this.props.getUserData();
    ReactGA.pageview(window.location.pathname);
    this.setState({
      selected_user_id: GET_USER_LOCAL()._id.$oid,
      selected_user_name: GET_USER_LOCAL().name,
    });
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });

    this.getCurrentUserSubOrgs();
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_task_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getTaskList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getTaskList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_task_search: value },
      () => {
        this.getTaskList();
      }
    );
  };

  setTimezonetype = (value) => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };

  getTaskList = debounce(() => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TASK_PRESCRIPTION_SEARCH_LIST}?sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_task_search}&ts=${time}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&started_on=${this.state.started_on}&completed_on=${this.state.completed_on}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&search_date_time_type=${this.state.search_date_time_type}&assigned_to_name=${this.state.assigned_to_name}&task_status=${this.state.task_status}&task_code=${this.state.task_code}&name=${this.state.name}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Task prescription list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from Task prescription list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        name: "",
        sub_org_name: "",
        sub_org_type_name: "",
        sub_org_parent_name: "",
        details: "",
        task_type: "",
        task_code: "",
        task_link: "",
        // sub_org_id_task_search: "",
        contact_no: "",
        contact_name: "",
        drop_contact_no: "",
        drop_contact_name: "",
        start_loc_name: "",
        end_loc_name: "",
        started_loc_name: "",
        completed_loc_name: "",
        task_status: "",
        assigned_asset_name: "",
        assigned_to_name: "",
        sort_by: "started_on",
        sort_type: "dsc",
        search_date_time_type: "created_on",
        start_time: moment().startOf("month").format("x"),
        start_time_view: moment().startOf("month"),
        end_time: Date.now(),
        end_time_view: moment(),
        accepted_on: "",
        accepted_on_view: moment(),
        started_on: "",
        started_on_view: moment(),
        completed_on: "",
        completed_on_view: moment(),
        page: 0,
        limit: 10,
        current_user_sub_org_id: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.current_user_sub_org_id,
        sub_org_id_task_search: this.props.userProfileData.data_level
          .access_all_data
          ? ""
          : this.state.sub_org_id_task_search,
      },
      () => {
        this.getTaskList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getTaskList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getTaskList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10, minWidth: 1000 }}>
        <Card style={{ marginTop: 5 }}>
          <Title level={4}>
            Select date range within which you would like to find your tasks
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{ margin: 5, display: "flex", flexDirection: "column" }}
            >
              <Text> Search By : {""}</Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getTaskList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                placeholder="Search By"
              >
                <Option key="started_on">Started on</Option>
                <Option key="completed_on">Completed On</Option>
              </Select>
            </div>
          </div>
        </Card>
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card style={{ marginTop: 5 }}>
            <Title level={4}>Filter Tasks With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{ margin: 10, display: "flex", flexDirection: "column" }}
              >
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div
                style={{ margin: 10, display: "flex", flexDirection: "column" }}
              >
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Code</Text>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_code", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_code", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_code}
                  onChange={(evt) => {
                    this.setState(
                      { task_code: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Code"
            render={(text, record) => (
              <span>{`${record?.task_code ?? ""}`}</span>
            )}
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getTaskList();
                    });
                  }}
                />
              </>
            }
            key="name"
            render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Task Type</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="task_type"
            render={(text, record) => (
              <span>{`${record?.task_type ?? ""}`}</span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Organization Name</Text>
                  <Input
                    value={this.state.sub_org_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Sub Organization"
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Prescription Image</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="prescription_image_path"
            render={(text, record) => (
              <span
                onClick={() =>
                  this.setState({
                    image_link: record.custom_fields[`Prescription Upload`],
                    view_image: true,
                  })
                }
              >
                <img
                  alt=""
                  src={`${record.custom_fields[`Prescription Upload`]}`}
                  width="300"
                  height="300"
                />
              </span>
            )}
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Status</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_status", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_status", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_status}
                  onChange={(evt) => {
                    this.setState(
                      { task_status: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="task_status"
            key="task_status"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Assigned To</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "assigned_to_name", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "assigned_to_name", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.assigned_to_name}
                  onChange={(evt) => {
                    this.setState(
                      { assigned_to_name: evt.target.value, page: 0 },
                      () => {
                        this.getTaskList();
                      }
                    );
                  }}
                />
              </>
            }
            key="assigned_to"
            render={(text, record) => (
              <span>{record?.assigned_to_name ?? ""}</span>
            )}
          />

          {/* <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Organization Name</Text>
                  <Input
                    value={this.state.sub_org_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Sub Organization"
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Organization Type</Text>
                  <Input
                    value={this.state.sub_org_type_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_type_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Sub Organization Type"
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Parent Sub Organization</Text>
                  <Input
                    value={this.state.sub_org_parent_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_parent_name: evt.target.value, page: 0 },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            key="Parent Sub Organization"
            render={(text, record) => (
              <span>{record.sub_org?.parent_sub_org_data?.name ?? ""}</span>
            )}
          /> */}

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Started On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "started_on", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "started_on", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.accepted_on_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          accepted_on: moment(value).format("x"),
                          accepted_on_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="started_on"
            render={(text, record) => (
              <span>
                {record.started_on.$date
                  ? timeConverter(record?.started_on?.$date)
                  : ""}
              </span>
            )}
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Completed On</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "completed_on", sort_type: "asc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "accepted_on", sort_type: "dsc" },
                          () => {
                            this.getTaskList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <Input disabled />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.accepted_on_view}
                    onChange={(value, dateString) => {
                      this.setState(
                        {
                          accepted_on: moment(value).format("x"),
                          accepted_on_view: value,
                          page: 0
                        },
                        () => {
                          this.getTaskList();
                        }
                      );
                    }}
                  />
                </MuiPickersUtilsProvider> */}
              </>
            }
            key="completed_on"
            render={(text, record) => (
              <span>
                {record.completed_on.$date
                  ? timeConverter(record?.completed_on?.$date)
                  : ""}
              </span>
            )}
          />
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          visible={this.state.view_image}
          onOk={() => {
            this.setState({ view_image: false, image_link: "" });
          }}
          onCancel={() => {
            this.setState({ view_image: false, image_link: "" });
          }}
          width={window.innerWidth - 400}
          closable={true}
        >
          <Card style={{textAlign:"center",marginTop:20}}>
          <img alt="" src={this.state.image_link} width={window.innerWidth - 500} />
          </Card>
          
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(
  PrescriptionSearchList
);
