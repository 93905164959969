import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { EDIT_PROCEDURE, CREATE_PROCEDURE } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { Column } = Table;

class ProcedureList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getProcedureList();
    ReactGA.pageview(window.location.pathname);
  }

  getProcedureList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PROCEDURE}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from procedure list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from procedure list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getProcedureList();
      }
    );
  };

  deleteProcedure = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_PROCEDURE}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.procedure.procedure_create?(
           <Link to="/createProcedure">
           <Tooltip placement="top" title="Create New Procedure">
             <Button
               shape="circle"
               icon="plus"
               style={{
                 position: "fixed",
                 zIndex: 100,
                 bottom: 30,
                 right: 20,
                 backgroundColor: "#1A3337",
                 height: 60,
                 width: 60,
                 fontSize: 30,
                 paddingBottom: 5
               }}
             />
           </Tooltip>
         </Link>
        ):null}
       
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Procedure"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Recommender"
            dataIndex="recommender_name"
            key="recommender_name"
          />
          <Column
            title="Approver"
            dataIndex="approver_name"
            key="approver_name"
          />
          <Column
            title="Authorizer"
            dataIndex="authorizer_name"
            key="authorizer_name"
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {GET_USER_LOCAL().org_admin ||
                this.props.userProfileData.procedure.procedure_update ? (
                  <Link
                    to={{
                      pathname: "/editProcedure",
                      state: { procedure_id: record._id.$oid }
                    }}
                  >
                    <Button
                      type="link"
                      style={{ color: "green" }}
                    >
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {GET_USER_LOCAL().org_admin ||
                this.props.userProfileData.procedure.procedure_delete ? (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteProcedure(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(ProcedureList);
