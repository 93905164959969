import React, { Component } from "react";
import { Card, Tabs,Typography } from "antd";
import axios from "axios";
import swal from "sweetalert2";

import "./login.css";

import Order from "./insights/order";
import Task from "./insights/task";
import Attendance from "./insights/attendance";
import Bill from "./insights/bill";
import ReactGA from "react-ga";
import {INTEGRATE_WITH_QUICKBOOKS} from "../api/constants";
import { 
    GET_ACCESS_TOKEN
     } from "../utilities/utilities";


const { TabPane } = Tabs;
const { Title } = Typography;

export default class Home extends Component {
  state = {
    activeKeyInsights: "attendance"
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
   
    if(this.props.location.search){
      console.log("from url params",this.props.location.search)
      let obj=JSON.parse('{"' + decodeURI(this.props.location.search.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
      const data = {
        auth_code: obj[`?code`],
        realm_id:obj['realmId']
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${INTEGRATE_WITH_QUICKBOOKS}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");

            console.log("response from intigrate with quickbooks", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from intigrate with quickbooks", error);
          });
      });

    }
    let key = JSON.parse(localStorage.getItem("activeKeyInsights"));
    if (key) {
      this.setState({ activeKeyInsights: key });
    }
  }

  onChange = activeKeyInsights => {
    console.log(`onChange ${activeKeyInsights}`);
    this.setState(
      {
        activeKeyInsights
      },
      () => {
        localStorage.setItem(
          "activeKeyInsights",
          JSON.stringify(this.state.activeKeyInsights)
        );
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 0 }}>
        <Title style={{marginLeft:15}} level={3}>Analytics</Title>
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={this.state.activeKeyInsights}
          onChange={this.onChange}
        >
          <TabPane tab="Attendance" key="attendance">
            <Attendance />
          </TabPane>
          <TabPane tab="Task" key="task">
            <Task />
          </TabPane>
          <TabPane tab="Order" key="order">
            <Order />
          </TabPane>
          <TabPane tab="Bill" key="bill">
            <Bill />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}
