import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_DRIVER,
  CONNECT_ASSET,
  ASSET_SEARCH,
  ASSET_PROVIDER,
  CONNECT_FINDERASSET_ORGASSET,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class ConnectAssetToUser extends Component {
  state = {
    driver_id: "",
    pin: "",
    loading: false,
    userData: [],
    assetData: [],
    name: "",
    device_name: "",
    device_id: "",
    org_asset_name: "",
    org_asset_id: "",
    finderAssets: [],
    finder_asset_name: "",
    finder_asset_id: "",
  };

  componentDidMount() {
    this.getUserList();
    this.getAssetList();
    this.getFinderAssets();
  }

  setValue = (value) => {
    this.setState({ driver_id: value.key, name: value.label }, () => {
      console.log(this.state.driver_id);
    });
  };
  setAsset = (value) => {
    let pin = value.label.slice("#");

    this.setState({ device_id: value.key, pin: pin[2] }, () => {
      console.log(this.state.device_id);
      console.log(this.state.pin);
    });
  };
  setOrgAsset = (value) => {
    this.setState(
      { org_asset_id: value.key, org_asset_name: value.label },
      () => {
        console.log(this.state.org_asset_id);
        console.log(this.state.org_asset_name);
      }
    );
  };
  setFinderAsset = (value) => {
    this.setState(
      { finder_asset_id: value.key, finder_asset_name: value.label },
      () => {
        console.log(this.state.finder_asset_id);
        console.log(this.state.finder_asset_name);
      }
    );
  };

  getFinderAssets = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_PROVIDER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from Asset Provider get list", response);
          this.setState({
            finderAssets: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from Asset Provider get list", error);
        });
    });
  };

  getAssetList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from asset list", response);
          this.setState({
            assetData: response.data.data,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from asset list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=10&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);
  handleAssetSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_SEARCH}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset search list", response);
        })
        .catch((error) => {
          console.log("error from asset search list api", error);
        });
    });
  }, 500);

  connectToAsset = () => {
    if (
      this.state.pin === "" ||
      this.state.driver_id === "" ||
      this.state.device_id === ""
    ) {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        driver_id: this.state.driver_id,
        device_id: this.state.device_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CONNECT_ASSET}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log("response from connect to asset", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from connect to asset", error);
          });
      });
    }
  };

  connectAssets = () => {
    if (this.state.org_asset_id === "" || this.state.finder_asset_id === "") {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        asset_id: this.state.org_asset_id,
        asset_provider_id: this.state.finder_asset_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CONNECT_FINDERASSET_ORGASSET}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log("response from connect to asset", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from connect to asset", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Card style={{ marginTop: 5 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)",
            }}
            title="Connect Asset to User"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Form.Item>
              <Text>Select User</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearch}
                value={{ key: this.state.driver_id, Label: this.state.name }}
                onChange={this.setValue}
                placeholder="Select User"
              >
                {this.state.userData.map((r) => {
                  return (
                    <Option
                      key={r._id.$oid}
                    >{`${r.name} (Org Status - ${r.organization_status})`}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Select Asset</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleAssetSearch}
                value={{
                  key: this.state.device_id,
                  Label: this.state.device_name,
                }}
                onChange={this.setAsset}
                placeholder="Select Asset"
              >
                {this.state.assetData.map((r) => {
                  return (
                    <Option key={r._id.$oid}>
                      {r.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.connectToAsset}
              >
                Connect User
              </Button>
            </Form.Item>
          </Form>
        </Card>

        <Card style={{ marginTop: 5 }}>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)",
            }}
            title="Connect Organization Asset to Finder Asset"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <Form.Item>
              <Text>Select Organization Asset</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleAssetSearch}
                value={{
                  key: this.state.org_asset_id,
                  Label: this.state.org_asset_name,
                }}
                onChange={this.setOrgAsset}
                placeholder="Select Asset"
              >
                {this.state.assetData.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>Select Finder Asset</Text>
              <Select
                labelInValue
                value={{
                  key: this.state.finder_asset_id,
                  Label: this.state.finder_asset_name,
                }}
                onChange={this.setFinderAsset}
                placeholder="Select Asset"
              >
                {this.state.finderAssets.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.connectAssets}
              >
                Connect Assets
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Card>
    );
  }
}
