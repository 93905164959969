import "date-fns";
import React from "react";
import {
  Select,
  Card,
  Spin,
  Input,
  Typography,
  Button,
  Tooltip,
  Icon,
  Switch,
  Divider,
  Modal,
  List
} from "antd";
import DateFnsUtils from "@date-io/date-fns";
import { debounce } from "lodash";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";

import {
  // GEO_CODING_API,
  // REVERSE_GEO,
  CREATE_DRIVER,
  ASSET_SEARCH,
  CREATE_TASK,
  SHIPMENT_NUMBER,
  USER_LOCATION_LIST,
  CREATE_ENTITY,
  CREATE_ROUTE,
  CREATE_TASK_TYPE,
  GET_ASSIGNED_USER_SUB_ORG_LIST,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE
} from "../../api/constants";
import { GET_ACCESS_TOKEN, LOCAL_USER_INFO } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "leaflet/dist/leaflet.css";

const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40]
});

const userIcon = new L.Icon({
  iconUrl: require("../../assets/user.png"),
  iconSize: [25, 40]
});

export default class CreateTask extends React.Component {
  state = {
    query: "",
    start_location: "",
    end_location: "",
    value: [],
    value1: [],
    data: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    end_location_lat: 0,
    end_location_lon: 0,
    name: "",
    details: "",
    start_time: Date.now(),
    start_time_view: moment(),
    start_time_view2: moment(),
    end_time: Date.now(),
    end_time_view: moment(),
    task_code: "",
    showContactDropdown: true,
    contact_id: "",
    contact_no: "",
    contact_name: "",
    contact_info: "",
    showDropcDropdown: true,
    drop_contact_id: "",
    drop_contact_no: "",
    drop_contact_name: "",
    drop_contact_info: "",
    price: "",
    assigned_to: "",
    assigned_to_label: "",
    userData: [],
    assigned_asset: "",
    assigned_asset_label: "",
    assetData: [],
    delay_mins: null,
    colors: ['#f44336'],
    colorId: [],
    trip_duration: 0,
    accepted: false,
    started: false,
    completed: false,
    cancelled: false,
    is_public: false,
    loading: false,
    isChainTask: false,
    delivery_time: "",
    delivery_time_view: moment(),
    delivery_time_by_customer: "",
    delivery_time_by_customer_view: moment(),
    delay_reason: "",
    secondaryUsers: [],

    prev_task_id: "",
    m_task_id: "",
    start_dist: null,
    end_dist: null,
    editStart: false,
    editEnd: false,
    UserLocationList: [],
    showUsers: false,
    zoom: 15,
    showClearbutton: false,
    redirect: false,
    filtered: [],
    savedRoutes: [],
    saveStartModal: false,
    entityList: [],
    startEntityModal: false,
    endEntityModal: false,
    taskTypes: [],
    task_type: "",
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: ""
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    window.scrollTo(0, 0);
    if (localStorage.getItem("pastTaskId")) {
      this.setState(
        { prev_task_id: localStorage.getItem("pastTaskId") },
        () => {
          console.log(this.state.prev_task_id);
          localStorage.removeItem("pastTaskId");
        }
      );
    }
    if (localStorage.getItem("mtaskId")) {
      this.setState({ m_task_id: localStorage.getItem("mtaskId") }, () => {
        console.log(this.state.m_task_id);
      });
    }

    if (localStorage.getItem("pastInfo")) {
      let userInfo = localStorage.getItem("pastInfo");
      let user = JSON.parse(userInfo);
      this.setState(
        {
          start_time_view: moment(parseInt(user.end_time)),
          start_time: user.end_time,
          start_location: user.end_location,
          value: user.end_location,
          start_location_lon: user.end_location_lon,
          start_location_lat: user.end_location_lat,
          editStart: true,
          showClearbutton: true
        },
        () => {
          localStorage.removeItem("pastInfo");
        }
      );
    }
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState({
        start_location_lat: latitude,
        start_location_lon: longitude,
        center_lat: latitude,
        center_lon: longitude
      });
    };
    navigator.geolocation.getCurrentPosition(success);
    this.getShipmentNumber();
    this.getUserList();
    this.getAssetList();
    this.getUserLocationList();
    this.getEntityList();
    this.getRouteList();
    this.getTaskType();
  }

  getTaskType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from task type list", response);

          this.setState({
            taskTypes: response.data.data
          });
        })
        .catch(error => {
          console.log("error from task type list api", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ENTITY}?limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from entity list", response);
          this.setState({
            entityList: response.data.data
          });
        })
        .catch(error => {
          console.log("error from entity list api", error);
        });
    });
  };
  getRouteList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ROUTE}?limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from route list", response);
          this.setState({
            savedRoutes: response.data.data
          });
        })
        .catch(error => {
          console.log("error from route list api", error);
        });
    });
  };

  getUserLocationList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${USER_LOCATION_LIST}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log(
            "response from organization user locstion list",
            response
          );

          this.setState({ UserLocationList: response.data.data });
        })
        .catch(error => {
          console.log("error from org user locstion list api", error);
        });
    });
  };

  getShipmentNumber = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${SHIPMENT_NUMBER}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ task_code: response.data.data[0] });
          console.log("response from shipment number list", response);
        })
        .catch(error => {
          console.log("error from shipment number list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 10;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${ASSET_SEARCH}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset list", response);
        })
        .catch(error => {
          console.log("error from asset list api", error);
        });
    });
  };

  colors = [
    {
      id: 1,
      color: "#f44336"
    },
    {
      id: 2,
      color: "#E91E63"
    },
    {
      id: 3,
      color: "#9C27B0"
    },
    {
      id: 4,
      color: "#673AB7"
    },
    {
      id: 5,
      color: "#3F51B5"
    },
    {
      id: 6,
      color: "#2196F3"
    },
    {
      id: 7,
      color: "#009688"
    },
    {
      id: 8,
      color: "#4CAF50"
    },
    {
      id: 9,
      color: "#FFEB3B"
    },
    {
      id: 10,
      color: "#FF9800"
    }
  ];

  setName = evt => {
    this.setState({ name: evt.target.value });
    console.log(this.state.name);
  };

  setTaskType = value => {
    this.setState({ task_type: value }, () => {
      console.log(this.state.task_type);
    });
  };

  setDetails = evt => {
    this.setState({ details: evt.target.value });
    console.log(this.state.details);
  };
  setDelayReason = evt => {
    this.setState({ delay_reason: evt.target.value });
    console.log(this.state.delay_reason);
  };

  setTaskCode = evt => {
    this.setState({ task_code: evt.target.value });
    console.log(this.state.task_code);
  };

  setContact = value => {
    let string = value.label.split(">");
    this.setState(
      {
        contact_id: value.key,
        contact_name: string[0],
        contact_no: string[1],
        filtered: []
      },
      () => {
        console.log(this.state.contact_name);
        console.log(this.state.contact_no);
        console.log(this.state.contact_id);
      }
    );
  };

  setContactNumber = evt => {
    this.setState({ contact_no: evt.target.value });
    console.log(this.state.contact_no);
  };

  setContactName = evt => {
    this.setState({ contact_name: evt.target.value });
    console.log(this.state.contact_name);
  };

  setDropContact = value => {
    let string = value.label.split(">");
    this.setState(
      {
        drop_contact_id: value.key,
        drop_contact_name: string[0],
        drop_contact_no: string[1],
        filtered: []
      },
      () => {
        console.log(this.state.drop_contact_name);
        console.log(this.state.drop_contact_no);
        console.log(this.state.drop_contact_id);
      }
    );
  };

  setDropContactNumber = evt => {
    this.setState({ drop_contact_no: evt.target.value });
    console.log(this.state.drop_contact_no);
  };

  setDropContactName = evt => {
    this.setState({ drop_contact_name: evt.target.value });
    console.log(this.state.drop_contact_name);
  };

  setPrice = evt => {
    this.setState({ price: evt.target.value });
    console.log(this.state.price);
  };

  setTripDuration = evt => {
    this.setState({ trip_duration: evt.target.value });
    console.log(this.state.trip_duration);
  };

  setDelayMinutes = evt => {
    this.setState({ delay_mins: evt.target.value });
    console.log(this.state.delay_mins);
  };

  setAssigned = value => {
    this.setState(
      { assigned_to_label: value.label, assigned_to: value.key, filtered: [] },
      () => {
        console.log(this.state.assigned_to);
        GET_ACCESS_TOKEN().then(token => {
          const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${this.state.assigned_to}&access_token=${token}`;
          axios({
            method: "get",
            url: url
          })
            .then(response => {
              if (response.data.data.length === 1) {
                this.setState({
                  assigned_user_sub_orgs: response.data.data,
                  sub_org_id: response.data.data[0].id,
                  sub_org_name: response.data.data[0].name
                });
              } else {
                this.setState({ assigned_user_sub_orgs: response.data.data });
              }
              console.log("response from assigned user sub org list", response);
            })
            .catch(error => {
              console.log("error from assigned user sub org list api", error);
            });
        });
      }
    );
  };

  setSubOrg = value => {
    this.setState({ sub_org_id: value.key, sub_org_name: value.label }, () => {
      console.log("sub org value", this.state.sub_org_id);
    });
  };

  handleSearch = debounce(value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);

  setAssignedAsset = value => {
    this.setState(
      { assigned_asset_label: value.label, assigned_asset: value.key },
      () => {
        console.log(this.state.assigned_asset_label);
      }
    );
  };

  handleAchange = checked => {
    this.setState({ accepted: checked }, () => {
      console.log(this.state.accepted);
    });
  };
  handleSchange = checked => {
    this.setState({ started: checked }, () => {
      console.log(this.state.started);
    });
  };
  handleCochange = checked => {
    this.setState({ completed: checked }, () => {
      console.log(this.state.completed);
    });
  };
  handleCachange = checked => {
    this.setState({ cancelled: checked }, () => {
      console.log(this.state.cancelled);
    });
  };
  handlePchange = checked => {
    this.setState({ is_public: checked }, () => {
      console.log(this.state.is_public);
    });
  };

  setChainTask = checked => {
    this.setState({ isChainTask: checked }, () => {
      console.log(this.state.isChainTask);
    });
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { start_time: moment(value).format("x"), start_time_view2: value },
      () => {
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected value: ", this.state.start_time_view2);
      }
    );
  };
  setStartTime2 = (value, dateString) => {
    this.setState(
      {
        start_time: moment(value).format("x"),
        start_time_view: value
      },
      () => {
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected Time view: ", this.state.start_time_view);
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      { end_time: moment(value).format("x"), end_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.end_time);
        console.log("Selected Time: ", this.state.end_time_view);
      }
    );
  };
  setDeliveryTime = (value, dateString) => {
    this.setState(
      { delivery_time: moment(value).format("x"), delivery_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.delivery_time);
        console.log("Selected Time: ", this.state.delivery_time_view);
      }
    );
  };
  setDeliveryTimeCustomer = (value, dateString) => {
    this.setState(
      {
        delivery_time_by_customer: moment(value).format("x"),
        delivery_time_by_customer_view: value
      },
      () => {
        console.log("Selected Time: ", this.state.delivery_time_by_customer);
        console.log(
          "Selected Time: ",
          this.state.delivery_time_by_customer_view
        );
      }
    );
  };

  handleStart = debounce(value => {
    this.setState({ query: value, data: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.query}&access_token=${token}`
            )
            .then(response => {
              console.log(response);
              const data = response.data.google_loc_datas.map(res => ({
                id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
                value: `${res.name}>${res.address}`
              }));
              this.setState({ data: data, fetch: false });
              console.log(response.data.google_loc_datas);
            });
        });
      }
    });
  }, 500);

  handleStartChange = value => {
    this.setState({ value, data: [], fetch: false });

    return this.setState({
      start_location: value.label.split(">")[0],
      start_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      start_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2]
    });
  };

  updatePositionStart = evt => {
    console.log(evt.target.getLatLng());
    if (
      this.state.end_location_lat === 0 &&
      this.state.end_location_lon === 0
    ) {
      this.setState(
        {
          start_location_lat: evt.target.getLatLng().lat,
          start_location_lon: evt.target.getLatLng().lng,
          end_location_lon: evt.target.getLatLng().lng,
          end_location_lat: evt.target.getLatLng().lat,
          center_lon: evt.target.getLatLng().lng,
          center_lat: evt.target.getLatLng().lat
        },
        () => {
          GET_ACCESS_TOKEN().then(token => {
            axios
              .get(
                `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
              )
              .then(response => {
                let name = response.data.google_loc_data[0].address;
                let id = response.data.google_loc_data[0]._id.$oid;
                this.setState({
                  start_location: name,
                  value: { key: id, label: name }
                });
              });
          });
        }
      );
    } else {
      this.setState(
        {
          start_location_lat: evt.target.getLatLng().lat,
          start_location_lon: evt.target.getLatLng().lng,
          center_lon: evt.target.getLatLng().lng,
          center_lat: evt.target.getLatLng().lat
        },
        () => {
          GET_ACCESS_TOKEN().then(token => {
            axios
              .get(
                `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`
              )
              .then(response => {
                let name = response.data.google_loc_data[0].address;
                let id = response.data.google_loc_data[0]._id.$oid;
                this.setState({
                  start_location: name,
                  value: { key: id, label: name }
                });
              });
          });
        }
      );
    }
  };

  handleEndChange = value => {
    this.setState({ value1: value, data: [], fetch: false });

    return this.setState(
      {
        end_location: value.label.split(">")[0],
        end_location_lon: value.key.split(">")[1],
        center_lon: value.key.split(">")[1],
        end_location_lat: value.key.split(">")[2],
        center_lat: value.key.split(">")[2]
      }
    );
  };

  updatePositionEnd = evt => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.end_location_lat}&lng=${this.state.end_location_lon}&access_token=${token}`
            )
            .then(response => {
              console.log("response for end marker change", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                end_location: name,
                value1: { key: id, label: name }
              });
            });
        });
      }
    );
  };

  createTask = () => {
    if (this.state.is_public === true && this.state.price === "0") {
      swal.fire("Info", "Please enter a valid price for public task", "info");
    } else if (this.state.is_public === true && this.state.price === "") {
      swal.fire("Info", "Please enter a valid price for public task", "info");
    } else if (
      this.state.start_location === "" ||
      this.state.end_location === ""
    ) {
      swal.fire("Info", " Please enter a valid start and end location", "info");
    } else if (this.state.start_time === "" || this.state.end_time === "") {
      swal.fire("Info", "Please enter a valid start and end time", "info");
    } else if (
      Date.now() > this.state.start_time ||
      Date.now() > this.state.end_time
    ) {
      swal.fire("Info", "Please enter a Future start and end time", "info");
    } else if (
      this.state.contact_no !== "" &&
      !this.state.contact_no.includes("+")
    ) {
      swal.fire(
        "Info",
        "Enter Country Code For Pickup Contact.Example: +8801711123456",
        "info"
      );
    } else if (
      this.state.drop_contact_no !== "" &&
      !this.state.drop_contact_no.includes("+")
    ) {
      swal.fire(
        "Info",
        "Enter Country Code For Dropoff Contact.Example: +8801711123456",
        "info"
      );
    }
    // else if(this.state.task_type===""){
    //   swal.fire(
    //     "Info",
    //     "Select a task type",
    //     "info"
    //   );
    // }
    else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        task_type: this.state.task_type,
        details: this.state.details,
        task_code: this.state.task_code,
        start_loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat
          ]
        },
        start_time: this.state.start_time,
        start_loc_name: this.state.start_location,
        start_dist: this.state.start_dist,
        end_loc: {
          coordinates: [
            this.state.end_location_lon,
            this.state.end_location_lat
          ]
        },
        end_time: this.state.end_time,
        end_loc_name: this.state.end_location,
        end_dist: this.state.end_dist,
        contact_no: this.state.contact_no,
        contact_name: this.state.contact_name,
        contact_id: this.state.contact_id,
        drop_contact_no: this.state.drop_contact_no,
        drop_contact_name: this.state.drop_contact_name,
        drop_contact_id: this.state.drop_contact_id,
        price: Number(this.state.price),
        assigned_to: this.state.is_public ? "" : this.state.assigned_to,
        sub_org: this.state.sub_org_id,
        assigned_to_name: this.state.is_public
          ? ""
          : this.state.assigned_to_label.split(">")[0],
        assigned_asset: this.state.is_public ? "" : this.state.assigned_asset,
        assigned_asset_name: this.state.is_public
          ? ""
          : this.state.assigned_asset_label,
        prev_task_id: this.state.prev_task_id,
        m_task_id: this.state.m_task_id,
        delay_mins: this.state.delay_mins,
        colors: this.state.colors,
        trip_duration: this.state.trip_duration,
        comments: "",
        accepted: this.state.accepted,
        started: this.state.started,
        completed: this.state.completed,
        cancelled: this.state.cancelled,
        is_public: this.state.is_public,
        delivery_time: this.state.delivery_time,
        delivery_time_by_customer: this.state.delivery_time_by_customer,
        delay_reason: this.state.delay_reason,
        assign_connected_users: this.state.secondaryUsers
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_TASK}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            if (
              this.state.isChainTask &&
              response.data.data.prev_task_id === ""
            ) {
              localStorage.setItem("mtaskId", response.data.data._id.$oid);
            } else if (
              !this.state.isChainTask &&
              localStorage.getItem("mtaskId")
            ) {
              localStorage.removeItem("mtaskId");
            }

            if (this.state.isChainTask === true) {
              localStorage.setItem("pastTaskId", response.data.data._id.$oid);
              localStorage.setItem(
                "pastInfo",
                JSON.stringify({
                  end_location: this.state.end_location,
                  end_time: this.state.end_time,
                  end_location_lon: this.state.end_location_lon,
                  end_location_lat: this.state.end_location_lat
                })
              );
              window.location.reload();
            }

            swal.fire("Info", response.data.message, "info");
            console.log("response from create task", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create task", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    if (this.state.redirect === true) {
      return <Redirect to="/taskList" />;
    }

    // const UserList = this.state.userData.map(r => {
    //   return <Option key={r._id.$oid}>{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>;
    // });

    // const FliteredList = this.state.filtered.map(r => {
    //   return <Option key={r._id.$oid}>{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>;
    // });
    return (
      <Card style={{ margin: 10 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                marginRight: 10,
                flexDirection: "column"
              }}
            >
              <Text>Start Location</Text>
              {this.state.editStart === true ? (
                <Input
                  type="text"
                  placeholder="Start Location"
                  value={this.state.start_location}
                  onChange={evt =>
                    this.setState({ start_location: evt.target.value })
                  }
                  style={{ width: window.innerWidth / 2.8 }}
                />
              ) : (
                <Select
                  showSearch={true}
                  labelInValue
                  value={this.state.value}
                  notFoundContent={fetch ? <Spin size="small" /> : null}
                  style={{ width: window.innerWidth / 2.8 }}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  placeholder="Search Start Location"
                  onSearch={this.handleStart}
                  onChange={this.handleStartChange}
                  optionLabelProp="label"
                >
                  {this.state.data.map(r => {
                    return (
                      <Option key={r.id} label={r.value}>
                        {
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Text>{r.value.split(">")[0]}</Text>
                            <Text style={{ fontSize: 10, color: "#9CB4CC" }}>
                              {r.value.split(">")[1]}
                            </Text>
                          </div>
                        }
                      </Option>
                    );
                  })}
                </Select>
              )}
            </div>
            {this.state.start_location === "" ? null : (
              <Tooltip
                title={
                  this.state.editStart
                    ? "Search Start Location"
                    : "Edit Start Location"
                }
              >
                <Button
                  type="primary"
                  shape="circle"
                  style={{ marginTop: 20, marginRight: 10 }}
                  onClick={() =>
                    this.setState({ editStart: !this.state.editStart })
                  }
                >
                  <Icon type={this.state.editStart ? "search" : "edit"} />
                </Button>
              </Tooltip>
            )}

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>End Location</Text>
              {this.state.editEnd === true ? (
                <Input
                  type="text"
                  placeholder="End Location"
                  value={this.state.end_location}
                  onChange={evt =>
                    this.setState({ end_location: evt.target.value })
                  }
                  style={{ width: window.innerWidth / 2.8 }}
                />
              ) : (
                <Select
                  showSearch={true}
                  labelInValue
                  value={this.state.value1}
                  notFoundContent={fetch ? <Spin size="small" /> : null}
                  style={{ width: window.innerWidth / 2.8 }}
                  filterOption={false}
                  showArrow={false}
                  placeholder="Search End Location"
                  onSearch={this.handleStart}
                  onChange={this.handleEndChange}
                  optionLabelProp="label"
                >
                  {this.state.data.map(r => {
                    return (
                      <Option key={r.id} label={r.value}>
                        {
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Text>{r.value.split(">")[0]}</Text>
                            <Text style={{ fontSize: 10, color: "#9CB4CC" }}>
                              {r.value.split(">")[1]}
                            </Text>
                          </div>
                        }
                      </Option>
                    );
                  })}
                </Select>
              )}
            </div>
            {this.state.end_location === "" ? null : (
              <Tooltip
                title={
                  this.state.editEnd
                    ? "Search End Location"
                    : "Edit End Location"
                }
              >
                <Button
                  type="primary"
                  shape="circle"
                  style={{ marginTop: 20, marginLeft: 10 }}
                  onClick={() =>
                    this.setState({ editEnd: !this.state.editEnd })
                  }
                >
                  <Icon type={this.state.editEnd ? "search" : "edit"} />
                </Button>
              </Tooltip>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="primary"
              onClick={() =>
                this.setState({ showUsers: !this.state.showUsers })
              }
              style={{ margin: 5, width: 100 }}
            >
              {this.state.showUsers ? "Hide Users" : "Show Users"}
            </Button>
            {this.state.showClearbutton ? (
              <Button
                type="primary"
                onClick={() =>
                  this.setState({
                    start_time_view: "",
                    start_time: "",
                    start_location: "",
                    value: [],
                    start_location_lat: 23.685,
                    start_location_lon: 90.3563,
                    editStart: false,
                    showClearbutton: false,
                    redirect: true
                  })
                }
                style={{ margin: 5, width: 200 }}
              >
                Clear Chain Task Data
              </Button>
            ) : null}
            <Button
              type="primary"
              onClick={() => this.setState({ saveStartModal: true })}
              style={{ margin: 5 }}
            >
              Select Location From Routes
            </Button>
            <Modal
              title="Select Route"
              visible={this.state.saveStartModal}
              onOk={() => this.setState({ saveStartModal: false })}
              onCancel={() => this.setState({ saveStartModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.savedRoutes}
                renderItem={item => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        start_location: item.start_loc_name,
                        end_location: item.end_loc_name,
                        start_location_lon: item.start_loc.coordinates[0],
                        start_location_lat: item.start_loc.coordinates[1],
                        end_location_lon: item.end_loc.coordinates[0],
                        end_location_lat: item.end_loc.coordinates[1],
                        center_lon: item.end_loc.coordinates[0],
                        center_lat: item.end_loc.coordinates[1],
                        saveStartModal: false,
                        editStart: true,
                        editEnd: true
                      });
                    }}
                  >{`From : ${item.start_loc_name} - To : ${item.end_loc_name}`}</List.Item>
                )}
              />
            </Modal>
            <Button
              type="primary"
              onClick={() => this.setState({ startEntityModal: true })}
              style={{ margin: 5 }}
            >
              Select Start Location From Place
            </Button>
            <Modal
              title="Select Start Location"
              visible={this.state.startEntityModal}
              onOk={() => this.setState({ startEntityModal: false })}
              onCancel={() => this.setState({ startEntityModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.entityList}
                renderItem={item => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        start_location: item.address,
                        start_location_lon: item.loc.coordinates[0],
                        start_location_lat: item.loc.coordinates[1],
                        center_lon: item.loc.coordinates[0],
                        center_lat: item.loc.coordinates[1],
                        startEntityModal: false,
                        editStart: true
                      });
                      if (item.hasOwnProperty("contact")) {
                        this.setState({
                          contact_name: item.contact?.name ?? "",
                          contact_no: item.contact?.mobile ?? ""
                        });
                      }
                    }}
                  >{`${item.name} - ${item.address}`}</List.Item>
                )}
              />
            </Modal>
            <Button
              type="primary"
              onClick={() => this.setState({ endEntityModal: true })}
              style={{ margin: 5 }}
            >
              Select End Location From Place
            </Button>
            <Modal
              title="Select End Location"
              visible={this.state.endEntityModal}
              onOk={() => this.setState({ endEntityModal: false })}
              onCancel={() => this.setState({ endEntityModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.entityList}
                renderItem={item => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        end_location: item.address,
                        end_location_lon: item.loc.coordinates[0],
                        end_location_lat: item.loc.coordinates[1],
                        center_lon: item.loc.coordinates[0],
                        center_lat: item.loc.coordinates[1],
                        endEntityModal: false,
                        editEnd: true
                      });
                      if (item.hasOwnProperty("contact")) {
                        this.setState({
                          drop_contact_name: item.contact?.name ?? "",
                          drop_contact_no: item.contact?.mobile ?? ""
                        });
                      }
                    }}
                  >{`${item.name} - ${item.address}`}</List.Item>
                )}
              />
            </Modal>
          </div>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 500 }}
            onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
          >
            {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            /> */}
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2"
              }}
              type={"roadmap"}
            />

            {this.state.showUsers ? (
              <div>
                {this.state.UserLocationList.map(user => {
                  return (
                    <Marker
                      key={user.locs.user_id.$oid}
                      position={[
                        user.locs.loc.coordinates[0],
                        user.locs.loc.coordinates[1]
                      ]}
                      icon={userIcon}
                    >
                      <Popup>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>Name : {user.user}</span>
                          {user.mobile === "" ? (
                            <span>Mobile : {user.email}</span>
                          ) : (
                            <span>Mobile : {user.mobile}</span>
                          )}
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
              </div>
            ) : null}

            <Marker
              position={[
                this.state.start_location_lat,
                this.state.start_location_lon
              ]}
              draggable
              onDragend={this.updatePositionStart}
              icon={green}
            >
              <Popup>
                <span>From :{this.state.start_location}</span>
              </Popup>
            </Marker>

            {this.state.start_location !== "" ? (
              <Marker
                position={[
                  this.state.end_location_lat,
                  this.state.end_location_lon
                ]}
                draggable
                onDragend={this.updatePositionEnd}
                icon={red}
              >
                <Popup>
                  <span>To :{this.state.end_location}</span>
                </Popup>
              </Marker>
            ) : null}
          </Map>
          <div style={{ display: "flex", flex: 1 }}>
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <div style={{ display: "flex" }}>
                  <Text>
                    Select Colors :{" "}
                    {this.state.colors.length > 1
                      ? `${this.state.colors.length} Colors Selected`
                      : `${this.state.colors.length} Color Selected`}
                  </Text>
                  <button
                    className="borderless-button"
                    style={{ color: "red" }}
                    onClick={() => {
                      this.setState({ colors: [], colorId: "" }, () => {
                        console.log(this.state.colors);
                      });
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {this.colors.map(color => (
                    <div key={color.id}>
                      {this.state.colorId === color.id ? (
                        <Button
                          style={{
                            borderRadius: 50,
                            backgroundColor: color.color,
                            height: 40,
                            width: 40,
                            margin: 5,
                            borderColor: "black"
                          }}
                          onClick={() => {
                            this.state.colors.push(color.color);
                            this.setState({ colorId: color.id }, () => {
                              console.log(this.state.colorId);
                            });
                            console.log(this.state.colors);
                          }}
                        />
                      ) : (
                        <Button
                          style={{
                            borderRadius: 50,
                            backgroundColor: color.color,
                            height: 35,
                            width: 35,
                            margin: 5
                          }}
                          onClick={() => {
                            this.state.colors.push(color.color);
                            this.setState({ colorId: color.id }, () => {
                              console.log(this.state.colorId);
                            });
                            console.log(this.state.colors);
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {LOCAL_USER_INFO().user.is_staff === false ? null : (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        marginRight: 5
                      }}
                    >
                      <Switch
                        checkedChildren="Accepted"
                        unCheckedChildren="Accepted"
                        defaultChecked={this.state.accepted}
                        onChange={this.handleAchange}
                      />
                    </div>
                    <div
                      style={{
                        marginRight: 5
                      }}
                    >
                      <Switch
                        checkedChildren="Started"
                        unCheckedChildren="Started"
                        defaultChecked={this.state.started}
                        onChange={this.handleSchange}
                      />
                    </div>
                    <div
                      style={{
                        marginRight: 5
                      }}
                    >
                      <Switch
                        checkedChildren="Completed"
                        unCheckedChildren="Completed"
                        defaultChecked={this.state.completed}
                        onChange={this.handleCochange}
                      />
                    </div>
                    <div
                      style={{
                        marginRight: 5
                      }}
                    >
                      <Switch
                        checkedChildren="Cancelled"
                        unCheckedChildren="Cancelled"
                        defaultChecked={this.state.cancelled}
                        onChange={this.handleCachange}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    marginRight: 5
                  }}
                >
                  <Switch
                    checkedChildren="Public"
                    unCheckedChildren="Public"
                    defaultChecked={this.state.is_public}
                    onChange={this.handlePchange}
                  />
                </div>
                <div
                  style={{
                    marginRight: 5
                  }}
                >
                  <Switch
                    checkedChildren="Chain task"
                    unCheckedChildren="Chain task"
                    defaultChecked={this.state.isChainTask}
                    onChange={this.setChainTask}
                  />
                </div>
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Trip Duration</Text>

                <Input
                  type="number"
                  placeholder="Trip Duration"
                  value={this.state.trip_duration}
                  onChange={this.setTripDuration}
                />
              </div>

              <div style={{ marginBottom: 5 }}>
                <Text>Price</Text>

                <Input
                  type="number"
                  placeholder="Price"
                  value={this.state.price}
                  onChange={this.setPrice}
                />
              </div>
              {this.state.is_public === true ? null : (
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Search Assigned User</Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleSearch}
                      style={{ width: "100%" }}
                      value={{
                        key: this.state.assigned_to,
                        label: this.state.assigned_to_label
                      }}
                      onChange={this.setAssigned}
                      placeholder="Select User"
                    >
                      {this.state.userData.map(r => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Select Sub Organization</Text>
                    <Select
                      style={{ width: "100%" }}
                      labelInValue
                      value={{
                        key: this.state.sub_org_id,
                        label: this.state.sub_org_name
                      }}
                      disabled={
                        this.state.assigned_user_sub_orgs.length < 2
                          ? true
                          : false
                      }
                      onChange={this.setSubOrg}
                      placeholder="Select Sub Organization"
                    >
                      {this.state.assigned_user_sub_orgs.map(r => {
                        return <Option key={r.id}>{`${r.name}`}</Option>;
                      })}
                    </Select>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text>Search Secondary Users</Text>
                    <Select
                      mode="multiple"
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      value={this.state.secondaryUsers}
                      onSearch={this.handleSearch}
                      onChange={value => {
                        this.setState({ secondaryUsers: value }, () => {
                          console.log(this.state.secondaryUsers);
                        });
                      }}
                      placeholder="Search Secondary Users"
                    >
                      {this.state.userData.map(r => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Assign to (Asset)</Text>
                    <Select
                      labelInValue
                      style={{ width: "100%" }}
                      value={{
                        key: this.state.assigned_asset,
                        label: this.state.assigned_asset_label
                      }}
                      onChange={this.setAssignedAsset}
                      placeholder="Select Asset"
                    >
                      {this.state.assetData.map(r => {
                        return <Option key={r._id.$oid}>{r.name}</Option>;
                      })}
                    </Select>
                  </div>
                </div>
              )}
              {/* <div style={{ marginBottom: 5 }}>
                <Text>Assign TPL(Third Party Logistic)</Text>

                <Input
                  type="Assign TPL(Third Party Logistic)"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Hiring Price</Text>

                <Input
                  type="Hiring Price"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Agency/Contractor</Text>

                <Input
                  type="Agency/Contractor"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Demurrage/Extended Cost</Text>

                <Input
                  type="Demurrage/Extended Cost"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Current Millage After Trip</Text>

                <Input
                  type="Current Millage After Trip"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div> */}

              <div style={{ marginBottom: 5 }}>
                <Text>Delay in Minutes</Text>

                <Input
                  type="number"
                  placeholder="Delay in Minutes"
                  value={this.state.delay_mins}
                  onChange={this.setDelayMinutes}
                />
              </div>
              {/* <Card
                style={{
                  margin: 3
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Text>Delivery Date & Time</Text>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.delivery_time_view}
                      onChange={this.setDeliveryTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card> */}
              {/* <Card
                style={{
                  margin: 3
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Text>Delivery Date & Time by Customer</Text>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.delivery_time_by_customer_view}
                      onChange={this.setDeliveryTimeCustomer}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card> */}
              <div style={{ marginBottom: 5 }}>
                <Text>Delay Reason</Text>

                <TextArea
                  rows={4}
                  placeholder="Delay Reason"
                  value={this.state.delay_reason}
                  onChange={this.setDelayReason}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: 10
              }}
            >
              {/* <div style={{ marginBottom: 5 }}>
                <Text>Shipment Number</Text>

                <Input
                  type="text"
                  placeholder="Type Shipment Number"
                  value={this.state.task_code}
                  onChange={this.setTaskCode}
                  allowClear={true}
                />
              </div> */}
              <Card style={{ margin: 3 }}>
                <div style={{ marginBottom: 5 }}>
                  <Text>Search Pickup Contact From List</Text>
                  <Select
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    onSearch={this.handleSearch}
                    labelInValue
                    style={{ width: "100%" }}
                    value={{
                      key: this.state.contact_id,
                      label: this.state.contact_info
                    }}
                    onChange={this.setContact}
                    placeholder="Select Pickup Contact"
                  >
                    {this.state.userData.map(r => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                      );
                    })}
                  </Select>
                </div>
                <Divider>Or Enter Manually</Divider>
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Pickup Contact Number</Text>

                    <Input
                      type="text"
                      placeholder="Example:+8801711123456"
                      value={this.state.contact_no}
                      onChange={this.setContactNumber}
                    />
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Pickup Contact Name</Text>

                    <Input
                      type="text"
                      placeholder="Pickup Contact Name"
                      value={this.state.contact_name}
                      onChange={this.setContactName}
                    />
                  </div>
                </div>
              </Card>

              <Card style={{ margin: 3 }}>
                <div style={{ marginBottom: 5 }}>
                  <Text>Search Drop-off Contact From List</Text>
                  <Select
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    onSearch={this.handleSearch}
                    labelInValue
                    style={{ width: "100%" }}
                    value={{
                      key: this.state.drop_contact_id,
                      label: this.state.drop_contact_name
                    }}
                    onChange={this.setDropContact}
                    placeholder="Select Drop-off Contact"
                  >
                    {this.state.userData.map(r => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                      );
                    })}
                  </Select>
                </div>
                <Divider>Or Enter Manually</Divider>
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Drop-off Contact Number</Text>

                    <Input
                      type="text"
                      placeholder="Example:+8801711123456"
                      value={this.state.drop_contact_no}
                      onChange={this.setDropContactNumber}
                    />
                  </div>
                  <div style={{ marginBottom: 5 }}>
                    <Text>Drop-off Contact Name</Text>

                    <Input
                      type="text"
                      placeholder="Drop-off Contact Name"
                      value={this.state.drop_contact_name}
                      onChange={this.setDropContactName}
                    />
                  </div>
                </div>
              </Card>
              {this.state.start_time_view === "" ? (
                <Card
                  style={{
                    margin: 3
                  }}
                >
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Text>Start Date & Time</Text>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.start_time_view2}
                        onChange={this.setStartTime}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Card>
              ) : (
                <Card
                  style={{
                    margin: 3
                  }}
                >
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    <Text>Start Date & Time</Text>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.start_time_view}
                        onChange={this.setStartTime2}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Card>
              )}
              <Card
                style={{
                  margin: 3
                }}
              >
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Text>End Date & Time</Text>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.end_time_view}
                      onChange={this.setEndTime}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Card>
              <div style={{ marginBottom: 5 }}>
                <Text>Task Name</Text>

                <Input
                  type="text"
                  placeholder="Task Name"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>Task Type</Text>
                <Select
                  value={this.state.task_type}
                  onChange={this.setTaskType}
                  placeholder="Task Type"
                >
                  {this.state.taskTypes.map(r => {
                    return <Option key={r.name}>{r.name}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ marginBottom: 5 }}>
                <Text>Task Details</Text>

                <TextArea
                  rows={4}
                  placeholder="Task Details"
                  value={this.state.details}
                  onChange={this.setDetails}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          className="login-form-button"
          htmlType="submit"
          onClick={this.createTask}
          style={{ marginTop: 24, marginBottom: 15 }}
        >
          Create Task
        </Button>
      </Card>
    );
  }
}
