import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import {
  CREATE_DRIVER,
  CREATE_ASSET,
  CREATE_TASK,
  CREATE_ORDER,
  CREATE_PARCEL,
  CREATE_CONTAINER,
  CREATE_REMINDER,
  CREATE_ENTITY,
  CREATE_BILL,
  CREATE_REQUISITION,
  REMINDER_TYPE_CHOICES
} from "../../api/constants";
import DateFnsUtils from "@date-io/date-fns";
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { GET_ACCESS_TOKEN, GET_USER_LOCAL } from "../../utilities/utilities";
import ReactGA from "react-ga";


import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateReminder extends Component {
  state = {
    name: "",
    reminder_type: "daily",
    reason: "",
    start_date: Date.now(),
    start_date_view: moment(),
    end_date: Date.now(),
    end_date_view: moment(),
    reminder_time: Date.now(),
    reminder_time_view: moment(),
    custom_days: [],
    custom_weeks: [],
    custom_months: [],
    asset_id: "",
    asset_name: "",
    task_id: "",
    task_name: "",
    order_id: "",
    order_name: "",
    parcel_name: "",
    parcel_id: "",
    container_id: "",
    container_name: "",
    entity_id: "",
    entity_name: "",
    bill_id: "",
    bill_name: "",
    requisition_id: "",
    requisition_name: "",
    created_for_id: "",
    created_for_name: "",
    reminderData: [],
    taskData: [],
    assetData: [],
    userData: [],
    orderData: [],
    parcelData: [],
    containerData: [],
    entityData: [],
    billData: [],
    requisitionData: []
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);

    this.setState({
      asset_id:this.props?.location?.state?.asset_id,
      task_id:this.props?.location?.state?.task_id,
      order_id:this.props?.location?.state?.order_id,
      bill_id:this.props?.location?.state?.bill_id,
      requisition_id:this.props?.location?.state?.requisition_id,
      entity_id:this.props?.location?.state?.entity_id,
      parcel_id:this.props?.location?.state?.parcel_id,
      container_id:this.props?.location?.state?.container_id,
      asset_name:this.props?.location?.state?.asset_name,
      task_name:this.props?.location?.state?.task_name,
      order_name:this.props?.location?.state?.order_name,
      bill_name:this.props?.location?.state?.bill_name,
      requisition_name:this.props?.location?.state?.requisition_name,
      entity_name:this.props?.location?.state?.entity_name,
      parcel_name:this.props?.location?.state?.parcel_name,
      container_name:this.props?.location?.state?.container_name,
    })
    this.getRunningTask();
    this.getReminderType();
    this.getAsset();
    this.getTask();
    this.getUser();
    this.getOrder();
    this.getParcel();
    this.getContainer();
    this.getEntity();
    this.getBill();
    this.getRequisition();
  }

  getReminderType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${REMINDER_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ reminderData: response.data.reminder_type_choices });
          console.log("reminder type", response);
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getAsset = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ASSET}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getEntity = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ENTITY}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ entityData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getBill = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_BILL}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ billData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getRequisition = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_REQUISITION}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ requisitionData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getUser = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getRunningTask = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_TASK}?assigned=true&accepted=true&started=true&completed=false&assigned_to=${
        GET_USER_LOCAL()._id.$oid
      }&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("running task", response);
          if (response.data.data.length > 0) {
            this.setState({
              task_id: response.data?.data[0]?._id?.$oid ?? "",
              asset_id: response.data?.data[0]?.assigned_asset?._id?.$oid ?? ""
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getTask = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_TASK}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ taskData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getOrder = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ORDER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ orderData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getParcel = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_PARCEL}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ parcelData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };
  getContainer = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_CONTAINER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ containerData: response.data.data });
        })
        .catch(error => {
          console.log(error);
        });
    });
  };

  setUser = value => {
    this.setState(
      { created_for_id: value.key, created_for_name: value.label },
      () => {
        console.log(this.state.created_for_name);
      }
    );
  };
  setReminderType = value => {
    this.setState({ reminder_type: value }, () => {
      console.log(this.state.reminder_type);
    });
  };
  setCustomDays = value => {
    this.setState({ custom_days: value }, () => {
      console.log(this.state.custom_days);
    });
  };
  setCustomWeeks = value => {
    this.setState({ custom_weeks: value }, () => {
      console.log(this.state.custom_weeks);
    });
  };
  setCustomMonths = value => {
    this.setState({ custom_months: value }, () => {
      console.log(this.state.custom_months);
    });
  };
  setStartDate = (value, dateString) => {
    this.setState(
      { start_date: moment(value).format("x"), start_date_view: value },
      () => {
        console.log("Selected Date: ", this.state.start_date);
      }
    );
  };
  setEndDate = (value, dateString) => {
    this.setState(
      { end_date: moment(value).format("x"), end_date_view: value },
      () => {
        console.log("Selected Date: ", this.state.end_date);
      }
    );
  };
  setReminderTime = (value, dateString) => {
    this.setState(
      { reminder_time: moment(value).format("x"), reminder_time_view: value },
      () => {
        console.log("Selected Date: ", this.state.reminder_time);
      }
    );
  };
  setAsset = value => {
    this.setState({ asset_id: value.key, asset_name: value.label }, () => {
      console.log(this.state.asset_name);
    });
  };
  setEntity = value => {
    this.setState({ entity_id: value.key, entity_name: value.label }, () => {
      console.log(this.state.entity_name);
    });
  };
  setBill = value => {
    this.setState({ bill_id: value.key, bill_name: value.label }, () => {
      console.log(this.state.bill_name);
    });
  };
  setRequisition = value => {
    this.setState({ requisition_id: value.key, requisition_name: value.label }, () => {
      console.log(this.state.requisition_name);
    });
  };
  setTask = value => {
    this.setState({ task_id: value.key, task_name: value.label }, () => {
      console.log(this.state.task_name);
    });
  };
  setOrder = value => {
    this.setState({ order_id: value.key, order_name: value.label }, () => {
      console.log(this.state.order_name);
    });
  };
  setParcel = value => {
    this.setState({ parcel_id: value.key, parcel_name: value.label }, () => {
      console.log(this.state.parcel_name);
    });
  };
  setContainer = value => {
    this.setState(
      { container_id: value.key, container_name: value.label },
      () => {
        console.log(this.state.container_name);
      }
    );
  };
  setText = evt => {
    evt.preventDefault();
    this.setState({ text: evt.target.value }, () => {
      console.log(this.state.text);
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setReason = evt => {
    evt.preventDefault();
    this.setState({ reason: evt.target.value }, () => {
      console.log(this.state.reason);
    });
  };

  createReminder = () => {
    if (
      this.state.reminder_type === "" ||
      this.state.reason === ""||
      this.state.start_date === ""||
      this.state.reminder_time === ""
    ) {
      swal.fire(
        "Warning",
        "Please enter mandatory data",
        "warning"
      );
    } else {
      const data = {
        name: this.state.name,
        reminder_type: this.state.reminder_type,
        reason: this.state.reason,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        reminder_time: this.state.reminder_time,
        custom_days: this.state.custom_days,
        custom_months: this.state.custom_months,
        custom_weeks: this.state.custom_weeks,
        asset: {
          id: this.state.asset_id,
          name: this.state.asset_name
        },
        task: {
          id: this.state.task_id,
          name: this.state.task_name
        },
        order: {
          id: this.state.order_id,
          name: this.state.order_name
        },
        parcel: {
          id: this.state.parcel_id,
          name: this.state.parcel_name
        },
        container: {
          id: this.state.container_id,
          name: this.state.container_name
        },
        entity: {
          id: this.state.entity_id,
          name: this.state.entity_name
        },
        bill: {
          id: this.state.bill_id,
          name: this.state.bill_name
        },
        requisition: {
          id: this.state.requisition_id,
          name: this.state.requisition_name
        },
        created_for: {
          id: this.state.created_for_id,
          name: this.state.created_for_name
        }
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_REMINDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            if(this.props.location.state){
              this.props.history.goBack();
            }
            console.log("response from create reminder", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create reminder", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Reminder (* Marked fields are mandatory)"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Reminder Type *</Text>
            <Select
              value={this.state.reminder_type}
              onChange={this.setReminderType}
              placeholder="Reminder Type"
            >
              {this.state.reminderData.map(r => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Reason *</Text>
            <Input
              placeholder="Reason"
              type="text"
              value={this.state.reason}
              onChange={this.setReason}
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Start Date *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  value={this.state.start_date_view}
                  onChange={this.setStartDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>End Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  value={this.state.end_date_view}
                  onChange={this.setEndDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Reminder Time *</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  inputVariant="outlined"
                  value={this.state.reminder_time_view}
                  onChange={this.setReminderTime}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          {this.state.reminder_type === "daily" ||
          this.state.reminder_type === "weekly" ||
          this.state.reminder_type === "monthly" ? (
            <Form.Item>
              <Text>Custom Days</Text>
              <Select
                value={this.state.custom_days}
                onChange={this.setCustomDays}
                mode="multiple"
                placeholder="Custom Days"
              >
                <Option key="Monday">Monday</Option>
                <Option key="Tuesday">Tuesday</Option>
                <Option key="Wednesday">Wednesday</Option>
                <Option key="Thursday">Thursday</Option>
                <Option key="Friday">Friday</Option>
                <Option key="Saturday">Saturday</Option>
                <Option key="Sunday">Sunday</Option>
              </Select>
            </Form.Item>
          ) : null}
          {this.state.reminder_type === "weekly" ||
          this.state.reminder_type === "monthly" ? (
            <Form.Item>
              <Text>Custom Weeks</Text>
              <Select
                value={this.state.custom_weeks}
                onChange={this.setCustomWeeks}
                mode="multiple"
                placeholder="Custom Weeks"
              >
                <Option key={1}>First</Option>
                <Option key={2}>Second</Option>
                <Option key={3}>Third</Option>
                <Option key={4}>Fourth</Option>
                <Option key={5}>Fifth</Option>
              </Select>
            </Form.Item>
          ) : null}
          {this.state.reminder_type === "monthly" ? (
            <Form.Item>
              <Text>Custom Months</Text>
              <Select
                value={this.state.custom_months}
                onChange={this.setCustomMonths}
                mode="multiple"
                placeholder="Custom Months"
              >
                <Option key={1}>January</Option>
                <Option key={2}>February</Option>
                <Option key={3}>March</Option>
                <Option key={4}>April</Option>
                <Option key={5}>May</Option>
                <Option key={6}>June</Option>
                <Option key={7}>July</Option>
                <Option key={8}>August</Option>
                <Option key={9}>September</Option>
                <Option key={10}>October</Option>
                <Option key={11}>November</Option>
                <Option key={12}>December</Option>
              </Select>
            </Form.Item>
          ) : null}

          {GET_USER_LOCAL().org_admin || GET_USER_LOCAL().is_staff ? (
            <Form.Item>
              <Text>Reminder For</Text>
              <Select
                labelInValue
                value={{
                  key: this.state.created_for_id,
                  label: this.state.created_for_name
                }}
                onChange={this.setUser}
                placeholder="Reminder For"
              >
                {this.state.userData.map(r => {
                  return <Option key={r._id.$oid}>{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Text>Asset</Text>
            <Select
              labelInValue
              value={{
                key: this.state.asset_id,
                label: this.state.asset_name
              }}
              onChange={this.setAsset}
              placeholder="Asset"
            >
              {this.state.assetData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Task</Text>
            <Select
              labelInValue
              value={{
                key: this.state.task_id,
                label: this.state.task_name
              }}
              onChange={this.setTask}
              placeholder="Task"
            >
              {this.state.taskData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Order</Text>
            <Select
              labelInValue
              value={{
                key: this.state.order_id,
                label: this.state.order_name
              }}
              onChange={this.setOrder}
              placeholder="Order"
            >
              {this.state.orderData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Place</Text>
            <Select
              labelInValue
              value={{
                key: this.state.entity_id,
                label: this.state.entity_name
              }}
              onChange={this.setEntity}
              placeholder="Place"
            >
              {this.state.entityData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Bill</Text>
            <Select
              labelInValue
              value={{
                key: this.state.bill_id,
                label: this.state.bill_name
              }}
              onChange={this.setBill}
              placeholder="Bill"
            >
              {this.state.billData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Requisition</Text>
            <Select
              labelInValue
              value={{
                key: this.state.requisition_id,
                label: this.state.requisition_name
              }}
              onChange={this.setRequisition}
              placeholder="Requisition"
            >
              {this.state.requisitionData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Parcel</Text>
            <Select
              labelInValue
              value={{
                key: this.state.parcel_id,
                label: this.state.parcel_name
              }}
              onChange={this.setParcel}
              placeholder="Parcel"
            >
              {this.state.parcelData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Container</Text>
            <Select
              labelInValue
              value={{
                key: this.state.container_id,
                label: this.state.container_name
              }}
              onChange={this.setContainer}
              placeholder="Container"
            >
              {this.state.containerData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createReminder}
            >
              Create Reminder
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
