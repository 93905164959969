import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";

import { CREATE_ROASTER_PLAN } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter, GET_USER_LOCAL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";


import "../login.css";

const { Column } = Table;

class RoasterPlanList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getRoasterPlanList();
    ReactGA.pageview(window.location.pathname);

  }

  getRoasterPlanList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ROASTER_PLAN}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from roaster plan list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from roaster plan list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getRoasterPlanList();
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.attendance.roaster_plan.roaster_plan_create?(
          <Link to="/createRoasterPlan">
          <Tooltip placement="top" title="Create New Roster Plan">
            <Button
              shape="circle"
              icon="plus"
              style={{
                position: "fixed",
                zIndex: 100,
                bottom: 30,
                right: 20,
                backgroundColor: "#1A3337",
                height: 60,
                width: 60,
                fontSize: 30,
                paddingBottom: 5
              }}
            />
          </Tooltip>
        </Link>
        ):null}
        
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Roster Plans"
        />

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Plan Name" dataIndex="name" key="name" />
          <Column title="Timezone Name" dataIndex="timezone_name" key="timezone_name" />

          <Column
            title="Start Time"
            render={(text, record) => <span>{record.start_time}</span>}
          />
          <Column
            title="End Time"
            render={(text, record) => <span>{record.end_time}</span>}
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          {GET_USER_LOCAL().org_admin||this.props.userProfileData.attendance.roaster_plan.roaster_plan_update?(
            <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/editRoasterPlan",
                    state: { roaster_plan_id: record._id.$oid }
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Edit
                  </Button>
                </Link>
              </span>
            )}
          />
          ):null}
          
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(RoasterPlanList);