import React from "react";
import { Form, Icon, Input, Button, Card, Select, Tabs } from "antd";
import { Link, Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import {
  LOGIN_URL,
  SEND_CODE,
  SEND_EMAIL,
  REVERSE_GEO_COUNTRY
} from "../api/constants";
import {
  LOCAL_USER_INFO,
  validateEmail,
  GET_USER
} from "../utilities/utilities";
import { Spinner } from "./loader";
import "./login.css";
import Logo from "../assets/logoblue.png";
import { countryCode } from "./countryCode";
import ReactGA from "react-ga";

const { Option } = Select;
const { TabPane } = Tabs;
// const { Footer } = Layout;
// const { Title } = Typography;

class Login extends React.Component {
  state = {
    mobile: "",
    email: "",
    password: "",
    timezone: "",
    loading: false,
    redirect: false,
    toLogin: false,
    countryCode: [],
    code: "",
    verified: true,
    verifiedEmail: true,
    width: window.innerWidth,
    height: window.innerHeight,
    country: "",
    show_password:false
  };

  componentDidMount() {
    console.log("from path", this.props.location);
    let date = new Date();
    let time = date.getTimezoneOffset();
    let token = LOCAL_USER_INFO();
    if (token) {
      this.setState({ redirect: true });
    } else {
      this.setState({
        countryCode: countryCode,
        timezone: time
      });
      this.getCountry();
    }
    const handleResize = () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth
      });
    };
    window.addEventListener("resize", handleResize);
    ReactGA.pageview(window.location.pathname);
  }
  getCountry = () => {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      axios.get(REVERSE_GEO_COUNTRY(latitude, longitude)).then(response => {
        console.log("response from reverse geo", response.data.address.country);
        this.setState({ country: response.data.address.country });
        if (response.data.address.country === "Bangladesh") {
          this.setState({ code: "+880" });
        } else if (response.data.address.country === "Nepal") {
          this.setState({ code: "+977" });
        } else if (response.data.address.country === "Australia") {
          this.setState({ code: "+61" });
        } else {
          this.setState({ code: "+61" });
        }
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };
  setCode = value => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };
  setMobile = evt => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value, email: "" });
    console.log(this.state.mobile);
  };
  setEmail = evt => {
    evt.preventDefault();
    this.setState({ email: evt.target.value, mobile: "" });
    console.log(this.state.email);
  };

  setPassword = evt => {
    evt.preventDefault();
    this.setState({ password: evt.target.value });
    console.log(this.state.password);
  };

  loginUser = event => {
    event.preventDefault();
    if (this.state.password.length < 6) {
      swal.fire("Error", "Password must be atleast 6 letters", "error");
    } else if (
      isNaN(this.state.mobile) === true &&
      validateEmail(this.state.mobile) === false
    ) {
      swal.fire(
        "Warning",
        "Please enter a valid mobile number or email",
        "warning"
      );
    } else if (
      this.state.mobile === "" &&
      this.state.email === "" &&
      this.state.password === ""
    ) {
      swal.fire(
        "Warning",
        "Please enter a valid mobile number or email and password",
        "warning"
      );
    } else if (this.state.mobile !== "" && this.state.email !== "") {
      swal.fire(
        "Warning",
        "Please only enter a valid mobile number or email",
        "warning"
      );
    } else {
      this.setState({ loading: true });
      if (this.state.mobile !== "") {
        let data = {
          login_type: "normal",
          client_id: "conveyance_android",
          client_secret: "c0nv3nappndr01d",
          grant_type: "client_credentials",
          user: this.state.code + this.state.mobile,
          password: this.state.password,
          timezone: this.state.timezone,
          scope: "",
          org_admin: true
        };

        console.log(data);

        axios({
          method: "post",
          url: LOGIN_URL,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            console.log("Coming from login", response);
            // swal.fire("Info", response.data.status, "info");
            if (response.data.status === "User not found.") {
              this.setState({ loading: false });
              swal.fire("Error", response.data.status, "error");
            } else if (response.data.status === "Password is not correct.") {
              swal.fire("Info", response.data.status, "info");
            } else if (response.data.status === "login successful") {
              if (response.data.user.mobile_verified === false) {
                swal.fire("Error", "Mobile not verified!", "error");
                response.data.Generated_time = Date.now();
                localStorage.setItem(
                  "conveyanceUser",
                  JSON.stringify(response.data)
                );

                axios({
                  method: "get",
                  url: `${SEND_CODE}?access_token=${response.data.access_token}`
                })
                  .then(response => {
                    console.log("response from code api", response);
                  })
                  .catch(error => {
                    console.log("error in send code", error);
                  });

                this.setState({ verified: false });
              } else {
                response.data.Generated_time = Date.now();
                localStorage.setItem(
                  "conveyanceUser",
                  JSON.stringify(response.data)
                );

                GET_USER();
                this.setState({ loading: false, redirect: true });
              }
            }
          })
          .catch(error => {
            this.setState({ loading: false });
            console.error("Error Happened:", error);

            swal.fire("Error", error.message, "error");
          });
      } else if (this.state.email !== "") {
        let data = {
          login_type: "normal",
          client_id: "conveyance_android",
          client_secret: "c0nv3nappndr01d",
          grant_type: "client_credentials",
          user: this.state.email,
          password: this.state.password,
          timezone: this.state.timezone,
          scope: "",
          org_admin: true
        };

        console.log(data);

        axios({
          method: "post",
          url: LOGIN_URL,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            console.log("Coming from login", response);
            this.setState({ loading: false });
            // swal.fire("Info", response.data.status, "info");
            if (response.data.status === "User not found.") {
              this.setState({ loading: false });
              swal.fire("Error", response.data.status, "error");
            } else if (response.data.status === "Password is not correct.") {
              this.setState({ loading: false });
              swal.fire("Error", response.data.status, "error");
            } else if (response.data.status === "login successful") {
              if (response.data.user.email_verified === false) {
                swal.fire("Error", "Email not verified!", "error");
                response.data.Generated_time = Date.now();
                localStorage.setItem(
                  "conveyanceUser",
                  JSON.stringify(response.data)
                );

                axios({
                  method: "get",
                  url: `${SEND_EMAIL}?access_token=${response.data.access_token}`
                })
                  .then(response => {
                    console.log("response from code api", response);
                  })
                  .catch(error => {
                    console.log("error in send code", error);
                  });

                this.setState({ verifiedEmail: false });
              } else {
                response.data.Generated_time = Date.now();
                localStorage.setItem(
                  "conveyanceUser",
                  JSON.stringify(response.data)
                );
                GET_USER();
                this.setState({ loading: false, redirect: true });
              }
            }
          })
          .catch(error => {
            this.setState({ loading: false });
            console.error("Error Happened:", error);

            swal.fire("Error", error.message, "error");
          });
      }
    }
  };
  render() {
    if (this.state.redirect === true) {
      return (
        <Redirect
          to={
            this.props.location.state &&
            this.props.location.state.from !== "/logout"
              ? this.props.location.state.from
              : "/home"
          }
        />
      );
    } else if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.toLogin === true) {
      return <Redirect to="/" />;
    } else if (this.state.verified === false) {
      return (
        <Redirect
          to={{
            pathname: "/verifyAccount",
            state: { mobile: this.state.code + this.state.mobile }
          }}
        />
      );
    } else if (this.state.verifiedEmail === false) {
      return (
        <Redirect
          to={{
            pathname: "/verifyAccountEmail",
            state: { email: this.state.email }
          }}
        />
      );
    }
    return (
      <div
        style={{
          width: this.state.width,
          height: this.state.height
        }}
      >
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          <a href="https://www.conveyance.app/">
            <img
              src={Logo}
              alt=""
              style={{
                width: 350,
                position: "relative",
                top: 40
              }}
            />
          </a>
        </div>
        <div style={{ display: "flex" }}>
          {/* {this.state.width < 1000 ? null : (
            <div style={{ paddingLeft: 40 }}>
              <Title
                style={{
                  color: "#faa61a",
                  fontSize: 70,
                  textShadow: "3px 3px #9E9E9E"
                }}
              >
                Supply Chain Made Easy
              </Title>
              <Title
                level={3}
                style={{ color: "white", textShadow: "1px 1px #9E9E9E" }}
              >
                Conveyance is a service that makes supply chain easy & efficient
                <br />
                by providing a platform and powerful tools to manage assets,
                <br /> resources and tasks .{" "}
              </Title>
            </div>
          )} */}

          <Card
            title="You can sign in here"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 15,
              position: "relative",
              top: 50,
              boxShadow: "15px 10px 20px -4px rgba(0,0,0,0.72)"
            }}
          >
            <Form className="login-form">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Login With Mobile" key="1">
                  <Form.Item>
                    <Select
                      value={this.state.code}
                      onChange={this.setCode}
                      placeholder="Select Coode"
                    >
                      {this.state.countryCode.map(r => {
                        return (
                          <Option key={r.dial_code}>
                            {r.name} {r.dial_code}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Input
                      type="number"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Mobile"
                      value={this.state.mobile}
                      onChange={this.setMobile}
                      // style={{display:"flex",flex:1}}
                    />
                  </Form.Item>
                  <Form.Item>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type={this.state.show_password ? "text" : "password"}
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.setPassword}
                    />
                    <Button
                      onClick={() =>
                        this.setState({
                          show_password: !this.state.show_password,
                        })
                      }
                    >
                      <Icon
                        type={
                          this.state.show_password ? "eye" : "eye-invisible"
                        }
                        style={{
                          marginTop: 3,
                          fontSize: 18,
                          color: "#7B8FA1",
                        }}
                      />
                    </Button>
                    </div>
                  </Form.Item>
                </TabPane>
                <TabPane tab="Login With Email" key="2">
                  <Form.Item>
                    <Input
                      type="text"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.setEmail}
                    />
                  </Form.Item>
                  <Form.Item>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type={this.state.show_password ? "text" : "password"}
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.setPassword}
                    />
                    <Button
                      onClick={() =>
                        this.setState({
                          show_password: !this.state.show_password,
                        })
                      }
                    >
                      <Icon
                        type={
                          this.state.show_password ? "eye" : "eye-invisible"
                        }
                        style={{
                          marginTop: 3,
                          fontSize: 18,
                          color: "#7B8FA1",
                        }}
                      />
                    </Button>
                    </div>
                  </Form.Item>
                </TabPane>
              </Tabs>

              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.loginUser}
                >
                  Sign in
                </Button>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Link
                      to="/signup"
                      style={{ color: "#faa61a", marginRight: 200 }}
                    >
                      Mobile Sign up
                    </Link>
                    <Link to="/signupEmail" style={{ color: "#faa61a" }}>
                      Email Sign up
                    </Link>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Link to="/forgetPassword" style={{ color: "#faa61a" }}>
                      Forgot Password
                    </Link>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </Card>
        </div>
        {/* {window.innerWidth<1000?null:(
           <Footer
           style={{
             textAlign: "center",
             backgroundColor: "#006479",
             width:"100%",
             position: "fixed",
             bottom:0,
             opacity:0.8
           }}
         >
           <div
             style={{
               display: "flex",
               flexDirection: "column",
               alignSelf: "center",
               justifyItems: "center"
             }}
           >
             <a href="https://www.conveyance.app/">
               <img
                 src={Logo}
                 alt=""
                 style={{ width: 160, height: 41, alignSelf: "center" }}
               />
             </a>
             <Text style={{ color: "white", fontSize: 15 }}>
               Monico Technologies LTD
             </Text>
             <Text style={{ color: "white", fontSize: 15 }}>
               26-27, Ringroad Adabor, Dhaka
             </Text>
             <Text style={{ color: "#b0bec5", fontSize: 15 }}>
               © All rights reserved by Monico Technologies LTD, 2020
             </Text>
           </div>
           </Footer>
        )} */}
      </div>
    );
  }
}

export default Login;
