import { SAVE_USER_PROFILE, API_ERROR } from "../actions/types";

const initialState = {
  userProfileData: {
    asset: {
      asset_create: false,
      asset_list: false,
      asset_update: false,
      asset_detail: false,
      asset_delete: false,
      add_reminder: false,
      connect_asset_to_user: false,
      connect_asset_to_finder_device: false,
      connect_to_finder: false
    },
    user: {
      user_create: true,
      user_list: true,
      user_update: true,
      user_detail: true,
      user_delete: true,
      view_user_route_history: true,
      connect_user_to_org: true,
      bulk_update_user: true,
      user_mobile_heartbeat_logs: true,
      assigned_role_to_users: true
    },
    roles_and_permission: {
      roles_and_permission_create: false,
      roles_and_permission_list: false,
      roles_and_permission_update: false,
      roles_and_permission_detail: false,
      roles_and_permission_delete: false,
      add_or_update_permissions_to_roles: false,
      view_permissions_against_roles: false,
      view_users_against_roles: false
    },
    place: {
      place_create: false,
      place_list: false,
      place_update: false,
      place_detail: {
        update_custom_field_data_related_to_place: false,
        view_custom_field_data_related_to_place: false
      },
      place_delete: false,
      add_place_reminder: false,
      nearby_place_search: false
    },
    attendance: {
      roaster_plan: {
        roaster_plan_create: false,
        roaster_plan_list: false,
        roaster_plan_update: false,
        roaster_plan_detail: false,
        roaster_delete: false
      },
      roaster: {
        roaster_create: false,
        roaster_list: false,
        roaster_update: false,
        roaster_detail: false,
        delete_user_from_roaster: false,
        roaster_delete: false
      },
      attendance: {
        attendance_create: false,
        attendance_create_for: false,
        attendance_checkin: false,
        attendance_checkout: false,
        attendance_list: false,
        attendance_detail: false,
        attendance_delete: false,
        attendance_view_route: false
      },
      quick_attendance: {
        quick_attendance_create: false,
        quick_attendance_checkin: false,
        quick_attendance_checkout: false,
        quick_attendance_list: false,
        quick_attendance_update: false,
        quick_attendance_detail: false,
        quick_attendance_delete: false,
        quick_attendance_view_route: false
      },
      leave_application: {
        leave_application_create: false,
        leave_application_list: false,
        leave_application_update: false,
        leave_application_detail: false,
        leave_application_delete: false
      }
    },
    task: {
      task_type: {
        task_type_create: false,
        task_type_list: false,
        task_type_update: false,
        task_type_detail: false,
        task_type_delete: false
      },
      route: {
        route_create: false,
        route_list: false,
        route_update: false,
        route_detail: false,
        route_delete: false
      },
      task: {
        task_create: false,
        task_list: false,
        task_update: false,
        task_detail: {
          add_comment: false,
          view_comment_list: false,
          update_comment: false,
          delete_comment: false,
          update_custom_field_data_related_to_task: false,
          view_custom_field_data_related_to_task: false
        },
        task_delete: false,
        task_view_route: false,
        task_add_reminder: false
      }
    },
    order_and_delivery: {
      unit: {
        unit_create: false,
        unit_list: false,
        unit_update: false,
        unit_detail: false,
        unit_delete: false
      },
      product: {
        product_create: false,
        product_list: false,
        product_update: false,
        product_detail: false,
        product_delete: false
      },
      territory: {
        territory_create: false,
        territory_list: false,
        territory_update: false,
        territory_detail: false,
        territory_delete: false
      },
      order: {
        order_create: false,
        order_list: false,
        order_update: false,
        order_detail: {
          change_delivery_time: false,
          order_product_status_update: false,
          order_location_update: false,
          add_received_amount: false,
          add_refund_amount: false,
          update_custom_field_data_related_to_order: false,
          view_custom_field_data_related_to_order: false
        },
        order_delete: false,
        order_add_reminder: false,
        download_order_detailed_to_order: false,
        order_related_payment_method_and_status_update: false,
        order_payment_method_and_status_update_from_task: false,
        order_payment_method_choices: false,
        order_discount_type_choices: false,
        order_item_update: false,
        generate_task_from_order: false,
        generate_multi_task_from_selected_order: false,
        generate_chain_task_from_selected_order: false,
        generate_route_suggestion_from_selected_order: false
      }
    },
    bill: {
      supplier: {
        supplier_create: false,
        supplier_list: false,
        supplier_update: false,
        supplier_detail: false,
        supplier_delete: false
      },
      item: {
        item_create: false,
        item_list: false,
        item_update: false,
        item_detail: false,
        item_delete: false,
        get_all_items: false
      },
      item_type: {
        item_type_create: false,
        item_type_list: false,
        item_type_update: false,
        item_type_detail: false,
        item_type_delete: false
      },
      bill: {
        bill_create: false,
        bill_list: false,
        bill_list_search: false,
        bill_update: false,
        bill_detail: false,
        bill_type_list: false,
        conveyance_bill_approval_api: false,
        bill_item_udpade: false,
        get_miscellaneous_bill_type_choices: false
      }
    },
    requisition: {
      requisition_create: false,
      requisition_list: false,
      requisition_list_search: false,
      requisition_update: false,
      requisition_detail: false,
      requisition_delete: false,
      generate_bill_from_requisition: false,
      generate_order_from_requisition: false
    },
    visit_plan: {
      visit_plan_create: false,
      visit_plan_list: false,
      visit_plan_update: false,
      visit_plan_detail: false,
      visit_plan_delete: false,
      generate_chain_task_from_selected_plan: false
    },
    incident: {
      incident_create: false,
      incident_list: false,
      incident_update: false,
      incident_detail: false,
      incident_delete: false
    },
    procedure: {
      procedure_create: false,
      procedure_list: false,
      procedure_update: false,
      procedure_detail: false,
      procedure_delete: false
    },
    custom_field: {
      custom_fields_create: false,
      custom_fields_list: false,
      custom_fields_update: false,
      custom_fields_detail: false,
      custom_fields_delete: false
    },
    organization: {
      organization_profile: {
        organization_create: true,
        organization_list: false,
        organization_update: false,
        organization_detail: false,
        organization_delete: false,
        add_plan_subscription: false,
        change_plan_subscription: false,
        monthly_billing_view: false,
        monthly_payment_view: false
      },
      change_organization: false
    },
    organizational_setting: {
      attendance_settings: false,
      inventory_connection_settings: false,
      tracking_settings: false,
      subscription_billing_settings: false
    },
    report: {
      attendance_report: {
        attendance_organizational_report: false,
        attendance_organizational_report_with_custom_fields: false,
        single_person_attendance_report: false,
        leave_organizational_report: false
      },
      task_report: {
        daily_task_report: false,
        task_report_based_on_filters: false,
        task_report_based_on_users: false
      },
      order_report: false,
      asset_report: false
    },
    data_level: {
      access_all_data: false,
      access_suborg_data: false,
      access_own_data: true
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_PROFILE:
      return {
        ...state,
        userProfileData: action.payload
      };

    case API_ERROR:
      return state;
    default:
      return state;
  }
}
