import React, { Component } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Table,
  Divider,
  Input,
  Pagination,
  Icon,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { savePurchaseOrderListParams } from "../../redux/actions/purchaseOrderListParamsAction";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { EDIT_ORDER, PURCHASE_ORDER_LIST } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  //   GET_USER_LOCAL,
} from "../../utilities/utilities";
import ReactGA from "react-ga";

import "../login.css";
import { debounce } from "lodash";

const { Option } = Select;
const { Text,Title } = Typography;
const { Column } = Table;

class PurchaseOrderList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    currentTime: "",
    timezone_name: "",
    order_number: "",
    name: "",
    details: "",
    drop_contact_no: "",
    drop_contact_name: "",
    start_loc_name: "",
    end_loc_name: "",
    bill_amount: "",
    received_amount: "",
    due_amount: "",
    start_max_distance: "",
    end_max_distance: "",
    start_time: "",
    start_time_view: moment(),
    end_time: Date.now(),
    end_time_view: moment(),
    extended_report_from: Date.now() / 1000,
    extended_report_from_view: moment(),
    extended_report_to: Date.now() / 1000,
    extended_report_to_view: moment(),
    summary_type: "order",
    delivered: "",
    cancelled: "",
    rescheduled: "",
    search_date_time_type: "created_on",
    datarange: "",
    sort_by: "created_on",
    sort_type: "dsc",
    created_by_name: "",
    created_on: null,
    modified_by_name: "",
    modified_on: null,
    refundable_amount: "",
    total_received_amount: "",
    total_refunded_amount: "",
    order_ids: [],
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_order_search: "",
    show_excel_loading_button: false,
    show_pdf_loading_button: false,
    sub_org_name: "",
    inventory_entity_name: "",
    sub_org_parent_name: "",
    order_status: "",
    see_more_details: false,
    details_text: "",
    organization_name: "",
  };
  componentDidMount() {
    this.props.getUserData();
    ReactGA.pageview(window.location.pathname);

    console.log("redux", this.props.purchaseOrderListParams);
    if (Object.keys(this.props.purchaseOrderListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          order_number: this.props?.purchaseOrderListParams?.order_number ?? "",
          organization_name:
            this.props?.purchaseOrderListParams?.organization_name ?? "",
          name: this.props?.purchaseOrderListParams?.name ?? "",
          inventory_entity_name:
            this.props?.purchaseOrderListParams?.inventory_entity_name ?? "",
          sub_org_parent_name:
            this.props?.purchaseOrderListParams?.sub_org_parent_name ?? "",
          order_status: this.props?.purchaseOrderListParams?.order_status ?? "",
          sub_org_name: this.props?.purchaseOrderListParams?.sub_org_name ?? "",
          created_by_name:
            this.props?.purchaseOrderListParams?.created_by_name ?? "",
          created_on:
            this.props?.purchaseOrderListParams?.created_on?.$date ?? null,
          modified_by_name:
            this.props?.purchaseOrderListParams?.modified_by_name ?? "",
          modified_on:
            this.props?.purchaseOrderListParams?.modified_on?.$date ?? null,
          details: this.props?.purchaseOrderListParams?.details ?? "",
          drop_contact_no:
            this.props?.purchaseOrderListParams?.drop_contact_no ?? "",
          drop_contact_name:
            this.props?.purchaseOrderListParams?.drop_contact_name ?? "",
          start_loc_name:
            this.props?.purchaseOrderListParams?.start_loc_name ?? "",
          end_loc_name: this.props?.purchaseOrderListParams?.end_loc_name ?? "",
          bill_amount: this.props?.purchaseOrderListParams?.bill_amount ?? "",
          received_amount:
            this.props?.purchaseOrderListParams?.received_amount ?? "",
          due_amount: this.props?.purchaseOrderListParams?.due_amount ?? "",
          refundable_amount:
            this.props?.purchaseOrderListParams?.refundable_amount ?? "",
          total_received_amount:
            this.props?.purchaseOrderListParams?.total_received_amount ?? "",
          total_refunded_amount:
            this.props?.purchaseOrderListParams?.total_refunded_amount ?? "",
          start_max_distance:
            this.props?.purchaseOrderListParams?.start_max_distance ?? "",
          end_max_distance:
            this.props?.purchaseOrderListParams?.end_max_distance ?? "",
          start_time: this.props?.purchaseOrderListParams?.start_time ?? "",
          start_time_view:
            this.props?.purchaseOrderListParams?.start_time_view ?? moment(),
          end_time: this.props?.purchaseOrderListParams?.end_time ?? Date.now(),
          end_time_view:
            this.props?.purchaseOrderListParams?.end_time_view ?? moment(),
          delivered: this.props?.purchaseOrderListParams?.delivered ?? "",
          cancelled: this.props?.purchaseOrderListParams?.cancelled ?? "",
          rescheduled: this.props?.purchaseOrderListParams?.rescheduled ?? "",
          search_date_time_type:
            this.props?.purchaseOrderListParams?.search_date_time_type ??
            "delivery_date_time",
          limit: this.props?.purchaseOrderListParams?.limit ?? 10,
          page: this.props?.purchaseOrderListParams?.page ?? 0,
          sort_by: this.props?.purchaseOrderListParams?.sort_by ?? "created_by",
          sort_type: this.props?.purchaseOrderListParams?.sort_type ?? "dsc",
        },
        () => {
          this.getOrderList();
        }
      );
    } else {
      this.getOrderList();
    }
  }

  getOrderList = debounce(() => {
    let reduxData = {
      order_number: this.state.order_number,
      organization_name: this.state.organization_name,
      name: this.state.name,
      sub_org_parent_name: this.state.sub_org_parent_name,
      order_status: this.state.order_status,
      inventory_entity_name: this.state.inventory_entity_name,
      sub_org_name: this.state.sub_org_name,
      created_by_name: this.state.created_by_name,
      created_on: this.state.created_on,
      modified_by_name: this.state.modified_by_name,
      modified_on: this.state.modified_on,
      details: this.state.details,
      drop_contact_no: this.state.drop_contact_no,
      drop_contact_name: this.state.drop_contact_name,
      start_loc_name: this.state.start_loc_name,
      bill_amount: this.state.bill_amount,
      received_amount: this.state.received_amount,
      due_amount: this.state.due_amount,
      refundable_amount: this.state.refundable_amount,
      total_received_amount: this.state.total_received_amount,
      total_refunded_amount: this.state.total_refunded_amount,
      start_max_distance: this.state.start_max_distance,
      end_max_distance: this.state.end_max_distance,
      end_loc_name: this.state.end_loc_name,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
      delivered: this.state.delivered,
      cancelled: this.state.cancelled,
      rescheduled: this.state.rescheduled,
      search_date_time_type: this.state.search_date_time_type,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      limit: this.state.limit,
      page: this.state.page,
    };
    this.props.savePurchaseOrderListParams(reduxData);
    console.log("reduxdata going in", reduxData);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${PURCHASE_ORDER_LIST}?organization_name=${this.state.organization_name}&page=${this.state.page}&limit=${this.state.limit}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&order_status=${this.state.order_status}&search_date_time_type=created_on&order_number=${this.state.order_number}&end_loc_name=${this.state.end_loc_name}&start_loc_name=${this.state.start_loc_name}&drop_contact_name=${this.state.drop_contact_name}&drop_contact_no=${this.state.drop_contact_no}&details=${this.state.details}&name=${this.state.name}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from purchase order list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from purchase order list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        order_number: "",
        organization_name: "",
        name: "",
        sub_org_parent_name: "",
        order_status: "",
        inventory_entity_name: "",
        sub_org_name: "",
        created_by_name: "",
        created_on: null,
        modified_by_name: "",
        modified_on: null,
        details: "",
        drop_contact_no: "",
        drop_contact_name: "",
        start_loc_name: "",
        end_loc_name: "",
        bill_amount: "",
        received_amount: "",
        due_amount: "",
        refundable_amount: "",
        total_received_amount: "",
        total_refunded_amount: "",
        start_max_distance: "",
        end_max_distance: "",
        start_time: "",
        delivered: "",
        cancelled: "",
        rescheduled: "",
        sort_by: "created_on",
        sort_type: "dsc",
        search_date_time_type: "created_on",
        start_time_view: moment(),
        end_time: Date.now(),
        end_time_view: moment(),
        page: 0,
        limit: 10,
      },
      () => {
        this.getOrderList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getOrderList();
    });
  };

  addTo = (record) => {
    let addedArray = [];
    addedArray.push(record._id.$oid);
    this.setState(
      {
        order_ids: [...this.state.order_ids, ...addedArray],
      },
      () => {
        console.log("added data", this.state.order_ids);
      }
    );
  };

  removefrom = (record) => {
    let addedFiltered = this.state.order_ids.filter(
      (m) => m !== record._id.$oid
    );
    this.setState({ order_ids: addedFiltered }, () => {
      console.log("added data", this.state.order_ids);
    });
  };

  deleteTask = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ORDER}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };
  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getOrderList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10, minWidth: 1000 }}>
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select daterange within which you would like to find your purchase orders
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).add(59, "seconds").format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
              }}
            >
              <Text style={{ margin: 5 }}>Filter Time By : </Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getOrderList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="delivery_date_time">Delivery date time</Option>
                <Option key="modified_on">Modified On</Option>
                <Option key="placed_on">Placed on</Option>
              </Select>
            </div>  */}
          </div>
        </Card>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Order Number</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "order_number", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "order_number", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.order_number}
                  onChange={(evt) => {
                    this.setState(
                      { order_number: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="order_number"
            render={(text, record) => (
              <span>{`${record?.order_number ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Order Name</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getOrderList();
                    });
                  }}
                />
              </>
            }
            key="name"
            render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Organization Name</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "organization_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "organization_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.organization_name}
                  onChange={(evt) => {
                    this.setState(
                      { organization_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="organization_name"
            render={(text, record) => (
              <span>{`${record?.organization_name ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Created By</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="created_by_name"
            render={(text, record) => <span>{record.created_by_name}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Created On</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_on", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="created_on.$date"
            render={(text, record) => (
              <span>
                {record.created_on.$date
                  ? timeConverter(record.created_on.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Details</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "details", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "details", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.details}
                  onChange={(evt) => {
                    this.setState(
                      { details: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(record) => (
              <span>
                {record.details && record.details.length > 30 ? (
                  <Text>
                    {record.details.slice(0, 10)}
                    <Button
                      type="link"
                      onClick={() =>
                        this.setState({
                          see_more_details: true,
                          details_text: record.details,
                        })
                      }
                    >
                      See More
                    </Button>
                  </Text>
                ) : (
                  <Text>{record.details}</Text>
                )}
              </span>
            )}
            key="description"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Drop Contact Name</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.drop_contact_name}
                  onChange={(evt) => {
                    this.setState(
                      { drop_contact_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record?.drop_contact_name ?? ""}</span>
            )}
            key="drop_contact_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Drop Contact Number</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_no", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "drop_contact_no", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.drop_contact_no}
                  onChange={(evt) => {
                    this.setState(
                      { drop_contact_no: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            key="drop_contact_no"
            render={(text, record) => (
              <span>{record?.drop_contact_no ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Delivery Location</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_loc_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "end_loc_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.end_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { end_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="end_loc_name"
            key="end_loc_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Delivery Date Time</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "delivery_date_time", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "delivery_date_time", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="delivery_date_time"
            render={(text, record) => (
              <span>
                {record.delivery_date_time.$date
                  ? timeConverter(record.delivery_date_time.$date)
                  : ""}
              </span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Pickup Location</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_loc_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "start_loc_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.start_loc_name}
                  onChange={(evt) => {
                    this.setState(
                      { start_loc_name: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record?.start_entity?.address ?? ""}</span>
            )}
            key="start_loc_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Inventory Entity Name</div>
                </div>
              </>
            }
            render={(text, record) => (
              <span>{record?.inventory_entity_name ?? ""}</span>
            )}
            key="inventory_entity_name"
          />
          <Column
            title={
              <>
                <div>Sub Org Name</div>
              </>
            }
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
            key="sub_org_name"
          />
          <Column
            title={
              <>
                <div>Sub Org Parent Name</div>
              </>
            }
            render={(text, record) => (
              <span>{record.sub_org?.parent_sub_org_data?.name ?? ""}</span>
            )}
            key="sub_org_parent_name"
          />

          <Column
            title={<>Sub Org Type</>}
            render={(text, record) => (
              <span>{record.sub_org?.sub_org_type_data?.name ?? ""}</span>
            )}
            key="sub_org_type"
          />
          <Column
            title={
              <>
                <div>Order Status</div>

                <Input
                  value={this.state.order_status}
                  onChange={(evt) => {
                    this.setState(
                      { order_status: evt.target.value, page: 0 },
                      () => {
                        this.getOrderList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record.order_status?.name ?? ""}</span>
            )}
            key="order_status"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Bill Amount</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_amount", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_amount", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                {/* <Input
                  value={this.state.bill_amount}
                  onChange={evt => {
                    this.setState(
                      { bill_amount: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                /> */}
              </>
            }
            key="bill_amount"
            render={(text, record) => (
              <span>{`${record?.bill_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Total Received Amount</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_received_amount",
                            sort_type: "asc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_received_amount",
                            sort_type: "dsc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="total_received_amount"
            render={(text, record) => (
              <span>{`${record?.total_received_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Refundable Amount</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "refundable_amount", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "refundable_amount", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="refundable_amount"
            render={(text, record) => (
              <span>{`${record?.refundable_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Total Refunded Amount</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_refunded_amount",
                            sort_type: "asc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_refunded_amount",
                            sort_type: "dsc",
                          },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="total_refunded_amount"
            render={(text, record) => (
              <span>{`${record?.total_refunded_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Due Amount</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "due_amount", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "due_amount", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                {/* <Input
                  value={this.state.due_amount}
                  onChange={evt => {
                    this.setState(
                      { due_amount: evt.target.value, page: 0 },
                      () => {
                        setTimeout(() => this.getOrderList(), 2000);
                      }
                    );
                  }}
                /> */}
              </>
            }
            key="due_amount"
            render={(text, record) => (
              <span>{`${record?.due_amount ?? ""}`}</span>
            )}
          />
          <Column
            title={<>Paid</>}
            render={(text, record) => (
              <span>{record.paid ? record.paid.toString() : "false"}</span>
            )}
            key="paid"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Modified By</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="modified_by_name"
            render={(text, record) => (
              <span>{record.modified_by_name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Modified On</div>
                  <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_on", sort_type: "asc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_on", sort_type: "dsc" },
                          () => {
                            this.getOrderList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </>
            }
            key="modified_on.$date"
            render={(text, record) => (
              <span>
                {record.modified_on.$date
                  ? timeConverter(record.modified_on.$date)
                  : ""}
              </span>
            )}
          />
          {this.props.userProfileData.order_and_delivery.order
            .purchase_order_detail ? (
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  <Link
                    to={{
                      pathname: "/viewOrder",
                      state: { order_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                </span>
              )}
            />
          ) : null}
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          destroyOnClose={true}
          footer={null}
          visible={this.state.see_more_details}
          onCancel={() => {
            this.setState({
              see_more_details: false,
              details_text: "",
            });
          }}
          closable={true}
        >
          <Text style={{ margin: 10 }}>{this.state.details_text}</Text>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  purchaseOrderListParams:
    state.purchaseOrderListParams.purchaseOrderListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  savePurchaseOrderListParams,
  getUserData,
})(PurchaseOrderList);
