import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_PRODUCT_CATEGORY,
  EDIT_PRODUCT_CATEGORY
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditProductCategory extends Component {
  state = {
    name: "",
    product_category_prefix: "",
    parent_category_id: "",
    parent_category_name: "",
    parent_categories: [],
    product_category_id: ""
  };

  componentDidMount() {
    this.setState({
      product_category_id: this.props.location.state.product_category_id
    });
    this.getProductCategory();
    this.getProductCategoryDetails();
    ReactGA.pageview(window.location.pathname);
  }

  getProductCategoryDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let product_category_id = this.props.location.state.product_category_id;
      let url = `${EDIT_PRODUCT_CATEGORY}${product_category_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit product category", response);
        this.setState({
          name: response.data?.data?.name ?? "",
          product_category_prefix:
            response.data?.data?.product_category_prefix ?? "",
          parent_category_id: response.data?.data?.parent_category_id ?? "",
          parent_category_name: response.data?.data?.parent_category_name ?? ""
        });
      });
    });
  };

  getProductCategory = () => {
    let product_category_id = this.props.location.state.product_category_id;
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PRODUCT_CATEGORY}?editing_product_category_id=${product_category_id}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ parent_categories: response.data.data });
          console.log("data from product category list api", response);
        })
        .catch(error => {
          console.log("error from product category list api", error);
        });
    });
  };

  setParentCategory = value => {
    this.setState(
      { parent_category_id: value.key, parent_category_name: value.label },
      () => {
        console.log(this.state.parent_category_id);
      }
    );
  };

  setPrefix = evt => {
    evt.preventDefault();
    this.setState({ product_category_prefix: evt.target.value }, () => {
      console.log(this.state.product_category_prefix);
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  editProductCategory = () => {
    const data = {
      name: this.state.name,
      product_category_prefix: this.state.product_category_prefix,
      parent_category_id: this.state.parent_category_id,
      parent_category_name: this.state.parent_category_name
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_PRODUCT_CATEGORY}${this.state.product_category_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit product category", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from edit product category", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Edit Product Category"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Prefix</Text>
            <Input
              placeholder="Prefix"
              type="text"
              value={this.state.product_category_prefix}
              onChange={this.setPrefix}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Parent Category</Text>
            <Select
              labelInValue
              value={{
                key: this.state.parent_category_id,
                label: this.state.parent_category_name
              }}
              onChange={this.setParentCategory}
            >
              {this.state.parent_categories.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editProductCategory}
            >
              Update Product Category
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
