import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";

import { CREATE_INCIDENT } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";


import "../login.css";

const { Column } = Table;

class IncidentList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getIncidentList();
    ReactGA.pageview(window.location.pathname);

  }

  getIncidentList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_INCIDENT}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from incident list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from incident list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getIncidentList();
      }
    );
  };

//   deleteProduct = id => {
//     this.setState({ loading: true });
//     GET_ACCESS_TOKEN().then(token => {
//       const url = `${EDIT_PRODUCT}${id}?access_token=${token}`;

//       axios({
//         method: "delete",
//         url: url
//       })
//         .then(response => {
//           this.setState({ loading: false });
//           swal.fire("Success", response.data.message, "success");
//           setTimeout(() => window.location.reload(), 1000);
//           console.log("deleted", response);
//         })
//         .catch(error => {
//           this.setState({ loading: false });
//           swal.fire("Error", error, "error");
//           console.log("error from del", error);
//         });
//     });
//   };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.incident.incident_create?(
           <Link to="/createIncident">
           <Tooltip placement="top" title="Create New Incident">
             <Button
               shape="circle"
               icon="plus"
               style={{
                 position: "fixed",
                 zIndex: 100,
                 bottom: 30,
                 right: 20,
                 backgroundColor: "#1A3337",
                 height: 60,
                 width: 60,
                 fontSize: 30,
                 paddingBottom: 5
               }}
             />
           </Tooltip>
         </Link>
        ):null}
       
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Incidents"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Reason" dataIndex="reason" key="reason" />
          <Column title="Incident Type" dataIndex="incident_type" key="incident_type" />
          <Column
            title="Created By/For"
            key="created_for"
            render={(text, record) => (
              <span>{record?.created_for?.name??""}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.incident.incident_update?(
                  <Link
                  to={{
                    pathname: "/editIncident",
                    state: { incident_id: record._id.$oid }
                  }}
                >
                  <Button
                    type="link"
                    style={{ color: "green" }}
                  >
                    Edit
                  </Button>
                </Link>
                ):null}
                
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(IncidentList);