import React, { Component } from "react";
import { Card, Typography, PageHeader,Button } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_CONTAINER,CONTAINER_TAG_PRINT } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter,GET_USER_LOCAL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";


import "../login.css";

const { Text } = Typography;
export default class ContainerDetails extends Component {
  state = {
    name: "",
    loc_lat: 23.685,
    loc_lon: 90.3563,
    cl: null,
    cw: null,
    ch: null,
    unit_dim: null,
    unit_weight: null,
    max_weight: null,
    gross_weight: null,
    asset: "",
    assets: [],
    device_id: "",
    connect_key: "",
    container_id: "",
    created_on: "",
    pin: "",
    loading: false,
    org_id: ""
  };

  componentDidMount() {
    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.getContainerDetails();
    ReactGA.pageview(window.location.pathname);

  }

  getContainerDetails = () => {
    this.setState({ container_id: this.props.location.state.container_id });

    GET_ACCESS_TOKEN().then(token => {
      let container_id = this.props.location.state.container_id;
      let url = `${EDIT_CONTAINER}${container_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro container view", response.data.data);
        this.setState({
          name: response.data.data.name,
          cl: response.data.data.cl,
          cw: response.data.data.cw,
          ch: response.data.data.ch,
          unit_dim: response.data.data.unit_dim,
          unit_weight: response.data.data.unit_weight,
          max_weight: response.data.data.max_weight,
          gross_weight: response.data.data.gross_weight,
          created_on: response.data.data.created_on.$date,
          asset: response.data.data.asset,
          pin:response.data.data.pin
        });
      });
    });
  };

  containerTagprint = (item_id, item) => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CONTAINER_TAG_PRINT}?container_id=${this.state.container_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log("response from container print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.container_tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from container print list fmt", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="View Container"
        />
        <Card className="view">
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Container Name</Text>:{" "}
            {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Container Width</Text>:{" "}
            {this.state.cw} Feet
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Container Height</Text>:{" "}
            {this.state.ch} Feet
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Container Length</Text>:{" "}
            {this.state.cl} Feet
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Unit Dimension</Text>:{" "}
            {this.state.unit_dim}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Unit Weight</Text>:{" "}
            {this.state.unit_weight}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Gross Weight</Text>:{" "}
            {this.state.gross_weight}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Container PIN</Text>:{" "}
                {this.state.pin} 
              </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Asset</Text>:{" "}
            {this.state.asset}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
          <Button
            onClick={this.containerTagprint}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Print Tag
          </Button>
        </Card>
      </Card>
    );
  }
}
