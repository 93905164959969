import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Radio,
  Checkbox,
  Tag,
  Icon,
  Table,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
// import moment from "moment";
import {
  EDIT_BILL,
  CREATE_CUSTOM_FIELD,
  ADD_CUSTOM_FIELDS_TO_TASK,
  MULTI_IMAGE_UPLOAD,
  CREATE_PROCEDURE,
  BILL_APPROVAL,
  APPROVAL_SYSTEM_CHOICES,
  BILL_ITEMS_EDIT,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  isValidURL,
  GET_USER_LOCAL,
  randomId,
  timeConverter,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

class ViewBill extends Component {
  state = {
    name: "",
    bill_id: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    created_by_name: "",
    created_for_name: "",
    created_for_mobile: "",
    created_on: "",
    qty: 0,
    unit: "",
    contact_no: "",
    supplier_name: "",
    supplier_name_key: "",
    rcmnd_price: null,
    appr_price: null,
    authorized_price: null,
    approved: "pending",
    recommended: "pending",
    authorised: "pending",
    data: [],
    dataS: [],
    loading: false,
    order_id: "",
    products: [],
    edit: false,
    procedureData: [],
    recommender_id: "",
    authoriser_id: "",
    approver_id: "",
    asset_id: "",
    asset_name: "",
    assetData: [],
    miscellaneous_bill_type_choices: [],
    misc_bill_type: "",
    taskData: [],
    started: [],
    completed: [],
    task_name: "",
    task_id: "",
    attendanceData: [],
    attendance_name: "",
    attendance_id: "",
    showSupplier: false,
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {},
    recommender_name: "",
    approver_name: "",
    authorizer_name: "",
    approval_system_choices: [],
    item_price: 0,
    item_unit_price: 0,
    item_recommended_price: 0,
    item_authorized_price: 0,
    item_approved_price: 0,
    item_qty: 0,
    item_name: "",
    item_unit: "",
    item_id: "",
    item_edit_modal: false,
  };

  componentDidMount() {
    this.props.getUserData();
    ReactGA.pageview(window.location.pathname);

    this.getProcedureList();
    this.getBill();
    this.getCustomFields();
    this.getApprovalSystemChoices();
    this.setState({ bill_id: this.props.location.state.bill_id });
  }

  getApprovalSystemChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${APPROVAL_SYSTEM_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from approval system choices", response);
          this.setState({
            approval_system_choices:
              response.data.approval_system_status_choices,
          });
        })
        .catch((error) => {
          console.log("error from approval system choices", error);
        });
    });
  };

  getProcedureList = () => {
    let limit = 1000;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PROCEDURE}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from procedure list", response);
          let bill = response.data.data.filter(
            (proce) => proce.name.toLowerCase() === "bill"
          );
          let recommender_id = bill[0].recommender;
          let authoriser_id = bill[0].authorizer;
          let approver_id = bill[0].approver;
          this.setState(
            {
              recommender_id: recommender_id,
              authoriser_id: authoriser_id,
              approver_id: approver_id,
            },
            () => {
              console.log("recommender", this.state.recommender_id);
              console.log("auhtorizer", this.state.authoriser_id);
              console.log("approver", this.state.approver_id);
            }
          );
        })
        .catch((error) => {
          console.log("error from procedure list api", error);
        });
    });
  };

  getCustomFields = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=purchase_bill&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_fields: response.data.data,
          });
          console.log("response from get custom fields", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };

  getBill = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let bill_id = this.props.location.state.bill_id;
      let url = `${EDIT_BILL}${bill_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit bill", response.data.data);
        if(response.data.message==="Bill not found"){
          this.props.history.goBack();
          swal.fire("Info",response.data.message,"info")
        }
        this.setState({
          name: response?.data?.data?.name ?? "",
          details: response?.data?.data?.details ?? "",
          price: response?.data?.data?.total_price ?? "",
          contact_no: response?.data?.data?.contact_no ?? "",
          supplier_name: response?.data?.data?.supplier?.name ?? "",
          supplier_name_key: response?.data?.data?.supplier?.id ?? "",
          rcmnd_price: response?.data?.data?.total_recommended_price ?? "",
          appr_price: response?.data?.data?.total_approved_price ?? "",
          authorized_price: response?.data?.data?.total_authorized_price ?? "",
          recommended: response?.data?.data?.recommended ?? "pending",
          authorised: response?.data?.data?.authorized ?? "pending",
          approved: response?.data?.data?.approved ?? "pending",
          products: response?.data?.data?.items ?? [],
          asset_id: response?.data?.data?.asset_id ?? "",
          asset_name: response?.data?.data?.asset_name ?? "",
          misc_bill_type: response?.data?.data?.misc_bill_type?.name ?? "",
          task_id: response?.data?.data?.task_id ?? "",
          task_name: response?.data?.data?.task_name ?? "",
          attendance_id: response?.data?.data?.attendance_id ?? "",
          attendance_name: response?.data?.data?.attendance_name ?? "",
          created_by_name: response?.data?.data?.created_by_name ?? "",
          created_for_name: response?.data?.data?.created_for?.name ?? "",
          created_for_mobile: response?.data?.data?.created_for?.mobile ?? "",
          recommender_name: response?.data?.data?.recommender_name ?? "",
          approver_name: response?.data?.data?.approver_name ?? "",
          authorizer_name: response?.data?.data?.authorizer_name ?? "",
          created_on: response.data?.data?.created_on?.$date ?? "",
          getCustomFieldData: response?.data?.data?.custom_fields ?? {},
        });
      });
    });
  };

  updateCustomBillData = (checked) => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "purchase_bill",
        custom_fields: this.state.custom_field_data,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.bill_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Success", response.data.message, "success");
            if (response.data.message === "Data successfully updated!") {
              this.getBill();
            }
            console.log(
              "response from custom field bill data update ",
              response
            );
          })
          .catch((error) => {
            this.getBill();
            swal.fire("Error", error.message, "error");
            console.log("error from custom field bill data update ", error);
          });
      });
    }
  };

  setRcmndPrice = (evt) => {
    evt.preventDefault();
    this.setState({ rcmnd_price: evt.target.value }, () => {
      console.log(this.state.rcmnd_price);
    });
  };
  setAuthorizerPrice = (evt) => {
    evt.preventDefault();
    this.setState({ authorized_price: evt.target.value }, () => {
      console.log(this.state.authorized_price);
    });
  };
  setApproverPrice = (evt) => {
    evt.preventDefault();
    this.setState({ appr_price: evt.target.value }, () => {
      console.log(this.state.appr_price);
    });
  };

  setRecomended = (value) => {
    this.setState({ recommended: value }, () => {
      console.log("recommended", this.state.recommended);
    });
  };
  setAuthorized = (value) => {
    this.setState({ authorised: value }, () => {
      console.log("authorized", this.state.authorised);
    });
  };
  setApproved = (value) => {
    this.setState({ approved: value }, () => {
      console.log("approved", this.state.approved);
    });
  };

  UpdateBill = () => {
    let data = {
      approved: this.state.approved,
      recommended: this.state.recommended,
      authorized: this.state.authorised,
      items: [
        {
          item: this.state.products[0]?.item ?? "",
          mtype: {
            name: this.state.products[0]?.mtype.name ?? "",
            id: this.state.products[0]?.mtype.id ?? "",
          },
          approved_price: Number(this.state.appr_price),
          authorized_price: Number(this.state.authorized_price),
          price: Number(this.state.products[0]?.price ?? "0"),
          qty: 1,
          recommended_price: Number(this.state.rcmnd_price),
          unit: "",
          unit_price: 0,
        },
      ],
    };
    console.log("conveyance bill status change data", data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${BILL_APPROVAL}${this.state.bill_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          this.getBill();
          console.log("response from bill approval status", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          this.getBill();
          console.log("error from bill approval status", error);
        });
    });
  };

  updateItem = (
    item_id,
    item_name,
    item_unit,
    item_unit_price,
    item_qty,
    item_price,
    item_recommended_price,
    item_authorized_price,
    item_approved_price
  ) => {
    this.setState({ item_edit_modal: false });
    let final = [
      ...[
        {
          id: item_id,
          item: item_name,
          unit: item_unit,
          unit_price: item_unit_price,
          qty: item_qty,
          price: item_price,
          recommended_price: item_recommended_price,
          authorized_price: item_authorized_price,
          approved_price: item_approved_price,
        },
      ],
      ...this.state.products.filter((item) => item.id !== item_id),
    ];
    const data = {
      items: final,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${BILL_ITEMS_EDIT}${this.state.bill_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit bill item", response);
          this.setState({
            item_price: 0,
            item_name: "",
            item_unit: "",
            item_unit_price: 0,
            item_qty: 0,
            item_id: "",
            item_recommended_price: 0,
            item_authorized_price: 0,
            item_approved_price: 0,
          });
          this.getBill();
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          this.setState({
            item_price: 0,
            item_name: "",
            item_unit: "",
            item_unit_price: 0,
            item_qty: 0,
            item_id: "",
            item_recommended_price: 0,
            item_authorized_price: 0,
            item_approved_price: 0,
          });
          console.log("error from edit trasnport bill", error);
        });
    });
  };

  deleteBillItem = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = ` ${BILL_ITEMS_EDIT}${this.state.bill_id}?item_id=${id}&access_token=${token}`;
      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          this.getBill();
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const { custom_field_data } = this.state;
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="View Bill"
          onBack={() => this.props.history.goBack()}
        />
        <Card
          style={{
            marginTop: 5,
            width: "100%"
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Bill Type</Text>:{" "}
            {this.state.misc_bill_type}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Bill Created by</Text>:{" "}
            {this.state.created_by_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Bill Created For</Text>:{" "}
            {`${this.state.created_for_name} ${this.state.created_for_mobile}`}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Bill Created on</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Requested Amount</Text>:{" "}
            {this.state.price}
          </p>
          {this.state.misc_bill_type === "Conveyance" ? null : (
            <div>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Name</Text>:{" "}
                {this.state.name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Details</Text>:{" "}
                {this.state.details}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Contact Number</Text>:{" "}
                {this.state.contact_no}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Asset Name</Text>:{" "}
                {this.state.asset_name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Supplier Name</Text>:{" "}
                {this.state.supplier_name}
              </p>
            </div>
          )}

          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Task Name</Text>:{" "}
            {this.state.task_name}
          </p>

          <Table
            dataSource={this.state.products}
            pagination={false}
            rowKey={() => randomId(10)}
            size="small"
            bordered
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            <Column
              title="Item Type"
              key="item.type"
              render={(text, record) => <span>{record.mtype?.name ?? ""}</span>}
            />
            <Column title="Item Name" dataIndex="item" key="item" />

            <Column title="Quantity" dataIndex="qty" key="qty" />
            <Column title="Unit" dataIndex="unit" key="unit" />
            <Column
              title="Unit Price"
              dataIndex="unit_price"
              key="unit_price"
            />
            <Column title="Price" dataIndex="price" key="price" />
            <Column
              title="Recommended Price"
              dataIndex="recommended_price"
              key="recommended_price"
            />
            <Column
              title="Authorized Price"
              dataIndex="authorized_price"
              key="authorized_price"
            />
            <Column
              title="Approved Price"
              dataIndex="approved_price"
              key="approved_price"
            />
            {this.state.misc_bill_type === "Conveyance" ? null : (
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    {this.props.userProfileData.bill.bill.bill_item_udpade ? (
                      <Button
                        type="link"
                        style={{ color: "green" }}
                        onClick={() =>
                          this.setState(
                            {
                              item_price: record.price,
                              item_name: record.item,
                              item_unit: record.unit,
                              item_unit_price: record.unit_price,
                              item_qty: record.qty,
                              item_id: record.id,
                              item_recommended_price: record.recommended_price,
                              item_authorized_price: record.authorized_price,
                              item_approved_price: record.approved_price,
                            },
                            () => this.setState({ item_edit_modal: true })
                          )
                        }
                      >
                        Edit
                      </Button>
                    ) : null}
                    {this.props.userProfileData.bill.bill.bill_item_udpade ? (
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => this.deleteBillItem(record.id)}
                      >
                        Delete
                      </Button>
                    ) : null}
                  </span>
                )}
              />
            )}
          </Table>
          <Modal
            title={this.state.item_name}
            visible={this.state.item_edit_modal}
            onOk={() =>
              this.setState(
                {
                  item_price: Number(
                    this.state.item_unit_price * this.state.item_qty
                  ),
                },
                () =>
                  this.updateItem(
                    this.state.item_id,
                    this.state.item_name,
                    this.state.item_unit,
                    this.state.item_unit_price,
                    this.state.item_qty,
                    this.state.item_price,
                    this.state.item_recommended_price,
                    this.state.item_authorized_price,
                    this.state.item_approved_price
                  )
              )
            }
            onCancel={() =>
              this.setState({
                item_edit_modal: false,
                item_price: 0,
                item_name: "",
                item_unit: "",
                item_unit_price: 0,
                item_qty: 0,
                item_id: "",
                item_recommended_price: 0,
                item_authorized_price: 0,
                item_approved_price: 0,
              })
            }
          >
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column",
              }}
            >
              {/* <Form.Item>
                <Text>Unit Price</Text>
                <Input
                  placeholder="Unit Price"
                  type="number"
                  value={this.state.item_unit_price}
                  onChange={evt =>
                    this.setState({ item_unit_price: Number(evt.target.value) })
                  }
                />
              </Form.Item> */}
              <Form.Item>
                <Text>Quantity</Text>
                <Input
                  placeholder="Quantity"
                  type="number"
                  value={this.state.item_qty}
                  onChange={(evt) =>
                    this.setState({ item_qty: Number(evt.target.value) })
                  }
                />
              </Form.Item>

              {this.state.recommender_id === GET_USER_LOCAL().role_id ? (
                <Form.Item>
                  <Text>Recommended Price</Text>
                  <Input
                    placeholder="Recommended Price"
                    type="number"
                    value={this.state.item_recommended_price}
                    onChange={(evt) =>
                      this.setState({
                        item_recommended_price: Number(evt.target.value),
                      })
                    }
                  />
                </Form.Item>
              ) : null}
              {this.state.authoriser_id === GET_USER_LOCAL().role_id ? (
                <Form.Item>
                  <Text>Authorized Price</Text>
                  <Input
                    placeholder="Authorized Price"
                    type="number"
                    value={this.state.item_authorized_price}
                    onChange={(evt) =>
                      this.setState({
                        item_authorized_price: Number(evt.target.value),
                      })
                    }
                  />
                </Form.Item>
              ) : null}
              {this.state.approver_id === GET_USER_LOCAL().role_id ? (
                <Form.Item>
                  <Text>Approved Price</Text>
                  <Input
                    placeholder="Approved Price"
                    type="number"
                    value={this.state.item_approved_price}
                    onChange={(evt) =>
                      this.setState({
                        item_approved_price: Number(evt.target.value),
                      })
                    }
                  />
                </Form.Item>
              ) : null}
            </Form>
          </Modal>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Recommended Amount</Text>
            :{" "}
          
              <Input
                placeholder="Total Recommended Amount"
                type="number"
                value={this.state.rcmnd_price}
                disabled={
                  this.state.recommender_id === GET_USER_LOCAL().role_id
                    ? false
                    : true
                }
                onChange={this.setRcmndPrice}
              />
           
          </p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Recommended</Text>:{" "}
              
                <Select
                  value={this.state.recommended}
                  onChange={this.setRecomended}
                  placeholder=""
                  disabled={
                    this.state.recommender_id === GET_USER_LOCAL().role_id
                      ? false
                      : true
                  }
                >
                  {this.state.approval_system_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              
            </span>
            <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
              <Text style={{ fontWeight: "bold" }}>Recommended By : </Text>
              {this.state.recommender_name}
            </p>
          </div>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Authorized Amount</Text>:{" "}
            
              <Input
                placeholder="Total Authorized Amount"
                type="number"
                value={this.state.authorized_price}
                disabled={
                  this.state.authoriser_id === GET_USER_LOCAL().role_id
                    ? false
                    : true
                }
                onChange={this.setAuthorizerPrice}
              />
          
          </p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Authorized</Text>:{" "}
              
                <Select
                  value={this.state.authorised}
                  onChange={this.setAuthorized}
                  placeholder=""
                  disabled={
                    this.state.authoriser_id === GET_USER_LOCAL().role_id
                      ? false
                      : true
                  }
                >
                  {this.state.approval_system_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              
            </span>
            <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
              <Text style={{ fontWeight: "bold" }}>Authorized By : </Text>
              {this.state.authorizer_name}
            </p>
          </div>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Approved Amount</Text>:{" "}
         
              <Input
                placeholder="Total Approved Amount"
                type="number"
                value={this.state.appr_price}
                disabled={
                  this.state.approver_id === GET_USER_LOCAL().role_id
                    ? false
                    : true
                }
                onChange={this.setApproverPrice}
              />
            
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span style={{ fontFamily: "AirbnbCerealApp-Book" }}>
              <Text style={{ fontWeight: "bold" }}>Approved</Text>:{" "}
           
                <Select
                  value={this.state.approved}
                  onChange={this.setApproved}
                  placeholder=""
                  disabled={
                    this.state.approver_id === GET_USER_LOCAL().role_id
                      ? false
                      : true
                  }
                >
                  {this.state.approval_system_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              
            </span>
            <p style={{ fontFamily: "AirbnbCerealApp-Book", marginLeft: 15 }}>
              <Text style={{ fontWeight: "bold" }}>Approved By : </Text>
              {this.state.approver_name}
            </p>
          </div>

          <div>
            {GET_USER_LOCAL().role_id === this.state.approver_id ||
            GET_USER_LOCAL().role_id === this.state.authoriser_id ||
            GET_USER_LOCAL().role_id === this.state.recommender_id ? (
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.UpdateBill}
              >
                Update Bill
              </Button>
            ) : null}
          </div>
        </Card>

        {Object.keys(this.state.getCustomFieldData).length !== 0 ? (
          <Card
            style={{
              marginTop: 5,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Custom Field Data"
            />
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 5,
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)",
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>{`${f.label} (Type : ${f._type}) : `}</Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={(e) => {
                            let obj = {
                              [f.key_name]: e.target.value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={(checkedValues) => {
                            let obj = {
                              [f.key_name]: checkedValues,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={(evt) => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value,
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then((token) => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                  .then((response) => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map((res) => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp,
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          ),
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch((error) => {
                                    swal.fire("Error", error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value),
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value,
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText,
                                  ],
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle,
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      ),
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white",
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: [],
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomBillData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(ViewBill);
