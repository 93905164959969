import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tag,
  Typography,
  Select,
  Pagination,
  Input,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
// import swal from "sweetalert2";

import { CREATE_SUB_ORG,SUB_ORG_SORT_TYPE_CHOICES } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

export default class SubOrgList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    name: "",
    sub_org_type_name: "",
    sub_org_parent_name: "",
    sub_org_types:[],
    sort_by:"created_on"
  };
  componentDidMount() {
    this.getSubOrgTypes();
    this.getSubOrgList();
  }

  getSubOrgTypes = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${SUB_ORG_SORT_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from sub org types", response);
          this.setState({
            sub_org_types: response.data.sub_org_sort_by_type_choices
          });
        })
        .catch(error => {
          console.log("error from sub org types api", error);
        });
    });
  };

  getSubOrgList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG}?sort_by=${this.state.sort_by}&name=${this.state.name}&sub_org_type_name=${this.state.sub_org_type_name}&sub_org_parent_name=${this.state.sub_org_parent_name}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub organization list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from sub organization list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        page: 0,
        limit: 10,
        sort_by: "name",
        sort_type: "asc",
        name: "",
        sub_org_parent_name: "",
        sub_org_type_name: "",
      },
      () => {
        this.getSubOrgList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getSubOrgList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getSubOrgList();
    });
  };

  setSortType = value => {
    this.setState({ sort_by: value }, () => {
      console.log(this.state.sort_by);
      this.getSubOrgList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Sub Organizations"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
          <Text>Sort By : </Text>
            <Select
              value={this.state.sort_by}
              onChange={this.setSortType}
              placeholder="Sort By"
            >
              {this.state.sub_org_types.map(s => (
                <Option key={s.value}>{s.label}</Option>
              ))}
            </Select>
          </div>

        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Name</Text>
                  {/* <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div> */}
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getSubOrgList();
                    });
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Sub Org Type</Text>
                  {/* <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div> */}
                </div>

                <Input
                  value={this.state.sub_org_type_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_type_name: evt.target.value, page: 0 },
                      () => {
                        this.getSubOrgList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Sub Org Type"
            render={(text, record) => (
              <span>{record.sub_org_type_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row",marginLeft:2 }}>
                <Text ellipsis={true}>Parent Sub Org</Text>
                  {/* <div>
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getShopList();
                          }
                        );
                      }}
                    />
                  </div> */}
                </div>

                <Input
                  value={this.state.sub_org_parent_name}
                  onChange={(evt) => {
                    this.setState(
                      { sub_org_parent_name: evt.target.value, page: 0 },
                      () => {
                        this.getSubOrgList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Parent Sub Org"
            render={(text, record) => (
              <span>{record.parent_sub_org_data?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Child Sub Org Count</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Child Sub Org Count"
            render={(text, record) => <span>{record.no_of_child_sub_org}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Child Sub Orgs</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Child Sub Orgs"
            render={(text, record) => (
              <>
                {record.child_sub_orgs.length > 0 ? (
                  <>
                    {record.child_sub_orgs.map((cso, index) => (
                      <Tag key={index} color="blue">
                        {cso.name}
                      </Tag>
                    ))}
                  </>
                ) : null}
              </>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Reprsentative Place</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Reprsentative Place"
            render={(text, record) => (
              <span>{`${
                record.representative_place_as_inventory?.name ?? ""
              }`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Reprsentative User</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Reprsentative User"
            render={(text, record) => (
              <span>{`${record.representative_user?.name ?? ""}>${
                record.representative_user?.mobile ?? ""
              }`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Clients</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Total Clients"
            render={(text, record) => (
              <span>{record.assigned_client_number}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Assigned Persons</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Assigned Persons"
            render={(text, record) => (
              <span>{record.assigned_user_number}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Orders</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Total Orders"
            render={(text, record) => (
              <span>{record.all_order_number_by_places}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Sold Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Total Sold Amount"
            render={(text, record) => (
              <span>{record.all_order_total_price}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Billed Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Total Billed Amount"
            render={(text, record) => (
              <span>{record.all_order_bill_amount}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Collected Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Total Collected Amount"
            render={(text, record) => (
              <span>{record.all_order_total_received_amount}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Due Amount</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="Total Due Amount"
            render={(text, record) => (
              <span>{record.all_order_due_amount}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created on</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/viewSubOrg",
                    state: { sub_org_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    View
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/editSubOrg",
                    state: { sub_org_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Edit
                  </Button>
                </Link>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}
