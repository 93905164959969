import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Pagination,
  Select,
  Typography,
  Button,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import { Link } from "react-router-dom";
import axios from "axios";
import { debounce } from "lodash";
import swal from "sweetalert2";

import {
  CREATE_DRIVER,
  CREATE_HANDOVER_REQUEST,
  APPROVE_HANDOVER,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  //   CHECK_BOOL,
  //   GET_USER_LOCAL,
} from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class HandoverList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    collector_id: "",
    userData: [],
    wallet_payment_methods: [],
    show_req_handover_modal: false,
    handover_method: "cash",
    handovered_amount: 0,
    notes: "",
  };
  componentDidMount() {
    this.getUserList();
    this.getHandoverList();
    ReactGA.pageview(window.location.pathname);
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            userData: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setAssignedUser = (value) => {
    this.setState({ collector_id: value }, () => {
      console.log(this.state.collector_id);
      this.getHandoverList();
    });
  };

  getHandoverList = debounce(() => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_HANDOVER_REQUEST}?page=${this.state.page}&limit=${this.state.limit}&collector_id=${this.state.collector_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from handover collection list list", response);

          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from handover collection list list api", error);
        });
    });
  }, 500);

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getHandoverList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getHandoverList();
    });
  };

  refresh = () => {
    this.setState(
      {
        data: [],
        page: 0,
        total: 0,
        limit: 10,
        collector_id: "",
        userData: [],
      },
      () => {
        this.getUserList();
        this.getHandoverList();
      }
    );
  };

  approveHandover = (handover_id) => {
    const data = {
      status: "Approved",
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${APPROVE_HANDOVER}${handover_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          this.getHandoverList();
          console.log("response from edit approve handover", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from edit approve handover", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Handover List"
        />
        {this.props.userProfileData.data_level.access_suborg_data ||
        this.props.userProfileData.data_level.access_all_data ? (
          <Card>
            <Title level={4}>Filter Handover list With User</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{
                  margin: 5,
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "200px",
                }}
              >
                <Text>Search User:</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  style={{ width: "100%" }}
                  value={this.state.collector_id}
                  onChange={this.setAssignedUser}
                  placeholder="Select User"
                  required
                >
                  {this.state.userData.map((r) => {
                    return (
                      <Option
                        key={r._id.$oid}
                      >{`${r.name}>${r.mobile}(${r.organization_status})`}</Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Card>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          {/* <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={()=>this.setState({show_req_handover_modal:true})}
            >
              Request Handover
            </Button>
          </div> */}
        </div>

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Collector Name</Text>
                </div>
              </>
            }
            dataIndex="created_by_name"
            key="created_by_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Collector Number</Text>
                </div>
              </>
            }
            dataIndex="created_by_number"
            key="created_by_number"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Receipt Number</Text>
                </div>
              </>
            }
            dataIndex="receipt_number"
            key="receipt_number"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Cumulative Amount</Text>
                </div>
              </>
            }
            dataIndex="cumulative_amount"
            key="cumulative_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Handovered Amount</Text>
                </div>
              </>
            }
            dataIndex="handovered_amount"
            key="handovered_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Handovered Amount</Text>
                </div>
              </>
            }
            dataIndex="total_handovered_amount"
            key="total_handovered_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Remaining Amount</Text>
                </div>
              </>
            }
            dataIndex="remaining_handoverable_amount"
            key="remaining_handoverable_amount"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>method</Text>
                </div>
              </>
            }
            dataIndex="handover_method"
            key="handover_method"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Status</Text>
                </div>
              </>
            }
            dataIndex="status"
            key="status"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Notes</Text>
                </div>
              </>
            }
            dataIndex="notes"
            key="notes"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org</Text>
                </div>
              </>
            }
            dataIndex="sub_org_name"
            key="sub_org_name"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Approved By</Text>
                </div>
              </>
            }
            key="approved_by"
            render={(text, record) => (
              <span>{`${record.approved_by_name},${record.approved_by_number}`}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Modified On</Text>
                </div>
              </>
            }
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {(this.props.userProfileData.data_level.access_suborg_data ||
                  this.props.userProfileData.data_level.access_all_data) &&
                record.status === "Pending" ? (
                  <Button
                    onClick={() => this.approveHandover(record._id.$oid)}
                    style={{ color: "green" }}
                    type="link"
                  >
                    Approve
                  </Button>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(HandoverList);
