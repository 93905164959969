import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { debounce } from "lodash";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  CREATE_ROASTER,
  CREATE_ROASTER_PLAN,
  CREATE_DRIVER
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateRoaster extends Component {
  state = {
    roaster_plan_id: "",
    name: "",
    roaster_name: "",
    day_name: "",
    start_time: "",
    end_time: "",
    date: "",
    timezone_name: "",
    data: [],
    roaster_plan: {},
    assigned_users: [],
    user_data: [],
    filtered: [],
    from: Date.now() / 1000,
    from_view: moment(),
    to: Date.now() / 1000,
    to_view: moment()
  };

  componentDidMount() {
    this.getRoasterPlanList();
    this.getUserList();
    ReactGA.pageview(window.location.pathname);

    // let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // this.setState({ timezone_name: timezone }, () => {
    //   console.log("time zone", this.state.timezone_name);
    // });
  }

  setName = evt => {
    evt.preventDefault();
    this.setState({ roaster_name: evt.target.value }, () => {
      console.log(this.state.roaster_name);
    });
  };

  handleSearch = debounce(value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from search user", response);
          this.setState({
            user_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  setTo = (value, dateString) => {
    this.setState(
      { to: moment(value).format("x") / 1000, to_view: value },
      () => {
        console.log("Selected Time: ", this.state.to);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState(
      { from: moment(value).format("x") / 1000, from_view: value },
      () => {
        console.log("Selected Time: ", this.state.from);
      }
    );
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?limit=100&d=false&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from user list", response);
          this.setState({
            user_data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from user list api", error);
        });
    });
  };

  getRoasterPlanList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ROASTER_PLAN}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ data: response.data.data });
          console.log("data from roaster plan list api", response);
        })
        .catch(error => {
          console.log("error from roaster plan list api", error);
        });
    });
  };

  setRoasterPlan = (value, info) => {
    console.log("object values", info.props);
    this.setState({ roaster_plan_id: value }, () => {
      console.log("roaster plan id array", this.state.roaster_plan_id);
      let tempObj = {
        _id: info.props.info._id.$oid,
        name: info.props.info.name,
        timezone_name: info.props.info.timezone_name,
        start_time: info.props.info.start_time,
        end_time: info.props.info.end_time
      };
      this.setState({ roaster_plan: tempObj }, () => {
        console.log("final object", this.state.roaster_plan);
      });
    });
  };

  createRoaster = () => {
    if (this.state.roaster_plan_id === "") {
      swal.fire("info", "Please slect a roaster plan", "info");
    } else {
      const data = {
        name: this.state.roaster_name,
        roaster_plan: this.state.roaster_plan,
        assigned_users: this.state.assigned_users,
        from: this.state.from,
        to: this.state.to
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_ROASTER}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            console.log("response from create roaster", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create roaster", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <div>
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)"
            }}
            title="Create Roster"
          />
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
              <Text>Name</Text>
              <Input
                placeholder="Name"
                type="text"
                value={this.state.roaster_name}
                onChange={this.setName}
              />
            </Form.Item>

            <Form.Item>
              <Text>Select Roster Plan</Text>
              <Select
                value={this.state.roaster_plan_id}
                onChange={this.setRoasterPlan}
                placeholder="Select Roster Plan"
              >
                {this.state.data.map(r => {
                  return (
                    <Option key={r._id.$oid} info={r}>
                      {r.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Text>Roster Start Date</Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.from_view}
                    onChange={this.setFrom}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Text>Roster End Date</Text>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    inputVariant="outlined"
                    value={this.state.to_view}
                    onChange={this.setTo}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </Form.Item>

            <Form.Item>
              <Text>Search Users</Text>
              <Select
                labelInValue
                mode="multiple"
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                value={this.state.assigned_users}
                onSearch={this.handleSearch}
                onChange={value => {
                  this.setState({ assigned_users: value }, () => {
                    console.log(this.state.assigned_users);
                  });
                }}
                placeholder="Search Users"
              >
                {this.state.user_data.map(r => {
                  return (
                    <Option
                      key={r._id.$oid}
                    >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.createRoaster}
              >
                Create Roster
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Card>
    );
  }
}
