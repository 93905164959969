import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Table,
  Tag,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  PROMOTION_DISCOUNT_TYPE_CHOICES,
  PROMOTION_TYPE_CHOICES,
  CREATE_PROMOTION,
  CREATE_SUB_ORG,
  CREATE_INVENTORY,
  CREATE_ENTITY,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
} from "../../api/constants";
import { debounce } from "lodash";
import { GET_ACCESS_TOKEN, randomId,CHECK_BOOL } from "../../utilities/utilities";
import ReactGA from "react-ga";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

export default class CreatePromotion extends Component {
  state = {
    name: "",
    promotion_discount_type_choices: [],
    promotion_discount_type: "",
    promotion_type_choices: [],
    promotion_type: "",
    start_date: Date.now(),
    start_date_view: moment(),
    end_date: Date.now(),
    end_date_view: moment(),
    usage_limit_by_client: 1,
    coupon_code: "",
    promotion_for: "",
    sub_org_choices: [],
    sub_orgs: [],
    sub_orgs_choices: [],
    sub_org_search_value: "",
    inventories: [],
    inventory_search_value: "",
    inventories_choices: [],
    clients: [],
    client_search_value: "",
    clients_choices: [],
    products: [],
    product_search_value: "",
    priority:1,
    reorder_priority:false,


    all_order_count_discount_tier: [],
    order_qty: 0,
    description: "",
    label: "",
    discount_type: "",
    discount: 0,
    discount_amount_limit: 0,

    all_order_total_val_discount_tier: [],
    all_order_total_val: 0,

    order_total_discount_tier: [],
    order_total: 0,

    wallet_payable_discount_tier: [],
    payable_amount: 0,
    calculated_discount_amount: 0,

    order_receivable_discount_tier: [],
    receivable_amount: 0,

    product_qty_discount_tier: [],
    product_id: "",
    product_name: "",
    product_category_name: "",
    product_category_id: "",
    product_qty: 0,
    selected_buy_products: [],
    selected_free_products: [],

    free_product_id: "",
    free_product_name: "",
    free_product_category_name: "",
    free_product_category_id: "",
    free_product_qty: 0,
  };

  componentDidMount() {
    this.getPromotionDiscountTypeChoices();
    this.getPromotionTypeChoices();
    ReactGA.pageview(window.location.pathname);
  }

  getSubOrgList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG}?name=${this.state.sub_org_search_value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub organization list", response);
          this.setState({
            sub_orgs_choices: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from sub organization list api", error);
        });
    });
  }, 500);

  handleSuborgSearch = (value) => {
    this.setState({ sub_org_search_value: value }, () => {
      this.getSubOrgList();
    });
  };

  setSuborg = (value) => {
    this.setState({ sub_orgs: value }, () => {
      console.log(this.state.sub_orgs);
    });
  };

  getInventoryList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?entity_name=${this.state.inventory_search_value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory list", response);
          this.setState({
            inventories_choices: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from inventory list api", error);
        });
    });
  }, 500);
  handleInventorySearch = (value) => {
    this.setState({ inventory_search_value: value }, () => {
      this.getInventoryList();
    });
  };
  setInventory = (value) => {
    this.setState({ inventories: value }, () => {
      console.log(this.state.inventories);
    });
  };
  getEntityList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?name=${this.state.client_search_value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from entity list", response);
          this.setState({
            clients_choices: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  getProductList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?is_parent_product=true&name=${this.state.product_name}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          this.setState({
            products: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  productDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_PRODUCT}${this.state.product_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view product", response);
        this.setState({
          product_name: response.data?.data?.name ?? "",
          product_category_name: response.data?.data?.category_name ?? "",
          product_category_id: response.data?.data?.category_id ?? "",
        });
      });
    });
  };

  freeProductDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${EDIT_PRODUCT}${this.state.free_product_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view product", response);
        this.setState({
          free_product_name: response.data?.data?.name ?? "",
          free_product_category_name: response.data?.data?.category_name ?? "",
          free_product_category_id: response.data?.data?.category_id ?? "",
        });
      });
    });
  };

  handleEntitySearch = (value) => {
    this.setState({ client_search_value: value }, () => {
      this.getEntityList();
    });
  };
  setEntity = (value) => {
    this.setState({ clients: value }, () => {
      console.log(this.state.clients);
    });
  };
  handleProductSearch = (value) => {
    this.setState({ product_name: value }, () => {
      this.getProductList();
    });
  };
  setProduct = (value) => {
    this.setState({ product_id: value.key, product_name: value.label }, () => {
      // this.productDetails();
      console.log(this.state.product_name);
    });
  };
  setFreeProduct = (value) => {
    this.setState(
      { free_product_id: value.key, free_product_name: value.label },
      () => {
        // this.freeProductDetails();
        console.log(this.state.free_product_name);
      }
    );
  };
  setPromotionType = (value) => {
    this.setState({ promotion_type: value }, () => {
      if (this.state.promotion_type === "product_qty_discount") {
        this.getProductList();
      }
      console.log(this.state.promotion_type);
    });
  };
  // setPromotionDiscountType = (value) => {
  //   this.setState({ promotion_discount_type: value }, () => {
  //     console.log(this.state.promotion_discount_type);
  //   });
  // };

  setPromotionFor = (value) => {
    this.setState({ promotion_for: value }, () => {
      if (this.state.promotion_for === "sub_org") {
        this.getSubOrgList();
      } else if (this.state.promotion_for === "inventory") {
        this.getInventoryList();
      } else if (this.state.promotion_for === "client") {
        this.getEntityList();
      }
      console.log(this.state.promotion_for);
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setPriority = (evt) => {
    evt.preventDefault();
    this.setState({ priority: evt.target.value }, () => {
      console.log(this.state.priority);
    });
  };
  setReorderPriority = value => {
    this.setState({ reorder_priority: CHECK_BOOL(value) }, () => {
      console.log(this.state.reorder_priority);
    });
  };
  setCouponCode = (evt) => {
    evt.preventDefault();
    this.setState({ coupon_code: evt.target.value }, () => {
      console.log(this.state.coupon_code);
    });
  };
  setUsageLimit = (evt) => {
    evt.preventDefault();
    this.setState({ usage_limit_by_client: evt.target.value }, () => {
      console.log(this.state.usage_limit_by_client);
    });
  };

  setEnd = (value, dateString) => {
    this.setState(
      { end_date: moment(value).format("x"), end_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.end_date);
      }
    );
  };
  setStart = (value, dateString) => {
    this.setState(
      { start_date: moment(value).format("x"), start_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.start_date);
      }
    );
  };
  getPromotionDiscountTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PROMOTION_DISCOUNT_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            promotion_discount_type_choices:
              response.data.discount_type_choices,
          });
          console.log(
            "data from promotion discoutn type choices api",
            response
          );
        })
        .catch((error) => {
          console.log("error from promotion discoutn type choices api", error);
        });
    });
  };
  getPromotionTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PROMOTION_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            promotion_type_choices: response.data.promotion_type_choices,
          });
          console.log("data from from promotion type choices api", response);
        })
        .catch((error) => {
          console.log("error from from promotion type choices api", error);
        });
    });
  };

  createPromotion = () => {
    if (this.state.coupon_code !== "" && this.state.coupon_code.length !== 6) {
      swal.fire(
        "Info",
        "Coupon Code Must be 6 Alphanumeric Characters",
        "info"
      );
    }else if(Number(this.state.priority)<1){
      swal.fire(
        "Info",
        "Priority Must be Greater than or Equal to One",
        "info"
      );
    }else if(this.state.name===""){
      swal.fire(
        "Info",
        "Please Enter a Name for Promotion",
        "info"
      );
    }else if(this.state.start_date>=this.state.end_date){
      swal.fire(
        "Info",
        "End Date Must be Greater than Start Date",
        "info"
      );
    } else {
      const data = {
        name: this.state.name,
        promotion_type: this.state.promotion_type,
        reorder_priority: this.state.reorder_priority,
        priority: Number(this.state.priority),
        // promotion_discount_type: this.state.promotion_discount_type,
        promotion_for_type: this.state.promotion_for,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        usage_limit_by_client: Number(this.state.usage_limit_by_client),
        coupon_code: this.state.coupon_code,
        sub_orgs: this.state.sub_orgs,
        inventories: this.state.inventories,
        clients: this.state.clients,
        all_order_count_discount_tier: this.state.all_order_count_discount_tier,
        product_qty_discount_tier: this.state.product_qty_discount_tier,
        all_order_total_val_discount_tier:
          this.state.all_order_total_val_discount_tier,
        order_total_discount_tier: this.state.order_total_discount_tier,
        wallet_payable_discount_tier: this.state.wallet_payable_discount_tier,
        order_receivable_discount_tier:
          this.state.order_receivable_discount_tier,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_PROMOTION}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");

            console.log("response from create promotion", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from create promotion", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Create Promotion"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Coupon Code</Text>
            <Input
              placeholder="Coupon Code"
              type="text"
              value={this.state.coupon_code}
              onChange={this.setCouponCode}
            />
          </Form.Item>
          <Form.Item>
            <Text>Usage Limit By Client</Text>
            <Input
              placeholder="Usage Limit By Client"
              type="text"
              value={this.state.usage_limit_by_client}
              onChange={this.setUsageLimit}
            />
          </Form.Item>
          <Form.Item>
            <Text>Reorder Priority</Text>
            <Select
              value={this.state.reorder_priority.toString()}
              onChange={this.setReorderPriority}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Priority</Text>
            <Input
              placeholder="Priority"
              type="number"
              value={this.state.priority}
              onChange={this.setPriority}
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
              }}
            >
              <Text>Start Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_date_view}
                  onChange={this.setStart}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
              }}
            >
              <Text>End Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_date_view}
                  onChange={this.setEnd}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          <Form.Item>
            <Text>Promotion For</Text>
            <Select
              value={this.state.promotion_for}
              onChange={this.setPromotionFor}
              placeholder="Promotion For"
            >
              <Option key="sub_org">Sub Org</Option>
              <Option key="inventory">Inventory</Option>
              <Option key="client">Client</Option>
            </Select>
          </Form.Item>

          {this.state.promotion_for === "inventory" ? (
            <Form.Item>
              <Text>Search Inventory</Text>
              <Select
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                mode="multiple"
                onSearch={this.handleInventorySearch}
                value={this.state.inventories}
                onChange={this.setInventory}
              >
                {this.state.inventories_choices.map((r) => {
                  return <Option key={r._id.$oid}>{r.entity_name}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : this.state.promotion_for === "sub_org" ? (
            <Form.Item>
              <Text>Search Sub Org</Text>
              <Select
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                mode="multiple"
                onSearch={this.handleSuborgSearch}
                value={this.state.sub_orgs}
                onChange={this.setSuborg}
              >
                {this.state.sub_orgs_choices.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : this.state.promotion_for === "client" ? (
            <Form.Item>
              <Text>Search Client</Text>
              <Select
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                mode="multiple"
                onSearch={this.handleEntitySearch}
                value={this.state.clients}
                onChange={this.setEntity}
              >
                {this.state.clients_choices.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : null}

          {/* <Form.Item>
            <Text>Promotion Discount Type</Text>
            <Select
              value={this.state.promotion_discount_type}
              onChange={this.setPromotionDiscountType}
              placeholder="Promotion Discount Type"
            >
              {this.state.promotion_discount_type_choices.map((r) => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item> */}

          <Form.Item>
            <Text>Promotion Type</Text>
            <Select
              value={this.state.promotion_type}
              onChange={this.setPromotionType}
              placeholder="Promotion Type"
            >
              {this.state.promotion_type_choices.map((r) => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>
          {this.state.promotion_type === "product_qty_discount" ? (
            <Card style={{ marginBottom: 10 }}>
              <Card>
                {this.state.selected_buy_products.length > 0 ? (
                  <>
                    {this.state.selected_buy_products.map((p) => (
                      <Tag color="blue" key={p.product_id}>{`${p.product_name} - Quantity:${p.product_qty}`}</Tag>
                    ))}
                  </>
                ) : null}
                <Form.Item>
                  <Text>Search Buy Product</Text>
                  <Select
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    onSearch={this.handleProductSearch}
                    labelInValue
                    value={{
                      key: this.state.product_id,
                      label: this.state.product_name,
                    }}
                    onChange={this.setProduct}
                  >
                    {this.state.products.map((r) => {
                      return <Option key={r._id.$oid}>{`${r.name} - Unit:${r.unit}`}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Buy Product Quantity</Text>
                  <Input
                    placeholder="Product Quantity"
                    type="number"
                    value={this.state.product_qty}
                    onChange={(evt) =>
                      this.setState({ product_qty: evt.target.value })
                    }
                  />
                </Form.Item>
                <Button
                  className="login-form-button"
                  onClick={() => {
                    let tempObj = {
                      product_id: this.state.product_id,
                      product_name: this.state.product_name,
                      product_qty: Number(this.state.product_qty),
                    };
                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        selected_buy_products: [
                          ...tempArray,
                          ...this.state.selected_buy_products,
                        ],
                      },
                      () => {
                        this.setState({
                          product_id: "",
                          product_name: "",
                          product_qty: 0,
                        });
                      }
                    );
                  }}
                >
                  Add Buy Products
                </Button>
              </Card>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={(evt) =>
                    this.setState({ description: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Label</Text>
                <Input
                  placeholder="Label"
                  type="text"
                  value={this.state.label}
                  onChange={(evt) => this.setState({ label: evt.target.value })}
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Type</Text>
                <Select
                  value={this.state.discount_type}
                  onChange={(value) => this.setState({ discount_type: value })}
                  placeholder="Promotion Discount Type"
                >
                  {this.state.promotion_discount_type_choices.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              {this.state.discount_type === "free_product" ? (
                <Card>
                  {this.state.selected_free_products.length > 0 ? (
                    <>
                      {this.state.selected_free_products.map((p) => (
                        <Tag color="blue" key={p.product_id}>{`${p.product_name} - Quantity:${p.product_qty}`}</Tag>
                      ))}
                    </>
                  ) : null}

                  <Form.Item>
                    <Text>Search Free Product</Text>
                    <Select
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleProductSearch}
                      labelInValue
                      value={{
                        key: this.state.free_product_id,
                        label: this.state.free_product_name,
                      }}
                      onChange={this.setFreeProduct}
                    >
                      {this.state.products.map((r) => {
                        return <Option key={r._id.$oid}>{`${r.name} - Unit:${r.unit}`}</Option>;
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Text>Free Product Quantity</Text>
                    <Input
                      placeholder="Free Product Quantity"
                      type="number"
                      value={this.state.free_product_qty}
                      onChange={(evt) =>
                        this.setState({ free_product_qty: evt.target.value })
                      }
                    />
                  </Form.Item>

                  <Button
                    className="login-form-button"
                    onClick={() => {
                      let tempObj = {
                        product_id: this.state.free_product_id,
                        product_name: this.state.free_product_name,
                        product_qty: Number(this.state.free_product_qty),
                      };
                      let tempArray = [];
                      tempArray.push(tempObj);
                      this.setState(
                        {
                          selected_free_products: [
                            ...tempArray,
                            ...this.state.selected_free_products,
                          ],
                        },
                        () => {
                          this.setState({
                            free_product_id: "",
                            free_product_name: "",
                            free_product_qty: 0,
                          });
                        }
                      );
                    }}
                  >
                    Add Free Products
                  </Button>
                </Card>
              ) : null}
              <Form.Item>
                <Text>Discount Amount</Text>
                <Input
                  disabled={this.state.discount_type === "free_product"?true:false}
                  placeholder="Discount Amount"
                  type="number"
                  value={this.state.discount}
                  onChange={(evt) =>
                    this.setState({ discount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount Limit</Text>
                <Input
                  disabled={this.state.discount_type === "free_product"?true:false}
                  placeholder="Discount Amount Limit"
                  type="number"
                  value={this.state.discount_amount_limit}
                  onChange={(evt) =>
                    this.setState({ discount_amount_limit: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() => {
                    let tempObj = {
                      id: randomId(10),
                      buy_product: this.state.selected_buy_products,
                      description: this.state.description,
                      label: this.state.label,
                      discount_type: this.state.discount_type,
                      discount: Number(this.state.discount),
                      discount_amount_limit: Number(
                        this.state.discount_amount_limit
                      ),
                      free_product: this.state.selected_free_products,
                    };

                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        product_qty_discount_tier: [
                          ...tempArray,
                          ...this.state.product_qty_discount_tier,
                        ],
                      },
                      () => {
                        this.setState({
                          selected_buy_products: [],
                          description: "",
                          label: "",
                          discount_type: "",
                          discount: 0,
                          discount_amount_limit: 0,
                          selected_free_products: [],
                        });
                        console.log(this.state.product_qty_discount_tier);
                      }
                    );
                  }}
                >
                  Add
                </Button>
              </Form.Item>
              {this.state.product_qty_discount_tier.length > 0 ? (
                <Table
                  dataSource={this.state.product_qty_discount_tier}
                  pagination={false}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                >
                  <Column
                    title="Buy Products"
                    key="buy_products"
                    render={(text, record) => (
                      <span>
                        {record.buy_product.map((p) => (
                          <Tag
                            key={p.product_id}
                          >{`Name:${p.product_name}|Quantity:${p.product_qty}`}</Tag>
                        ))}
                      </span>
                    )}
                  />
                  <Column
                    title="Free Products"
                    key="free_products"
                    render={(text, record) => (
                      <span>
                        {record.free_product.map((p) => (
                          <Tag
                            key={p.product_id}
                          >{`Name:${p.product_name}|Quantity:${p.product_qty}`}</Tag>
                        ))}
                      </span>
                    )}
                  />
                  <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Discount Type"
                    dataIndex="discount_type"
                    key="discount_type"
                  />
                  <Column
                    title="Discount"
                    dataIndex="discount"
                    key="discount"
                  />
                  <Column
                    title="Discount Amount Limits"
                    dataIndex="discount_amount_limit"
                    key="discount_amount_limit"
                  />
                </Table>
              ) : null}
            </Card>
          ) : this.state.promotion_type === "all_order_count_discount" ? (
            <Card style={{ marginBottom: 10 }}>
              <Form.Item>
                <Text>Order Quantity</Text>
                <Input
                  placeholder="Order Quantity"
                  type="number"
                  value={this.state.order_qty}
                  onChange={(evt) =>
                    this.setState({ order_qty: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={(evt) =>
                    this.setState({ description: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Label</Text>
                <Input
                  placeholder="Label"
                  type="text"
                  value={this.state.label}
                  onChange={(evt) => this.setState({ label: evt.target.value })}
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Type</Text>
                <Select
                  value={this.state.discount_type}
                  onChange={(value) => this.setState({ discount_type: value })}
                  placeholder="Promotion Discount Type"
                >
                  {this.state.promotion_discount_type_choices.filter(f=>f!=="free_product").map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount</Text>
                <Input
                  placeholder="Discount Amount"
                  type="number"
                  value={this.state.discount}
                  onChange={(evt) =>
                    this.setState({ discount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount Limit</Text>
                <Input
                  placeholder="Discount Amount Limit"
                  type="number"
                  value={this.state.discount_amount_limit}
                  onChange={(evt) =>
                    this.setState({ discount_amount_limit: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() => {
                    let tempObj = {
                      id: randomId(10),
                      order_qty: Number(this.state.order_qty),
                      description: this.state.description,
                      label: this.state.label,
                      discount_type: this.state.discount_type,
                      discount: Number(this.state.discount),
                      discount_amount_limit: Number(
                        this.state.discount_amount_limit
                      ),
                    };

                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        all_order_count_discount_tier: [
                          ...tempArray,
                          ...this.state.all_order_count_discount_tier,
                        ],
                      },
                      () => {
                        this.setState({
                          order_qty: 0,
                          description: "",
                          label: "",
                          discount_type: "",
                          discount: 0,
                          discount_amount_limit: 0,
                        });
                      }
                    );
                  }}
                >
                  Add
                </Button>
              </Form.Item>
              {this.state.all_order_count_discount_tier.length > 0 ? (
                <Table
                  dataSource={this.state.all_order_count_discount_tier}
                  pagination={false}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                >
                  <Column
                    title="Order Quantity"
                    dataIndex="order_qty"
                    key="order_qty"
                  />
                  <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Discount Type"
                    dataIndex="discount_type"
                    key="discount_type"
                  />
                  <Column
                    title="Discount"
                    dataIndex="discount"
                    key="discount"
                  />
                  <Column
                    title="Discount Amount Limits"
                    dataIndex="discount_amount_limit"
                    key="discount_amount_limit"
                  />
                </Table>
              ) : null}
            </Card>
          ) : this.state.promotion_type === "all_order_total_val_discount" ? (
            <Card style={{ marginBottom: 5 }}>
              <Form.Item>
                <Text> All Order Total Value</Text>
                <Input
                  placeholder="All Order Total Value"
                  type="number"
                  value={this.state.all_order_total_val}
                  onChange={(evt) =>
                    this.setState({ all_order_total_val: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={(evt) =>
                    this.setState({ description: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Label</Text>
                <Input
                  placeholder="Label"
                  type="text"
                  value={this.state.label}
                  onChange={(evt) => this.setState({ label: evt.target.value })}
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Type</Text>
                <Select
                  value={this.state.discount_type}
                  onChange={(value) => this.setState({ discount_type: value })}
                  placeholder="Promotion Discount Type"
                >
                  {this.state.promotion_discount_type_choices.filter(f=>f!=="free_product").map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount</Text>
                <Input
                  placeholder="Discount Amount"
                  type="number"
                  value={this.state.discount}
                  onChange={(evt) =>
                    this.setState({ discount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount Limit</Text>
                <Input
                  placeholder="Discount Amount Limit"
                  type="number"
                  value={this.state.discount_amount_limit}
                  onChange={(evt) =>
                    this.setState({ discount_amount_limit: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() => {
                    let tempObj = {
                      id: randomId(10),
                      all_order_total_val: Number(
                        this.state.all_order_total_val
                      ),
                      description: this.state.description,
                      label: this.state.label,
                      discount_type: this.state.discount_type,
                      discount: Number(this.state.discount),
                      discount_amount_limit: Number(
                        this.state.discount_amount_limit
                      ),
                    };

                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        all_order_total_val_discount_tier: [
                          ...tempArray,
                          ...this.state.all_order_total_val_discount_tier,
                        ],
                      },
                      () => {
                        this.setState({
                          all_order_total_val: 0,
                          description: "",
                          label: "",
                          discount_type: "",
                          discount: 0,
                          discount_amount_limit: 0,
                        });
                      }
                    );
                  }}
                >
                  Add
                </Button>
              </Form.Item>
              {this.state.all_order_total_val_discount_tier.length > 0 ? (
                <Table
                  dataSource={this.state.all_order_total_val_discount_tier}
                  pagination={false}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                >
                  <Column
                    title="All Order Total Value"
                    dataIndex="all_order_total_val"
                    key="all_order_total_val"
                  />
                  <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Discount Type"
                    dataIndex="discount_type"
                    key="discount_type"
                  />
                  <Column
                    title="Discount"
                    dataIndex="discount"
                    key="discount"
                  />
                  <Column
                    title="Discount Amount Limits"
                    dataIndex="discount_amount_limit"
                    key="discount_amount_limit"
                  />
                </Table>
              ) : null}
            </Card>
          ) : this.state.promotion_type === "order_total_discount" ? (
            <Card style={{ marginBottom: 5 }}>
              <Form.Item>
                <Text> Order Total</Text>
                <Input
                  placeholder="Order Total"
                  type="number"
                  value={this.state.order_total}
                  onChange={(evt) =>
                    this.setState({ order_total: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={(evt) =>
                    this.setState({ description: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Label</Text>
                <Input
                  placeholder="Label"
                  type="text"
                  value={this.state.label}
                  onChange={(evt) => this.setState({ label: evt.target.value })}
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Type</Text>
                <Select
                  value={this.state.discount_type}
                  onChange={(value) => this.setState({ discount_type: value })}
                  placeholder="Promotion Discount Type"
                >
                  {this.state.promotion_discount_type_choices.filter(f=>f!=="free_product").map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount</Text>
                <Input
                  placeholder="Discount Amount"
                  type="number"
                  value={this.state.discount}
                  onChange={(evt) =>
                    this.setState({ discount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount Limit</Text>
                <Input
                  placeholder="Discount Amount Limit"
                  type="number"
                  value={this.state.discount_amount_limit}
                  onChange={(evt) =>
                    this.setState({ discount_amount_limit: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() => {
                    let tempObj = {
                      id: randomId(10),
                      order_total: Number(this.state.order_total),
                      description: this.state.description,
                      label: this.state.label,
                      discount_type: this.state.discount_type,
                      discount: Number(this.state.discount),
                      discount_amount_limit: Number(
                        this.state.discount_amount_limit
                      ),
                    };

                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        order_total_discount_tier: [
                          ...tempArray,
                          ...this.state.order_total_discount_tier,
                        ],
                      },
                      () => {
                        this.setState({
                          order_total: 0,
                          description: "",
                          label: "",
                          discount_type: "",
                          discount: 0,
                          discount_amount_limit: 0,
                        });
                      }
                    );
                  }}
                >
                  Add
                </Button>
              </Form.Item>
              {this.state.order_total_discount_tier.length > 0 ? (
                <Table
                  dataSource={this.state.order_total_discount_tier}
                  pagination={false}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                >
                  <Column
                    title="Order Total"
                    dataIndex="order_total"
                    key="order_total"
                  />
                  <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Discount Type"
                    dataIndex="discount_type"
                    key="discount_type"
                  />
                  <Column
                    title="Discount"
                    dataIndex="discount"
                    key="discount"
                  />
                  <Column
                    title="Discount Amount Limits"
                    dataIndex="discount_amount_limit"
                    key="discount_amount_limit"
                  />
                </Table>
              ) : null}
            </Card>
          ) : this.state.promotion_type === "wallet_payable_discount" ? (
            <Card style={{ marginBottom: 5 }}>
              <Form.Item>
                <Text> Payable Amount</Text>
                <Input
                  placeholder="Payable Amount"
                  type="number"
                  value={this.state.payable_amount}
                  onChange={(evt) =>
                    this.setState({ payable_amount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={(evt) =>
                    this.setState({ description: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Label</Text>
                <Input
                  placeholder="Label"
                  type="text"
                  value={this.state.label}
                  onChange={(evt) => this.setState({ label: evt.target.value })}
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Type</Text>
                <Select
                  value={this.state.discount_type}
                  onChange={(value) => this.setState({ discount_type: value })}
                  placeholder="Promotion Discount Type"
                >
                  {this.state.promotion_discount_type_choices.filter(f=>f!=="free_product").map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount</Text>
                <Input
                  placeholder="Discount Amount"
                  type="number"
                  value={this.state.discount}
                  onChange={(evt) =>
                    this.setState({ discount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount Limit</Text>
                <Input
                  placeholder="Discount Amount Limit"
                  type="number"
                  value={this.state.discount_amount_limit}
                  onChange={(evt) =>
                    this.setState({ discount_amount_limit: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Calculated Discount Amount</Text>
                <Input
                  placeholder="Calculated Discount Amount"
                  type="number"
                  value={this.state.calculated_discount_amount}
                  onChange={(evt) =>
                    this.setState({
                      calculated_discount_amount: evt.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() => {
                    let tempObj = {
                      id: randomId(10),
                      payable_amount: Number(this.state.payable_amount),
                      description: this.state.description,
                      label: this.state.label,
                      discount_type: this.state.discount_type,
                      discount: Number(this.state.discount),
                      discount_amount_limit: Number(
                        this.state.discount_amount_limit
                      ),
                      calculated_discount_amount: Number(
                        this.state.calculated_discount_amount
                      ),
                    };

                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        wallet_payable_discount_tier: [
                          ...tempArray,
                          ...this.state.wallet_payable_discount_tier,
                        ],
                      },
                      () => {
                        this.setState({
                          payable_amount: 0,
                          description: "",
                          label: "",
                          discount_type: "",
                          discount: 0,
                          discount_amount_limit: 0,
                          calculated_discount_amount: 0,
                        });
                      }
                    );
                  }}
                >
                  Add
                </Button>
              </Form.Item>
              {this.state.wallet_payable_discount_tier.length > 0 ? (
                <Table
                  dataSource={this.state.wallet_payable_discount_tier}
                  pagination={false}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                >
                  <Column
                    title="Payable Amount"
                    dataIndex="payable_amount"
                    key="payable_amount"
                  />
                  <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Discount Type"
                    dataIndex="discount_type"
                    key="discount_type"
                  />
                  <Column
                    title="Discount"
                    dataIndex="discount"
                    key="discount"
                  />
                  <Column
                    title="Discount Amount Limits"
                    dataIndex="discount_amount_limit"
                    key="discount_amount_limit"
                  />
                  <Column
                    title="Calculated Discount Amount"
                    dataIndex="calculated_discount_amount"
                    key="calculated_discount_amount"
                  />
                </Table>
              ) : null}
            </Card>
          ) : this.state.promotion_type === "order_receivable_discount" ? (
            <Card style={{ marginBottom: 5 }}>
              <Form.Item>
                <Text> Receivable Amount</Text>
                <Input
                  placeholder="Receivable Amount"
                  type="number"
                  value={this.state.receivable_amount}
                  onChange={(evt) =>
                    this.setState({ receivable_amount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Description</Text>
                <Input
                  placeholder="Description"
                  type="text"
                  value={this.state.description}
                  onChange={(evt) =>
                    this.setState({ description: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Label</Text>
                <Input
                  placeholder="Label"
                  type="text"
                  value={this.state.label}
                  onChange={(evt) => this.setState({ label: evt.target.value })}
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Type</Text>
                <Select
                  value={this.state.discount_type}
                  onChange={(value) => this.setState({ discount_type: value })}
                  placeholder="Promotion Discount Type"
                >
                  {this.state.promotion_discount_type_choices.filter(f=>f!=="free_product").map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount</Text>
                <Input
                  placeholder="Discount Amount"
                  type="number"
                  value={this.state.discount}
                  onChange={(evt) =>
                    this.setState({ discount: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Discount Amount Limit</Text>
                <Input
                  placeholder="Discount Amount Limit"
                  type="number"
                  value={this.state.discount_amount_limit}
                  onChange={(evt) =>
                    this.setState({ discount_amount_limit: evt.target.value })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Text>Calculated Discount Amount</Text>
                <Input
                  placeholder="Calculated Discount Amount"
                  type="number"
                  value={this.state.calculated_discount_amount}
                  onChange={(evt) =>
                    this.setState({
                      calculated_discount_amount: evt.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={() => {
                    let tempObj = {
                      id: randomId(10),
                      receivable_amount: Number(this.state.receivable_amount),
                      description: this.state.description,
                      label: this.state.label,
                      discount_type: this.state.discount_type,
                      discount: Number(this.state.discount),
                      discount_amount_limit: Number(
                        this.state.discount_amount_limit
                      ),
                      calculated_discount_amount: Number(
                        this.state.calculated_discount_amount
                      ),
                    };

                    let tempArray = [];
                    tempArray.push(tempObj);
                    this.setState(
                      {
                        order_receivable_discount_tier: [
                          ...tempArray,
                          ...this.state.order_receivable_discount_tier,
                        ],
                      },
                      () => {
                        this.setState({
                          receivable_amount: 0,
                          description: "",
                          label: "",
                          discount_type: "",
                          discount: 0,
                          discount_amount_limit: 0,
                          calculated_discount_amount: 0,
                        });
                      }
                    );
                  }}
                >
                  Add
                </Button>
              </Form.Item>
              {this.state.order_receivable_discount_tier.length > 0 ? (
                <Table
                  dataSource={this.state.order_receivable_discount_tier}
                  pagination={false}
                  rowKey={(record) => record.id}
                  size="small"
                  bordered
                >
                  <Column
                    title="Receivable Amount"
                    dataIndex="receivable_amount"
                    key="receivable_amount"
                  />
                  <Column
                    title="Description"
                    dataIndex="description"
                    key="description"
                  />
                  <Column title="Label" dataIndex="label" key="label" />
                  <Column
                    title="Discount Type"
                    dataIndex="discount_type"
                    key="discount_type"
                  />
                  <Column
                    title="Discount"
                    dataIndex="discount"
                    key="discount"
                  />
                  <Column
                    title="Discount Amount Limits"
                    dataIndex="discount_amount_limit"
                    key="discount_amount_limit"
                  />
                  <Column
                    title="Calculated Discount Amount"
                    dataIndex="calculated_discount_amount"
                    key="calculated_discount_amount"
                  />
                </Table>
              ) : null}
            </Card>
          ) : null}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createPromotion}
            >
              Create Promotion
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
